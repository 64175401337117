import { CryptoWallet, Map } from 'icons'
import React, { Fragment, PropsWithChildren } from 'react'
import {
  Box,
  Text,
  Heading,
  Flex,
  VStack,
  Hide,
  BoxProps,
  Container,
} from '@chakra-ui/react'

interface IServiceOne extends BoxProps {
  title?: string
}

const Service: React.FC<PropsWithChildren<IServiceOne>> = ({
  children,
  title,
  ...props
}) => {
  return (
    <Fragment>
      <Container maxW="container.xl">
        <Flex gap={20} alignItems="center" py={20} px={[6, 0]}>
          <Hide below="md">
            <Box>
              <Map />
            </Box>
          </Hide>

          <Box textAlign={['center', 'left']}>
            <Heading pb={4}>Buy and Sell Cryptocurrency</Heading>
            <Text>
              BitBarter is a multi-asset digital wallet that is enabled by a P2P
              marketplace. It offers financial services to Africans and the
              global capital market by bridging traditional finance and
              cryptocurrencies. Our P2P marketplace is a cutting-edge secured
              platform, where anyone can buy or sell their cryptocurrencies at
              the best market rates using different fiat currencies available.
            </Text>
            <Text>
              BitBarter offers a wide range of fiat and cryptocurrencies, for
              frictionless payment, foreign exchange and cross-border remittance
              for people and institutions across the world.
            </Text>
          </Box>
        </Flex>
      </Container>
      {/* Second container */}
      <Box bg={'#F5F5F5'}>
        <Container maxW="container.xl">
          <Flex
            gap={[20]}
            alignItems="center"
            py={20}
            justifyContent="space-between"
            px={[6, 20]}
          >
            <Box textAlign={['center', 'left']}>
              <Heading pb={4}>A Global Payment Platform</Heading>
              <Text>
                BitBarter digital wallet is built on core of proprietary
                technologies, which enables people and businesses around the
                world have access to fast, reliable, and trusted financial
                services without any hidden fees.
              </Text>
            </Box>

            <Hide below="md">
              <Box>
                <CryptoWallet />
              </Box>
            </Hide>
          </Flex>
        </Container>
      </Box>
    </Fragment>
  )
}

export default Service
