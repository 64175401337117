import React, { PropsWithChildren } from 'react'
import { AppDataProvider } from 'providers'
import { ToastProvider, WebSocketProvider } from 'ui'
import customTheme from 'theme'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import { reduxStore } from 'store'

const AppWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider store={reduxStore}>
      <ChakraProvider theme={customTheme}>
        <ToastProvider>
          <WebSocketProvider>
            <AppDataProvider>{children}</AppDataProvider>
          </WebSocketProvider>
        </ToastProvider>
      </ChakraProvider>
    </Provider>
  )
}

export default AppWrapper
