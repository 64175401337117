import { extendTheme } from '@chakra-ui/react'
import {
  themeColors,
  themeConfig,
  themeFonts,
  themeFontSizes,
  themeFontWeights,
  themeLetterSpacings,
  themeLineHeights,
} from 'lib'
import styles from './styles'

const customTheme = extendTheme({
  colors: themeColors,
  fonts: {
    ...themeFonts,
    heading: "'Fira Sans', sans-serif",
    body: "'Fira Sans', sans-serif",
  },
  fontSizes: themeFontSizes,
  fontWeights: themeFontWeights,
  lineHeights: themeLineHeights,
  letterSpacings: themeLetterSpacings,
  config: themeConfig,
  styles,
})

export default customTheme
