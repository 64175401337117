import { getRecentBlogPosts, ICommon } from 'lib'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { useWebsocket } from 'ui'

type IAppDataProvider = React.FC<PropsWithChildren<{}>>

interface IAppDataContext {
  marketTrends: ICommon.MarketTrendData[];
  blogPosts: ICommon.BlogPost[];
}

export const AppDataContext = createContext<IAppDataContext | null>(null)

export const AppDataProvider: IAppDataProvider = ({ children }) => {
  const ws = useWebsocket();
  const dispatch = useAppDispatch();
  const {data: blogPosts } = useAppSelector(({recentBlogPosts}) => recentBlogPosts);
  const [marketTrends, setMarketTrends] = useState<ICommon.MarketTrendData[]>(
    []
  )

  const value: IAppDataContext = {
    marketTrends,
    blogPosts: blogPosts ?? []
  }
  
  ws.dataChannel.listenToMarketTrends((data) => {
    setMarketTrends(data);
  });

  useEffect(() => {
    dispatch(getRecentBlogPosts());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  return (
    <AppDataContext.Provider value={value}>{children}</AppDataContext.Provider>
  )
}

export const useAppData = (): IAppDataContext => {
  const context = useContext(AppDataContext)
  if (!context) {
    throw new Error(
      'useAppData hook not allowed outside AppDataProvider context. Please wrap you your component with AppDataProvider.'
    )
  }

  return context
}
