const styles = {
  global: (props: { colorMode: string }) => ({
    body: {
      fontFamily: 'fira',
      bg: 'white',
      color: 'bitcodeColor',
    },
    heading: {
      color: 'bitcodeYellow',
      fontWeight: 'extrabold',
    }
  }),
}

export default styles
