import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { Headset, Key, ThumbCheck } from 'icons'
import React, { Fragment } from 'react'

const WhyBarter: React.FC<unknown> = () => {
  return (
    <Fragment>
      <Flex
        display="flex"
        flexWrap="wrap"
        flexDirection={['column', 'row']}
        justifyContent="center"
        alignItems={['center', 'flex-start']}
        maxW="100%"
        minHeight="440px"
        background="#F5F5F5"
        gap="50px"
      >
        <Box
          order="-1"
          flex="1 0 100%"
          gap="40px"
          fontStyle="normal"
          fontWeight="800"
          fontSize="32px"
          line-height="40px"
          marginTop="67px"
          textAlign="center"
          color="#122633"
        >
          <Heading
            as="h2"
            fontFamily="fira sans"
            color="#122633"
            width="100%"
            maxHeight="40px"
            fontStyle="normal"
            fontWeight="800"
            fontSize="32px"
            lineHeight="40px"
            textAlign="center"
          >
            Why BitBarter?
          </Heading>
        </Box>
        <Flex
          display="flex"
          flexWrap='wrap'
          justifyContent="center"
          gap={['0px','20px','20px', '50px', '80px']}
          pl={['0', '0']}
          maxW="100%"
          
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="0px"
            gap="22px"
            width="228px"
            paddingBottom="50px"
          >
            <ThumbCheck />
            <Heading
              fontFamily="fira sans"
              width="228px"
              minHeight="22px"
              fontStyle="normal"
              fontWeight="600"
              fontSize="18px"
              lineHeight="24px"
              textAlign="center"
              color="#122633"
              flex="none"
              order="0"
              alignSelf="stretch"
              flexGrow="0"
            >
              Seamless
            </Heading>
            <Text
              fontFamily="fira sans"
              width="228px"
              height="87px"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="19px"
              textAlign="center"
              letterSpacing="0.1px"
              color="bgray2"
              flex="none"
              order="1"
              alignSelf="stretch"
              flexGrow="0"
            >
              BitBarter is designed to give users the best application interface
              experience as they navigate the the web app.
            </Text>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="0px"
            gap="22px"
            width="228px"
            paddingBottom="50px"
          >
            <Headset />
            <Heading
              width="228px"
              minHeight="22px"
              fontStyle="normal"
              fontWeight="600"
              fontSize="18px"
              lineHeight="24px"
              textAlign="center"
              color="#122633"
              flex="none"
              order="0"
              alignSelf="stretch"
              flexGrow="0"
              fontFamily="fira sans"
            >
              Customer service
            </Heading>
            <Text
              width="228px"
              height="87px"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="19px"
              textAlign="center"
              letterSpacing="0.1px"
              color="bgray2"
              flex="none"
              order="1"
              fontFamily="fira sans"
              alignSelf="stretch"
              flexGrow="0"
            >
              Trust, speed and functionality is assured to our users. With just
              a click users can get help from our 24/7 customer support
              representatives.
            </Text>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="0px"
            gap="22px"
            width="228px"
            paddingBottom="50px"
          >
            <Key />
            <Heading
              maxW="228px"
              minHeight="22px"
              fontStyle="normal"
              fontWeight="600"
              fontSize="18px"
              lineHeight="24px"
              textAlign="center"
              color="#122633"
              flex="none"
              order="0"
              fontFamily="fira sans"
              alignSelf="stretch"
              flexGrow="0"
            >
              Fraud protection
            </Heading>
            <Text
              maxW="228px"
              maxH="87px"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="19px"
              textAlign="center"
              letterSpacing="0.1px"
              color="bgray2"
              flex="none"
              fontFamily="fira sans"
              order="1"
              alignSelf="stretch"
              flexGrow="0"
            >
              Our users’ safety is our top priority. We built from the ground up
              with state of the art technologies to ensure that your funds and
              data are better protected.{' '}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Fragment>
  )
}

export default WhyBarter
