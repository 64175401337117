import { Box, Flex, Heading, Hide, Spacer, Text } from '@chakra-ui/react'

import { HandLeft, HandRight } from 'assets'

import { routes } from 'lib'
import Image from 'next/image'
import React, { Fragment } from 'react'
import { WrapLink, YellowButton } from 'ui'

const Bread: React.FC<unknown> = () => {
  return (
    <Fragment>
      <Flex 
        flexDirection="row" 
        h={{base:'310px', lg:'480px'}} 
        background="#502D80"
        align="center"
        justify={["center"]}
        minW="100%" 
        overflow="hidden"
        >
        <Hide below="md">
          <Flex align="center">
          <Box boxSize="auto">
                  <Image objectFit="cover" src={HandLeft} alt="Hand Left" />
          </Box>
          </Flex>
          <Spacer />
        </Hide>
        <Box
          textAlign={'center'}
          color="#ffffff"
        >
          <Heading lineHeight="40px" fontSize='26px'>Start Bartering up Today!</Heading>
          <Text  pt={3}
          fontSize="14px"
          lineHeight="19px"
          textAlign="center"
          >
            ...and experience the power of crypto wherever you are.
          </Text>
          <Box pt={8}>
            <WrapLink href={routes.escrow.register}>
              <YellowButton rounded="sm" h={'44px'} w={[270]}>
                Get started
              </YellowButton>
            </WrapLink>
          </Box>
        </Box>
        <Hide below="md">
          <Spacer />
          <Flex align="center">
          <Box boxSize="auto">
                  <Image objectFit="cover" src={HandRight} alt="Hand Right" />

          </Box>
          </Flex>
        </Hide>
      </Flex>
    </Fragment>
  )
}

export default Bread
