import {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  Heading,
  Hide,
  Show,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react'
import { BitcoinIcon, EthereumIcon, TetherIcon } from 'bundle/assets'
import BinanceIcon from 'bundle/assets/BinanceIcon'
import { TrendingGreen, TrendingRed } from 'icons'
import { routes, toCommaValue } from 'lib'
import { useRouter } from 'next/router'
import { useAppData } from 'providers'
import React, { Fragment, useMemo } from 'react'
import { WrapLink, YellowButton } from 'ui'

interface ISupportedAsset {
  name: string
  code: string
  icon: JSX.Element
  marketUrl: string
}

const supportedAssets: ISupportedAsset[] = [
  {
    name: 'Bitcoin',
    code: 'BTC',
    icon: <BitcoinIcon width="35" height="35" />,
    marketUrl: `${routes.escrow.p2p}?asset=BTC`,
  },
  {
    name: 'Ethereum',
    code: 'ETH',
    icon: <EthereumIcon width="35" height="35" />,
    marketUrl: `${routes.escrow.p2p}?asset=ETH`,
  },
  {
    name: 'Binance Coin',
    code: 'BNB',
    icon: <BinanceIcon width="35" height="35" />,
    marketUrl: `${routes.escrow.p2p}?asset=BNB`,
  },
  {
    name: 'Tether USD',
    code: 'USDT',
    icon: <TetherIcon width="35" height="35" />,
    marketUrl: `${routes.escrow.p2p}?asset=USDT`,
  },
]

const Crypto: React.FC<unknown> = () => {
  const router = useRouter()
  const { marketTrends } = useAppData()
  const showElement = useBreakpointValue({ base: 'none', md: 'table-cell' })

  const trends = useMemo(
    () =>
      marketTrends
        .map((trend) => {
          const meta = supportedAssets.find((a) => a.code === trend?.baseAsset)
          return meta ? { ...trend, meta } : undefined
        })
        .filter((t) => !!t),
    [marketTrends]
  )

  return (
    <Fragment>
      <VStack
        maxWidth="100%"
        maxHeight="100%"
        alignItems="center"
        textAlign="center"
        background="#E69330"
        pb={20}
        pt={30}
      >
        <Box p={['20px', '50px']}>
          <Heading as="h2" color="#FFFFFF">
            Supported Crypto Assets
          </Heading>
        </Box>
        <Center>
          <Container maxW={{base:"container.lg", md: "container.md"}}>
            <Flex align='center' justify='center'>
              <Grid
                templateColumns={['repeat(2, 1fr)','repeat(2, 1fr)','repeat(2, 1fr)','repeat(4, 1fr)', 'repeat(4, 1fr)']}
                gap={[2, 4 ,6 ,6 ,8, 10]}
              >
                {supportedAssets.map((item) => (
                  <Box width="100%" minW={['150px','200px','220px','220px','244px']} key={item.name}>
                    <Center
                      px="3"
                      py="3"
                      bg="red.500"
                      width="100%"
                      background={'#FFFFFF'}
                      borderRadius={9}
                      alignItems={'center'}
                    >
                      <Flex alignItems={'center'} gap={[1, 2]}>
                        <Box>{item.icon}</Box>
                        <Text
                          fontSize={['12px','12px', '12px','15px', '18px']}
                          fontWeight={700}
                          color="bgray2"
                        >
                          {item.name}
                          <Text as="span" color={'#C1C1C1'} pl={1}>
                            ({item.code})
                          </Text>
                        </Text>
                      </Flex>
                    </Center>
                  </Box>
                ))}
              </Grid>
            </Flex>
          </Container>
        </Center>
      </VStack>

      {/* Market Trends Section */}
      <Container maxW="container.xl" p={[4, 16]}>
        <Box>
          <Heading as="h2" color="#122633" py={8}>
            Today&apos;s Market Trends
          </Heading>
        </Box>

        <TableContainer>
          <Table
            boxSizing="border-box"
            variant="simple"
            border="1px"
            borderColor="#E9E9E9"
            size={'md'}
          >
            <Thead my="10px">
              <Tr>
                <Th p={6} display={showElement}>
                  #
                </Th>
                <Th>Coin</Th>
                <Th>Last Price</Th>
                <Th display={showElement}>Change (24HRS)</Th>
                <Th display={showElement}>Trade</Th>
              </Tr>
            </Thead>
            <Tbody>
              {trends.map((item, index) => (
                <Tr key={index}>
                  <Td display={showElement}>{index + 1}</Td>
                  <Td>
                    <Stack direction={['row']} alignItems="center">
                      <Text>{item.meta.icon}</Text>
                      <Show below="md">
                        <Text>{item.meta.code}</Text>
                      </Show>
                      <Hide below="md">
                        <Text>{item.meta.name}</Text>
                      </Hide>
                    </Stack>
                  </Td>
                  <Td>{`${item.quoteAsset} ${toCommaValue(
                    item.averagePrice,
                    2
                  )}`}</Td>
                  <Td display={showElement}>
                    <Stack direction={['row']} alignItems="center">
                      <Text>
                        {+item.priceChangePercent < 0 ? (
                          <TrendingRed />
                        ) : (
                          <TrendingGreen />
                        )}
                      </Text>
                      <Text>
                        {+item.priceChangePercent < 0
                          ? item.priceChangePercent * -1
                          : item.priceChangePercent}
                        %
                      </Text>
                    </Stack>
                  </Td>
                  <Td display={showElement}>
                    <WrapLink href={item.meta.marketUrl}>
                      <YellowButton size="sm" rounded="sm">
                        Buy
                      </YellowButton>
                    </WrapLink>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Fragment>
  )
}

export default Crypto
