import { Button, ButtonProps } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

type INeutralButton = React.FC<PropsWithChildren<ButtonProps>>

const NeutralButton: INeutralButton = ({ children, ...props }) => {
  return (
    <Button
      color="#122633"
      boxSizing='border-box'
      colorScheme="white"
      borderColor="bitcodeColor"
      maxWidth='76px'
      maxHeight='42px'
      border= '1px solid #122633'
      borderRadius= '4px'
      _focus={{ boxShadow: 'none' }}
      _hover={{ outline: 'none', shadow: 'dark-lg' }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default NeutralButton