import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Hide,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import { GetImageAlt } from 'assets'
import { StepOne, StepThree, StepTwo } from 'icons'
import Image from 'next/image'
import React, { Fragment } from 'react'

const GetStarted: React.FC<unknown> = () => {
  return (
    <Fragment>
      <VStack
        my={5}
        p={[5, 0]}
        maxWidth="100%"
        maxHeight="100%"
        alignItems="center"
        textAlign="center"
      >
        <Box pt={['20px', '40px']}>
          <Heading as="h2" color="#122633" py={4}>
            How to get started with BitBarter
          </Heading>
        </Box>
      </VStack>

      <Box py={2} mb={10}>
        <Container maxW="container.xl" px={[0, 5]}>
          <Stack
            color="white"
            direction={['column', 'row']}
            alignItems={['none', 'center']}
          >
            <Center>
              <VStack gap={5}  align={'self-start'}>
                <Box p={4} background="#FFF9F2" w={['320px', 460]}>
                  <Flex alignItems={'flex-start'}>
                    <Box pr={3}>
                      <StepOne />
                    </Box>
                    <Box textAlign={['center', 'left']}>
                      <Heading color="#122633" as="h4" size="md" pb={2}>
                        Create an account
                      </Heading>
                      <Text color="bgray2" fontSize="14px">
                        Sign up for an account on our website with your email
                      </Text>
                    </Box>
                  </Flex>
                </Box>

                <Box p={4} background="#F9F5FF" w={['320px', 460]}>
                  <Flex>
                    <Box pr={3}>
                      <StepTwo />
                    </Box>
                    <Box textAlign={['center', 'left']}>
                      <Heading color="#122633" as="h4" size="md" pb={2}>
                        Verify your account
                      </Heading>
                      <Text color="bgray2" fontSize="14px">
                        Complete your KYC verification and access more BitBarter
                        features.
                      </Text>
                    </Box>
                  </Flex>
                </Box>

                <Box p={4} background="#FFFDF5" w={['320px', 460]}>
                  <Flex>
                    <Box pr={3}>
                      <StepThree />
                    </Box>
                    <Box textAlign={['center', 'left']}>
                      <Heading color="#122633" as="h4" size="md" pb={2}>
                        Trade, Pay and Remit
                      </Heading>
                      <Text color="bgray2" fontSize="14px">
                        Start trading at best P2P market rates or process
                        payments using BitCode.
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </VStack>
            </Center>

            <Center flex={1}>
              <Hide below="md">
                <Box boxSize="auto" maxW={{lg:'701px'}} maxH={{lg:'404px'}}>
                  <Image objectFit="cover" src={GetImageAlt} alt="hero frame" />
                </Box>
              </Hide>
            </Center>
          </Stack>
        </Container>
      </Box>
    </Fragment>
  )
}

export default GetStarted