import {
  Box,
  Text,
  Heading,
  Show,
  VStack,
  Hide,
  Container,
  Stack,
  Center,
} from '@chakra-ui/react'

import { PhoneFrame } from 'icons'

import React, { Fragment } from 'react'

const Features: React.FC<unknown> = () => {
  return (
    <Fragment>
      <VStack
        my={5}
        p={[5, 0]}
        maxWidth="100%"
        maxHeight="100%"
        alignItems="center"
        textAlign="center"
      >
        <Box pt={['20px', '40px']}>
          <Heading as="h2" color="#122633">
            Be financially available with BitBarter
          </Heading>
        </Box>

        <Box>
          <Text fontSize="15px" color="bgray2">
            Send, Receive, Save and Earn money anytime, anywhere.
          </Text>
        </Box>
      </VStack>

      <Box>
        <Hide below="sm">
          <Container maxW="container.xl" px="5">
            <Stack
              color="white"
              direction={['column', 'row']}
              alignItems={['none', 'center']}
            >
              <Center w="450px" mr={2}>
                <VStack
                  gap={7}
                  ml={10}
                  borderLeft="4px"
                  align={'self-start'}
                  borderColor="#E4F8FF"
                >
                  <Box borderLeft="4px" borderColor="#000000" ml={-1} pl={4}>
                    <Heading color="#122633" as="h4" size="md" pb={2}>
                      Buy and Sell Cryptocurrencies
                    </Heading>
                    <Text color="bgray2" fontSize="14px">
                      Our P2P marketplace makes it easy for you to exchange
                      digital assets like BTC, ETH, USDT and BNB to fiat/cash.
                    </Text>
                  </Box>

                  <Box borderLeft="4px" ml={-1} pl={4}>
                    <Heading color="#122633" as="h4" size="md" pb={2}>
                      BitCode Pay
                    </Heading>
                    <Text color="bgray2" fontSize="14px">
                      With BitBarter, you can request for payments in crypto and
                      send crypto vouchers to anyone around the world.
                    </Text>
                  </Box>

                  <Box borderLeft="4px" ml={-1} pl={4}>
                    <Heading color="#122633" as="h4" size="md" pb={2}>
                      Digital Wallet
                    </Heading>
                    <Text color="bgray2" fontSize="14px">
                      BitBarter offers you a secured digital wallet that keeps
                      your crypto accessible anytime, anywhere and on the go.
                    </Text>
                  </Box>
                </VStack>
              </Center>

              <Center flex={1}>
                <Hide below="md">
                  <Box boxSize="auto">
                    <PhoneFrame />
                  </Box>
                </Hide>
              </Center>
            </Stack>
          </Container>
        </Hide>
        <Show below="sm">
          <Container maxW="container.xl" px="5">
            <Stack color="white">
              <VStack mb={10} textAlign="center" gap={8}>
                <Box>
                  <Heading color="#122633" as="h4" size="md" pb={2}>
                    Buy and Sell Cryptocurrencies
                  </Heading>
                  <Text color="bgray2" fontSize="14px">
                    Our P2P marketplace makes it easy for you to exchange
                    digital assets like BTC, ETH, USDT and BNB to fiat/cash.
                  </Text>
                </Box>

                <Box>
                  <Heading color="#122633" as="h4" size="md" pb={2}>
                    BitCode Pay
                  </Heading>
                  <Text color="bgray2" fontSize="14px">
                    With BitBarter, you can request for payments in crypto and
                    send crypto vouchers to anyone around the world.
                  </Text>
                </Box>

                <Box>
                  <Heading color="#122633" as="h4" size="md" pb={2}>
                    Digital Wallet
                  </Heading>
                  <Text color="bgray2" fontSize="14px">
                    BitBarter offers you a secured digital wallet that keeps
                    your crypto accessible anytime, anywhere and on the go.
                  </Text>
                </Box>
              </VStack>
            </Stack>
          </Container>
        </Show>
      </Box>
    </Fragment>
  )
}

export default Features
