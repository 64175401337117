import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Hide,
  Show,
  Stack,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react'
import { HeroMobile, HeroRightFrame } from 'assets'
import { CheckIcon } from 'icons'
import { routes } from 'lib'
import Image from 'next/image'
import React, { Fragment } from 'react'
import { WrapLink, YellowButton } from 'ui'

const Hero: React.FC<unknown> = () => {
  return (
    <Fragment>
      <Box>
        <Container maxW="container.xl" py="3" px="5">
          <Stack
            color="white"
            direction={['column', 'row']}
            alignItems={['none', 'center']}
          >
            <Wrap>
              <Center w="450px" mr={2}>
                <VStack align={'self-start'} gap={2}>
                  <Heading color="bitcodeYellow" mb={15} mt={[5, 0]}>
                    Buy, Sell & Remit Crypto Safely in Minutes
                  </Heading>
                  <Box color="#122633">
                    <Box>
                      <Flex alignItems={'center'}>
                        <Box as="span">
                          <CheckIcon />
                        </Box>
                        <Text ml={2} mb={1}>
                          Buy and sell digital currencies seamlessly from
                          anywhere.
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                  <Box>
                    <WrapLink href={routes.escrow.register}>
                      <YellowButton
                        w={['200px', '270px']}
                        h={['44px']}
                        mt={[30, 50]}
                      >
                        Get started
                      </YellowButton>
                    </WrapLink>
                  </Box>
                </VStack>
              </Center>
            </Wrap>

            <Center flex={1}>
              <Hide below="sm">
                <Box boxSize="auto">
                  <Image src={HeroRightFrame} priority={true} alt="Hero Image" />
                </Box>
              </Hide>
              <Show below="sm">
                <Box boxSize="auto">
                  <Image objectFit="cover" src={HeroMobile} priority={true} alt="Hero Image" />
                </Box>
              </Show>
            </Center>
          </Stack>
        </Container>
      </Box>
    </Fragment>
  )
}

export default Hero
