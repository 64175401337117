import { Box, BoxProps } from '@chakra-ui/react'
import { IActiveNav, language } from 'lib'
import Script from 'next/script'
import React, { PropsWithChildren } from 'react'
import { BitBarterLogo, FreshchatWidget, MainFooter, MainHeader, MainLayout } from 'ui'

interface ILayout extends BoxProps {
  title?: string
  activeNav?: IActiveNav
}

const GA_MEASUREMENT_ID = 'G-GXZL17L4V7'

const Layout: React.FC<PropsWithChildren<ILayout>> = ({
  children,
  title,
  activeNav,
}) => {
  return (
    <MainLayout title={title ?? language.homeDefaultTitle}>
      {/* G-Analytics */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
        strategy="lazyOnload"
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}', {
            page_path: window.location.pathname,
          });
        `}
      </Script>
      {/* END: G-Analytics */}

      <MainHeader NavLogo={BitBarterLogo} activeNav={activeNav} />
      <Box maxW="100%" mb="8">
        {children}
      </Box>
      <MainFooter />
      <FreshchatWidget />
    </MainLayout>
  )
}

export default Layout
