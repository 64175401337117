import {Box, Text, Heading, Flex, Link} from '@chakra-ui/react'
import React, { Fragment } from 'react'

const ContactCard: React.FC<unknown> = () => {
  const contactInfo = [
    {
      text: 'For general inquiries, complains or suggestions:',
      email: 'support@bitbarter.io',
    },
    {
      text: 'For merchant related inquiries:',
      email: 'p2p@bitbarter.io',
    },
    {
      text: 'For business partnerships:',
      email: 'partnership@bitbarter.io',
    },
  ]
  return (
    <Fragment>
      <Box background="#FFF9F2" py={[10, 36]}>
        <Box
          fontStyle="normal"
          fontWeight="800"
          textAlign={['left', 'center']}
          color="#122633"
          pl={['5', '0']}
        >
          <Heading>How can we help?</Heading>
          <Text fontWeight="400" fontSize={['20px', '14px']} color="bgray2">
            We love hearing from you, so don’t hesitate to reach out!
          </Text>
        </Box>

        <Flex
          display="flex"
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="center"
          maxW="100%"
          gap={['10', '10']}
          py={16}
        >
          {contactInfo.map((info, index) => {
            return (
              <Box
                p={'20px 30px'}
                background="#FFFFFF"
                boxShadow="0px 1px 6px rgba(0, 0, 0, 0.01), 0px 2px 13px rgba(0, 0, 0, 0.02)"
                borderRadius="8px"
                w={['100%', '288px']}
                mx={[2, 0]}
                key={index}
              >
                <Text
                  fontStyle="normal"
                  fontWeight="500"
                  fontSize={['16', '18']}
                  color="#122633"
                >
                  {info.text}
                </Text>
                <Link
                  href={"mailto:"+info.email}
                  fontSize="16px"
                  textDecoration="underline"
                  color="#E69330"
                  pt={8}
                >
                  {info.email}
                </Link>
              </Box>
            )
          })}
        </Flex>
      </Box>
    </Fragment>
  )
}

export default ContactCard
