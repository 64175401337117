import { configureStore } from '@reduxjs/toolkit'
import { handleRequests } from '@redux-requests/core'
import {
  reduxDrivers,
  reduxOnError,
  reduxOnSuccess,
  reduxRequestReset,
  reduxReducers, reduxOnRequest,
} from 'lib'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const { util } = reduxDrivers
const { recentBlogPosts } = reduxReducers
const isSSR = typeof window === 'undefined'
const preloadedState = !isSSR ? (window as any).__INITIAL_STATE__ : {}

const { requestsReducer, requestsMiddleware } = handleRequests({
  driver: { default: util, util },
  ssr: isSSR ? 'server' : 'client',
  onSuccess: reduxOnSuccess,
  onError: reduxOnError,
})

export const reduxStore = configureStore({
  reducer: {
    requests: requestsReducer,
    recentBlogPosts,
  },
  preloadedState,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [...requestsMiddleware],
})

export type RootState = ReturnType<typeof reduxStore.getState>
export type AppDispatch = typeof reduxStore.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const resetState = (stateKey: string): void => {
  const actionType = reduxRequestReset(stateKey)
  reduxStore.dispatch({
    type: actionType,
  })
}
