import { Box, Container, Flex, Heading, Link, Text } from '@chakra-ui/react'
import { BlogOne, BlogTwo } from 'assets'
import Image from 'next/image'
import React, { Fragment } from 'react'

const Academy: React.FC<unknown> = () => {
  return (
    <Fragment>
      <Container
        maxW="100%"
        maxH={['964px', '566px']}
        background="#FAFAFA"
        px={['0px','0px','20px','100px', '121px']}
        py={['0px', '63px']}
      >
        <Flex
          maxW={['100%', '1198px']}
          minH={['964px', '350px', '300px', '300px', '420px']}
          maxH={['964px', '420px']}
          background="#E69330"
          borderRadius={['0px', '28px']}
          flexGrow='1'
          flexDir={['column', 'row']}
          align={['center', 'center']}
          justify={['center', 'space-between']}
          padding={['0 0 0px 0 ', '0 15px 0 15px', '100px 30px 100px 30px', '100px 30px 100px 30px', '160px 66px 162px 63px']}
        >
          <Flex
            flexDir="column"
            align={['center', 'flex-start']}
            textAlign={['center', 'left']}
            alignItems={['center', 'flex-start']}
            padding="0px"
            gap="28px"
            maxW={['293px','293px','230px','293px', '293px']}
            minH="158px"
            mt={['0','0','0px','0px', '100px']}
            mb={['50px','50px','0px','0px', '100px']}
            
          >
            <Box
              display="flex"
              flexDir="column"
              gap={['8px','20px','30px','30px', '8px']}
              maxW="293px"
              maxH="86px"
            >
              <Heading
                as="h2"
                maxW={['300px', '274px']}
                maxH="40px"
                fontFamily="Fira Sans"
                fontStyle="normal"
                fontWeight="800"
                fontSize={['32px','22px','28px','28px', '32px']}
                lineHeight={['40px','28px','28px', '40px']}
                color="#FFFFFF"
              >
                BitBarter Academy
              </Heading>
              <Text
                maxW="293px"
                maxH="38px"
                fontStyle="normal"
                fontWeight="400"
                fontSize={['14px','12px','12px','14px', '14px']}
                lineHeight={['19px','15px','15px','19px', '19px']}
                letterSpacing="0.1px"
                color="#FFFFFF"
              >
                An investment in knowledge pays the best interest - Benjamin Franklin.
              </Text>
            </Box>
            <Link
              href="https://blog.bitbarter.io"
              target="_blank"
              colorScheme="#FFFFFF"
              color="#353535"
              bg="#FFFFFF"
              borderRadius="4px"
              display="flex"
              flexDir="row"
              justifyContent="center"
              alignItems="center"
              padding="12px 10px 12px 16px"
              gap="10px"
              minW="163px"
              maxH="44px"
              fontFamily="Fira Sans"
              fontStyle="normal"
              fontWeight="500"
              fontSize="14px"
              lineHeight="20px"
              textAlign="center"
              letterSpacing="0.005em"
            >
              Visit our blog
            </Link>
          </Flex>

          <Flex
            display="flex"
            flexDir={['column', 'row']}
            justifyContent="space-between"
            maxW="600px"
            gap="10px"
          >
            <Box
              maxW="295px"
              minH={['303px','303px','303px','303px', '303px']}
              bg="#F9F5FF"
              borderRadius="8px"
              display="flex"
              flexDir="column"
              justifyContent="flex-start"
            >
              <Image src={BlogOne} alt="Blog Image" />
              <Heading
                as="h2"
                fontFamily="Fira Sans"
                fontStyle="normal"
                fontWeight="800"
                fontSize="18px"
                lineHeight="24px"
                color="#122633"
                pt="20px"
                pl="17px"
              >
                Uses Of Cryptocurrency
              </Heading>
              <Link
                href="https://blog.bitbarter.io"
                target="_blank"
                maxW="200px"
                maxH="20px"
                fontFamily="Fira Sans"
                fontStyle="normal"
                fontWeight="500"
                fontSize="14px"
                lineHeight="20px"
                letterSpacing="0.005em"
                color="#353535"
                display="flex"
                pt="47px"
                pl="17px"
              >
                Read more <Box as="span">&nbsp; &#8594;</Box>
              </Link>
            </Box>
            <Box
              maxW="295px"
              minH={['303px','303px','0px','19px', '303px']}
              bg="#F9F5FF"
              borderRadius="8px"
              display="flex"
              flexDir="column"
              justifyContent="flex-start"
            >
              <Image src={BlogTwo} alt="Blog Image" />
              <Heading
                as="h2"
                fontFamily="Fira Sans"
                fontStyle="normal"
                fontWeight="800"
                fontSize="18px"
                lineHeight="24px"
                color="#122633"
                pt="20px"
                pl="17px"
                pr="17px"
              >
                Tips to trade safely on peer to peer
              </Heading>
              <Link
                href="https://blog.bitbarter.io"
                target="_blank"
                maxW="200px"
                maxH="20px"
                fontFamily="Fira Sans"
                fontStyle="normal"
                fontWeight="500"
                fontSize="14px"
                lineHeight="20px"
                letterSpacing="0.005em"
                color="#353535"
                pt="23px"
                pl="17px"
                display="flex"
              >
                Read more <Box as="span">&nbsp; &#8594;</Box>
              </Link>
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Fragment>
  )
}

export default Academy