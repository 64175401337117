const BinanceIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3168_43107)">
      <path
        d="M15.9998 31.68C24.6596 31.68 31.6798 24.6598 31.6798 16C31.6798 7.34018 24.6596 0.320007 15.9998 0.320007C7.34 0.320007 0.319824 7.34018 0.319824 16C0.319824 24.6598 7.34 31.68 15.9998 31.68Z"
        fill="#F3BA2C"
      />
      <path
        d="M4.74805 16.0467L7.45685 13.3382L10.1317 16.0128L7.42293 18.7216L4.74805 16.0467ZM16.045 3.82593L23.4338 11.2147L20.2776 14.3709L16.8143 10.9075C16.602 10.6952 16.3142 10.5758 16.014 10.5754C15.7138 10.5751 15.4258 10.6938 15.213 10.9056L11.7176 14.401L8.59381 11.2768L16.045 3.82593V3.82593Z"
        fill="white"
      />
      <path
        d="M13.3076 16.0182L16.0164 13.3094L18.6913 15.984L15.9825 18.6928L13.3076 16.0182ZM21.8673 15.9872L24.5761 13.2784L27.2503 15.9526L24.5415 18.6614L21.8673 15.9872Z"
        fill="white"
      />
      <path
        d="M15.9546 28.1738L8.56641 20.785L11.7222 17.6291L15.185 21.0919C15.6061 21.5312 16.3648 21.5338 16.7862 21.0944L20.2816 17.5991L23.4058 20.7229L15.9546 28.1738V28.1738Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3168_43107">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default BinanceIcon
