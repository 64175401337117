import 'ui/dist/index.css'
import '../styles/global.css'
import type { AppProps } from 'next/app'
import { useNProgress } from 'lib'
import Head from 'next/head'
import { AppWrapper } from 'bundle'

export default function BitBarterApp({ Component, pageProps }: AppProps) {
  useNProgress()
  return (
    <AppWrapper>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
      <Component {...pageProps} />
    </AppWrapper>
  )
}
