const EthereumIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 16.5C0 7.66344 7.16344 0.5 16 0.5C24.8366 0.5 32 7.66344 32 16.5C32 25.3366 24.8366 32.5 16 32.5C7.16344 32.5 0 25.3366 0 16.5Z"
      fill="#636890"
    />
    <path
      d="M21.8937 16.1938L16.3937 9.19375C16.347 9.13399 16.2872 9.08566 16.219 9.05242C16.1508 9.01918 16.0759 9.00191 16 9.00191C15.9241 9.00191 15.8492 9.01918 15.781 9.05242C15.7128 9.08566 15.653 9.13399 15.6062 9.19375L10.1062 16.1938C10.0362 16.2805 9.99805 16.3885 9.99805 16.5C9.99805 16.6115 10.0362 16.7195 10.1062 16.8062L15.6062 23.8062C15.653 23.866 15.7128 23.9143 15.781 23.9476C15.8492 23.9808 15.9241 23.9981 16 23.9981C16.0759 23.9981 16.1508 23.9808 16.219 23.9476C16.2872 23.9143 16.347 23.866 16.3937 23.8062L21.8937 16.8062C21.9638 16.7195 22.0019 16.6115 22.0019 16.5C22.0019 16.3885 21.9638 16.2805 21.8937 16.1938ZM16.5 10.9438L20.7125 16.3062L16.5 18.225V10.9438ZM15.5 18.225L11.2875 16.3062L15.5 10.9438V18.225ZM15.5 19.325V22.0562L12.1625 17.8062L15.5 19.325ZM16.5 19.325L19.8375 17.8062L16.5 22.0562V19.325Z"
      fill="white"
    />
  </svg>
)

export default EthereumIcon
