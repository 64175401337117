import {
  Box,
  Text,
  Grid,
  HStack,
  Heading,
  GridItem,
  Flex,
  Hide,
  Container,
  useDisclosure,
  Show,
  VStack,
  BoxProps,
} from '@chakra-ui/react'
import { AboutHeroImage } from 'assets'
import { Ellipse } from 'icons'
import React, { Fragment, PropsWithChildren } from 'react'
import Image from 'next/image'

interface IAboutHero extends BoxProps {
  title?: string
}

const AboutHero: React.FC<PropsWithChildren<IAboutHero>> = ({
  children,
  title,
  ...props
}) => {
  return (
    <Fragment>
      <Flex
        maxWidth="100vw"
        maxHeight={['577', '624']}
        pt={['0', '10']}
        background="#E69330"
        flexDir={['column', 'row']}
      >
        <VStack
          w="100%"
          h="100%"
          alignItems="flex-start"
          spacing={5}
          pt={['0', '60']}
        >
          <Box
            display="flex"
            flexDir="column"
            justifyContent="center"
            maxW={['350', 'xl']}
            mx={['5', 'auto']}
            pt={['10', '0']}
            gap={['3', '0']}
          >
            <Heading color="#FFFFFF">Welcome to BitBarter</Heading>
            <Text
              maxW={['280', '439']}
              maxH={['46', '200']}
              size={['md', 'lg']}
              fontStyle="normal"
              fontWeight="400"
              color="#FFFFFF"
              mt={6}
            >
              A secure platform for batering crypto and settling payment.
            </Text>
          </Box>
        </VStack>

        <HStack w="100%" h="100%" alignItems="flex-start">
          <Box
            boxSize="100%"
            pt={['5', '70']}
            pl="80px"
            mb={['5', '150']}
            pr={['10', '0']}
          >
            <Image objectFit="cover" src={AboutHeroImage} alt="hero frame" />
          </Box>
        </HStack>
        <Hide below="md">
          <Box as="span" ml="-500px" mt="68px" width="100%" maxHeight="931px">
            <Ellipse />
          </Box>
        </Hide>
      </Flex>
    </Fragment>
  )
}

export default AboutHero
