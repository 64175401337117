var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/api/clients.ts
import {
  AssetConfigsConfigsClient,
  RewardsEarningsClient,
  RewardsReferralsClient,
  UsersAccountsClient,
  UsersAuthClient,
  UsersSecuritiesClient,
  UsersSettingsClient,
  WebhookConfigsClient,
  WebhookEventsClient
} from "@bitbarter/exchange-api-gateway-client";
import { UtilsClient as CoreUtilsClient } from "@bitbarter/core-asset-configs-client";
import axios3 from "axios";

// src/config/api.config.ts
var apiConfig = {
  wsBaseUrl: process.env.NEXT_PUBLIC_WS_BASE_URL,
  relBaseUrl: process.env.NEXT_PUBLIC_REL_REST_URL,
  gqlBaseUrl: process.env.NEXT_PUBLIC_GQL_BASE_URL,
  restBaseUrl: process.env.NEXT_PUBLIC_REST_BASE_URL,
  clientToken: process.env.REST_CLIENT_TOKEN,
  clientId: process.env.REST_CLIENT_ID,
  exchangeGatewayBasePath: process.env.NEXT_PUBLIC_GATEWAY_BASE_PATH,
  googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  coreAssetConfigsBaseUrl: process.env.NEXT_PUBLIC_CORE_ASSET_CONFIG_BASE_URL || "https://asset-configs-dev.bitbarter.io"
};

// src/config/route.config.ts
var AppHostUrlMap = {
  MAIN: process.env.NEXT_PUBLIC_MAIN_HOST ?? "",
  BITCODE: process.env.NEXT_PUBLIC_BITCODE_HOST ?? "",
  BITBARTER: process.env.NEXT_PUBLIC_BITBARTER_HOST ?? ""
};

// src/config/app.config.ts
var environment = process.env.NODE_ENV;
var baseRefUrl = process.env.NEXT_PUBLIC_REF_BASE_URL || "https://ref.thebitbarter.co";

// src/definitions/enum/common.enum.ts
var PageOrder = /* @__PURE__ */ ((PageOrder2) => {
  PageOrder2["DESC"] = "DESC";
  PageOrder2["ASC"] = "ASC";
  return PageOrder2;
})(PageOrder || {});
var ThemeType = /* @__PURE__ */ ((ThemeType2) => {
  ThemeType2["LIGHT"] = "LIGHT";
  ThemeType2["DARK"] = "DARK";
  return ThemeType2;
})(ThemeType || {});
var UserRole = /* @__PURE__ */ ((UserRole2) => {
  UserRole2["USER"] = "USER";
  UserRole2["MERCHANT"] = "MERCHANT";
  UserRole2["ADMIN"] = "ADMIN";
  return UserRole2;
})(UserRole || {});
var UserGender = /* @__PURE__ */ ((UserGender2) => {
  UserGender2["MALE"] = "MALE";
  UserGender2["FEMALE"] = "FEMALE";
  return UserGender2;
})(UserGender || {});
var AssetType = /* @__PURE__ */ ((AssetType2) => {
  AssetType2["CRYPTO"] = "CRYPTO";
  AssetType2["FIAT"] = "FIAT";
  return AssetType2;
})(AssetType || {});
var AssetNetworkType = /* @__PURE__ */ ((AssetNetworkType2) => {
  AssetNetworkType2["BTC"] = "BTC";
  AssetNetworkType2["ETH"] = "ETH";
  AssetNetworkType2["BSC"] = "BSC";
  AssetNetworkType2["ERC20"] = "ERC20";
  AssetNetworkType2["BEP20"] = "BEP20";
  AssetNetworkType2["TRC20"] = "TRC20";
  return AssetNetworkType2;
})(AssetNetworkType || {});
var WalletCurrency = /* @__PURE__ */ ((WalletCurrency2) => {
  WalletCurrency2["BNB"] = "BNB";
  WalletCurrency2["BTC"] = "BTC";
  WalletCurrency2["ETH"] = "ETH";
  WalletCurrency2["USDT"] = "USDT";
  return WalletCurrency2;
})(WalletCurrency || {});
var SecureLevel = /* @__PURE__ */ ((SecureLevel2) => {
  SecureLevel2[SecureLevel2["MODERATE"] = 0] = "MODERATE";
  SecureLevel2[SecureLevel2["SECURE"] = 1] = "SECURE";
  SecureLevel2[SecureLevel2["VERY_SECURE"] = 2] = "VERY_SECURE";
  return SecureLevel2;
})(SecureLevel || {});
var OtpReqType = /* @__PURE__ */ ((OtpReqType2) => {
  OtpReqType2["SEND_BITCODE_VOUCHER"] = "SEND_BITCODE_VOUCHER";
  OtpReqType2["ADD_PAYMENT_OPTION"] = "ADD_PAYMENT_OPTION";
  OtpReqType2["CONFIRM_P2P_TRADE"] = "CONFIRM_P2P_TRADE";
  OtpReqType2["DISABLE_LOGIN_2FA"] = "DISABLE_LOGIN_2FA";
  OtpReqType2["RESET_PASSWORD"] = "RESET_PASSWORD";
  OtpReqType2["SETTLE_BITCODE"] = "SETTLE_BITCODE_INVOICE";
  OtpReqType2["UPDATE_PAYMENT_OPTION"] = "UPDATE_PAYMENT_OPTION";
  OtpReqType2["VERIFY_DEVICE"] = "VERIFY_DEVICE";
  OtpReqType2["VERIFY_EMAIL"] = "VERIFY_EMAIL";
  OtpReqType2["VERIFY_LOGIN"] = "VERIFY_LOGIN";
  OtpReqType2["VERIFY_PHONE"] = "VERIFY_PHONE";
  OtpReqType2["WITHDRAWAL"] = "WITHDRAWAL";
  return OtpReqType2;
})(OtpReqType || {});
var StoreKeys = /* @__PURE__ */ ((StoreKeys2) => {
  StoreKeys2["AuthOtp"] = "auth.otp-options";
  return StoreKeys2;
})(StoreKeys || {});
var KycProvider = /* @__PURE__ */ ((KycProvider2) => {
  KycProvider2["SMILE"] = "SMILE";
  KycProvider2["DOJAH"] = "DOJAH";
  KycProvider2["VERIFF"] = "VERIFF";
  return KycProvider2;
})(KycProvider || {});
var VerificationType = /* @__PURE__ */ ((VerificationType2) => {
  VerificationType2["ADDRESS_CHECK"] = "ADDRESS_CHECK";
  VerificationType2["BIOMETRIC_CHECK"] = "BIOMETRIC_CHECK";
  VerificationType2["ID_CHECK"] = "ID_CHECK";
  VerificationType2["KYT_CHECK"] = "KYT_CHECK";
  return VerificationType2;
})(VerificationType || {});

// src/definitions/enum/auth.enum.ts
var AuthVerifyType = /* @__PURE__ */ ((AuthVerifyType2) => {
  AuthVerifyType2["VERIFY_DEVICE"] = "VERIFY_DEVICE";
  AuthVerifyType2["PROVIDE_TOKEN"] = "PROVIDE_TOKEN";
  return AuthVerifyType2;
})(AuthVerifyType || {});
var AnyOtp = /* @__PURE__ */ ((AnyOtp2) => {
  AnyOtp2["APP"] = "APP";
  AnyOtp2["PHONE"] = "PHONE";
  AnyOtp2["EMAIL"] = "EMAIL";
  return AnyOtp2;
})(AnyOtp || {});
var PhoneAppOtp = /* @__PURE__ */ ((PhoneAppOtp2) => {
  PhoneAppOtp2["APP"] = "APP";
  PhoneAppOtp2["PHONE"] = "PHONE";
  return PhoneAppOtp2;
})(PhoneAppOtp || {});
var PhoneEmailOtp = /* @__PURE__ */ ((PhoneEmailOtp2) => {
  PhoneEmailOtp2["PHONE"] = "PHONE";
  PhoneEmailOtp2["EMAIL"] = "EMAIL";
  return PhoneEmailOtp2;
})(PhoneEmailOtp || {});

// src/definitions/enum/market.enum.ts
var OrderType = /* @__PURE__ */ ((OrderType2) => {
  OrderType2["BUY"] = "BUY";
  OrderType2["SELL"] = "SELL";
  return OrderType2;
})(OrderType || {});
var DisputeFrom = /* @__PURE__ */ ((DisputeFrom2) => {
  DisputeFrom2["MAKER"] = "MAKER";
  DisputeFrom2["TAKER"] = "TAKER";
  return DisputeFrom2;
})(DisputeFrom || {});
var OrderStatus = /* @__PURE__ */ ((OrderStatus2) => {
  OrderStatus2["OPEN"] = "OPEN";
  OrderStatus2["CLOSED"] = "CLOSED";
  OrderStatus2["FILLED"] = "FILLED";
  OrderStatus2["LOCKED"] = "LOCKED";
  OrderStatus2["PAUSED"] = "PAUSED";
  return OrderStatus2;
})(OrderStatus || {});
var TradeStatus = /* @__PURE__ */ ((TradeStatus2) => {
  TradeStatus2["CANCELLED"] = "CANCELLED";
  TradeStatus2["COMPLETED"] = "COMPLETED";
  TradeStatus2["PENDING"] = "PENDING";
  TradeStatus2["PAID"] = "PAID";
  return TradeStatus2;
})(TradeStatus || {});
var TradeType = /* @__PURE__ */ ((TradeType2) => {
  TradeType2["OFFRAMP"] = "OFFRAMP";
  TradeType2["ONRAMP"] = "ONRAMP";
  return TradeType2;
})(TradeType || {});
var TradeFilterStatus = /* @__PURE__ */ ((TradeFilterStatus2) => {
  TradeFilterStatus2["ALL"] = "ALL";
  TradeFilterStatus2["PENDING"] = "PENDING";
  TradeFilterStatus2["CANCELED"] = "STATUS";
  return TradeFilterStatus2;
})(TradeFilterStatus || {});

// src/definitions/enum/account.enum.ts
var AccountLevelType = /* @__PURE__ */ ((AccountLevelType2) => {
  AccountLevelType2["T0"] = "T0";
  AccountLevelType2["T1"] = "T1";
  AccountLevelType2["T2"] = "T2";
  AccountLevelType2["T3"] = "T3";
  AccountLevelType2["T4"] = "T4";
  AccountLevelType2["T5"] = "T5";
  return AccountLevelType2;
})(AccountLevelType || {});
var AccountStatusType = /* @__PURE__ */ ((AccountStatusType2) => {
  AccountStatusType2["ACTIVE"] = "ACTIVE";
  AccountStatusType2["SUSPENDED"] = "SUSPENDED";
  AccountStatusType2["LOCKED"] = "LOCKED";
  return AccountStatusType2;
})(AccountStatusType || {});
var VerificationStatus = /* @__PURE__ */ ((VerificationStatus3) => {
  VerificationStatus3["BLANK"] = "BLANK";
  VerificationStatus3["FAILED"] = "FAILED";
  VerificationStatus3["PENDING"] = "PENDING";
  VerificationStatus3["REJECTED"] = "REJECTED";
  VerificationStatus3["VERIFIED"] = "VERIFIED";
  VerificationStatus3["REVIEW_REQUIRED"] = "REVIEW_REQUIRED";
  return VerificationStatus3;
})(VerificationStatus || {});
var IdentityType = /* @__PURE__ */ ((IdentityType2) => {
  IdentityType2["PASSPORT"] = "PASSPORT";
  IdentityType2["ID_CARD"] = "ID_CARD";
  IdentityType2["DRIVERS_LICENSE"] = "DRIVERS_LICENSE";
  IdentityType2["RESIDENCE_PERMIT"] = "RESIDENCE_PERMIT";
  return IdentityType2;
})(IdentityType || {});

// src/definitions/enum/bitcode.enum.ts
var BitcodeStatus = /* @__PURE__ */ ((BitcodeStatus2) => {
  BitcodeStatus2["PAID"] = "PAID";
  BitcodeStatus2["PENDING"] = "PENDING";
  BitcodeStatus2["SETTLED"] = "SETTLED";
  BitcodeStatus2["RECALLED"] = "RECALLED";
  BitcodeStatus2["CANCELLED"] = "CANCELLED";
  BitcodeStatus2["PROCESSING"] = "PROCESSING";
  return BitcodeStatus2;
})(BitcodeStatus || {});
var BitCodeType = /* @__PURE__ */ ((BitCodeType2) => {
  BitCodeType2["VOUCHER"] = "VOUCHER";
  BitCodeType2["INVOICE"] = "INVOICE";
  return BitCodeType2;
})(BitCodeType || {});

// src/definitions/enum/components.enum.ts
var AccMenuType = /* @__PURE__ */ ((AccMenuType2) => {
  AccMenuType2["DASHBOARD"] = "DASHBOARD";
  AccMenuType2["WALLET"] = "WALLET";
  AccMenuType2["CONVERT"] = "CONVERT";
  AccMenuType2["BITCODE"] = "BITCODE";
  AccMenuType2["P2P_MARKET"] = "P2P_MARKET";
  AccMenuType2["REMITTANCE"] = "REMITTANCE";
  AccMenuType2["HISTORY"] = "HISTORY";
  AccMenuType2["SUPPORT"] = "SUPPORT";
  AccMenuType2["REWARDS"] = "REWARDS";
  return AccMenuType2;
})(AccMenuType || {});
var HomeMenuType = /* @__PURE__ */ ((HomeMenuType2) => {
  HomeMenuType2["HOME"] = "HOME";
  HomeMenuType2["ABOUT"] = "ABOUT";
  HomeMenuType2["BITCODE"] = "BITCODE";
  HomeMenuType2["ACADEMY"] = "ACADEMY";
  HomeMenuType2["SUPPORT"] = "SUPPORT";
  HomeMenuType2["CONTACT"] = "CONTACT";
  return HomeMenuType2;
})(HomeMenuType || {});

// src/definitions/enum/websocket.enum.ts
var DataSub = /* @__PURE__ */ ((DataSub2) => {
  DataSub2["MARKET_UPDATE"] = "MARKET_UPDATE";
  return DataSub2;
})(DataSub || {});

// src/definitions/enum/wallet.enum.ts
var TxType = /* @__PURE__ */ ((TxType2) => {
  TxType2["SWAP"] = "SWAP";
  TxType2["REWARD"] = "REWARD";
  TxType2["DEPOSIT"] = "DEPOSIT";
  TxType2["WITHDRAWAL"] = "WITHDRAWAL";
  return TxType2;
})(TxType || {});
var TxStatus = /* @__PURE__ */ ((TxStatus2) => {
  TxStatus2["FAILED"] = "FAILED";
  TxStatus2["PENDING"] = "PENDING";
  TxStatus2["PROCESSING"] = "PROCESSING";
  TxStatus2["CANCELLED"] = "CANCELLED";
  TxStatus2["COMPLETED"] = "COMPLETED";
  return TxStatus2;
})(TxStatus || {});
var TxCategory = /* @__PURE__ */ ((TxCategory2) => {
  TxCategory2["BITCODE"] = "BITCODE";
  TxCategory2["REWARD"] = "REWARD";
  TxCategory2["SWAP"] = "SWAP";
  TxCategory2["WALLET"] = "WALLET";
  return TxCategory2;
})(TxCategory || {});

// src/config/wallet.config.ts
var walletDisplayCurrencies = [
  "BTC" /* BTC */,
  "BNB" /* BNB */,
  "ETH" /* ETH */,
  "USDT" /* USDT */
];

// src/api/interceptors.ts
import axios2 from "axios";

// src/constants/timeLimitOptions.ts
var timeLimitOptions = [
  { value: 15, label: "15 mins" },
  { value: 30, label: "30 mins" },
  { value: 45, label: "45 mins" },
  { value: 60, label: "60 mins" },
  { value: 120, label: "120 mins" }
];

// src/constants/account.ts
import { UsersSvnVerificationStatus, VerificationStatus as VerificationStatus2 } from "@bitbarter/exchange-api-gateway-client";
var accountLevelData = {
  T0: { level: 0, text: "None" },
  T1: { level: 1, text: "One" },
  T2: { level: 2, text: "Two" },
  T3: { level: 3, text: "Three" },
  T4: { level: 4, text: "Four" },
  T5: { level: 5, text: "Master" }
};
var UserVerificationStatusMap = {
  [VerificationStatus2.BLANK]: UsersSvnVerificationStatus.BLANK,
  [VerificationStatus2.FAILED]: UsersSvnVerificationStatus.FAILED,
  [VerificationStatus2.PENDING]: UsersSvnVerificationStatus.PENDING,
  [VerificationStatus2.REJECTED]: UsersSvnVerificationStatus.REJECTED,
  [VerificationStatus2.VERIFIED]: UsersSvnVerificationStatus.VERIFIED,
  [VerificationStatus2.REVIEW_REQUIRED]: UsersSvnVerificationStatus.REVIEW_REQUIRED
};

// src/constants/countries.ts
import sc from "states-cities-db";
var COUNTRIES = sc.getCountries();
var getCountryTelCode = (country) => {
  if (!country)
    return null;
  return COUNTRIES.find(({ iso }) => iso === country);
};
var countryOptions = COUNTRIES.map(({ name, iso, prefix }) => ({
  label: name,
  value: iso,
  prefix
}));

// src/constants/timeouts.ts
var TEN_MINUTES_TIMEOUT = 600;
var TWO_MINUTES_TIMEOUT = 120;

// src/constants/tokenLengths.ts
var EMAIL_TOKEN_LENGTH = 6;
var PHONE_TOKEN_LENGTH = 6;
var AUTHENTICATOR_TOKEN_LENGTH = 6;
var VOUCHER_PIN_LENGTH = 8;

// src/constants/currencySymbols.ts
var currencySymbols = {
  AED: "\u062F.\u0625",
  AFN: "\u060B",
  ALL: "L",
  AMD: "\u058F",
  ANG: "\u0192",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "\u0192",
  AZN: "\u20BC",
  BAM: "KM",
  BBD: "$",
  BDT: "\u09F3",
  BGN: "\u043B\u0432",
  BHD: ".\u062F.\u0628",
  BIF: "FBu",
  BMD: "$",
  BND: "$",
  BOB: "$b",
  BOV: "BOV",
  BRL: "R$",
  BSD: "$",
  BTC: "\u20BF",
  BTN: "Nu.",
  BWP: "P",
  BYN: "Br",
  BYR: "Br",
  BZD: "BZ$",
  CAD: "$",
  CDF: "FC",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "$",
  CNY: "\xA5",
  COP: "$",
  COU: "COU",
  CRC: "\u20A1",
  CUC: "$",
  CUP: "\u20B1",
  CVE: "$",
  CZK: "K\u010D",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "RD$",
  DZD: "\u062F\u062C",
  EEK: "kr",
  EGP: "\xA3",
  ERN: "Nfk",
  ETB: "Br",
  ETH: "\u039E",
  EUR: "\u20AC",
  FJD: "$",
  FKP: "\xA3",
  GBP: "\xA3",
  GEL: "\u20BE",
  GGP: "\xA3",
  GHC: "\u20B5",
  GHS: "GH\u20B5",
  GIP: "\xA3",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "\u20AA",
  IMP: "\xA3",
  INR: "\u20B9",
  IQD: "\u0639.\u062F",
  IRR: "\uFDFC",
  ISK: "kr",
  JEP: "\xA3",
  JMD: "J$",
  JOD: "JD",
  JPY: "\xA5",
  KES: "KSh",
  KGS: "\u043B\u0432",
  KHR: "\u17DB",
  KMF: "CF",
  KPW: "\u20A9",
  KRW: "\u20A9",
  KWD: "KD",
  KYD: "$",
  KZT: "\u20B8",
  LAK: "\u20AD",
  LBP: "\xA3",
  LKR: "\u20A8",
  LRD: "$",
  LSL: "M",
  LTC: "\u0141",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "lei",
  MGA: "Ar",
  MKD: "\u0434\u0435\u043D",
  MMK: "K",
  MNT: "\u20AE",
  MOP: "MOP$",
  MRO: "UM",
  MRU: "UM",
  MUR: "\u20A8",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MXV: "MXV",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "\u20A6",
  NIO: "C$",
  NOK: "kr",
  NPR: "\u20A8",
  NZD: "$",
  OMR: "\uFDFC",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "K",
  PHP: "\u20B1",
  PKR: "\u20A8",
  PLN: "z\u0142",
  PYG: "Gs",
  QAR: "\uFDFC",
  RMB: "\uFFE5",
  RON: "lei",
  RSD: "\u0414\u0438\u043D.",
  RUB: "\u20BD",
  RWF: "R\u20A3",
  SAR: "\uFDFC",
  SBD: "$",
  SCR: "\u20A8",
  SDG: "\u062C.\u0633.",
  SEK: "kr",
  SGD: "S$",
  SHP: "\xA3",
  SLL: "Le",
  SOS: "S",
  SRD: "$",
  SSP: "\xA3",
  STD: "Db",
  STN: "Db",
  SVC: "$",
  SYP: "\xA3",
  SZL: "E",
  THB: "\u0E3F",
  TJS: "SM",
  TMT: "T",
  TND: "\u062F.\u062A",
  TOP: "T$",
  TRL: "\u20A4",
  TRY: "\u20BA",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "\u20B4",
  UGX: "USh",
  USD: "$",
  UYI: "UYI",
  UYU: "$U",
  UYW: "UYW",
  UZS: "\u043B\u0432",
  VEF: "Bs",
  VES: "Bs.S",
  VND: "\u20AB",
  VUV: "VT",
  WST: "WS$",
  XAF: "FCFA",
  XBT: "\u0243",
  XCD: "$",
  XOF: "CFA",
  XPF: "\u20A3",
  XSU: "Sucre",
  XUA: "XUA",
  YER: "\uFDFC",
  ZAR: "R",
  ZMW: "ZK",
  ZWD: "Z$",
  ZWL: "$"
};

// src/constants/networks.ts
var networkNames = {
  BTC: "Bitcoin",
  ETH: "Ethereum",
  ERC20: "Ethreum",
  BSC: "Binance Smart Chain",
  BEP20: "Binance Smart Chain",
  TRC20: "TRON"
};
var networkValidatorMap = {
  BTC: "BTC",
  BSC: "ETH",
  ETH: "ETH",
  ERC20: "ETH",
  BEP20: "ETH",
  TRC20: "TRX"
};

// src/constants/cryptoNames.ts
var cryptoNames = {
  "42": "42 Coin",
  "300": "300 token",
  "365": "365Coin",
  "404": "404Coin",
  "433": "433 Token",
  "611": "SixEleven",
  "808": "808",
  "888": "Octocoin",
  "1337": "EliteCoin",
  "2015": "2015 coin",
  "ARC*": "Arcade City",
  CLUB: "ClubCoin",
  "007": "007 coin",
  ZCN: "0chain",
  ZRX: "0x",
  "0xBTC": "0xBitcoin",
  BIT16: "16BitCoin",
  MCT: "1717 Masonic Commemorative Token",
  "1CR": "1Credit",
  "1WO": "1World",
  CHAO: "23 Skidoo",
  ARMS: "2Acoin",
  "2BACCO": "2BACCO Coin",
  "2GIVE": "2GiveCoin",
  "2TF": "2TF",
  "32BIT": "32Bitcoin",
  "3XD": "3DChain",
  "3DES": "3DES",
  "8BT": "8 Circuit Studios",
  "8BIT": "8BIT Coin",
  ATKN: "A-Token",
  AAA: "AAA Reserve Currency",
  RTB: "AB-CHAIN",
  ABC: "AB-Chain",
  AT: "ABCC Token",
  AC3: "AC3",
  ACA: "ACA Token",
  ACT: "ACT",
  ACOIN: "ACoin",
  AENT: "AEN",
  AEON: "AEON",
  AERGO: "AERGO",
  AGT: "AGATE",
  AIC: "AI Crypto",
  AIDOC: "AI Doctor",
  AIT: "AIChain Token",
  "XAI*": "AICoin",
  AIOT: "AIOT Token",
  AITT: "AITrading",
  AXT: "AIX",
  ALX: "ALAX",
  ALIS: "ALISmedia",
  ALT: "ALTcoin",
  AMBT: "AMBT Token",
  AMIS: "AMIS",
  AMLT: "AMLT",
  AMO: "AMO Coin",
  ANON: "ANON",
  ANTS: "ANTS Reloaded",
  APIS: "APIS",
  APS: "APRES",
  QEY: "AQwire",
  "ARB*": "ARBITRAGE",
  ARE: "ARENON",
  ARK: "ARK",
  ARNA: "ARNA Panacea",
  ARR: "ARROUND",
  ASGC: "ASG",
  ASQT: "ASQ Protocol",
  ATB: "ATB coin",
  ATCC: "ATC Coin",
  ATFS: "ATFS Project",
  ATL: "ATLANT",
  ATM: "ATMChain",
  "AUC*": "AU-Coin",
  AWAX: "AWAX",
  AXR: "AXRON",
  AXS: "AXS",
  ABELE: "Abele",
  ABJ: "Abjcoin",
  ABS: "Absolute Coin",
  "ACC*": "Accelerator Network",
  ACCO: "Accolade",
  AEC: "AcesCoin",
  ACES: "AcesCoin",
  "ACT*": "Achain",
  ACH: "AchieveCoin",
  ACID: "AcidCoin",
  OAK: "Acorn Collective",
  ACM: "Actinium",
  ACTN: "Action Coin",
  AMT: "Acumen",
  AAC: "Acute Angle Cloud",
  ACC: "AdCoin",
  ADX: "AdEx",
  ADT: "AdToken",
  ADAB: "Adab Solutions",
  ADM: "Adamant",
  ADB: "Adbank",
  ADL: "Adelphoi",
  ADH: "Adhive",
  ADI: "Aditus",
  ADST: "Adshares",
  AIB: "AdvancedInternetBlock",
  ADZ: "Adzcoin",
  AGS: "Aegis",
  AEN: "Aenco",
  AERM: "Aerium",
  AERO: "Aero Coin",
  AM: "AeroMe",
  ARN: "Aeron",
  XRM: "Aerum",
  AER: "Aeryus",
  AE: "Aeternity",
  ATT: "Aeternum",
  AET: "AfterEther",
  AGVC: "AgaveCoin",
  ESTATE: "AgentMile",
  AGRS: "Agoras Token",
  DLT: "Agrello Delta",
  AHT: "Ahoolee",
  AIBB: "AiBB",
  AID: "AidCoin",
  ADN: "Aiden",
  ADK: "Aidos Kuneen",
  AIX: "Aigang",
  AIM: "Aimedis",
  AION: "Aion",
  APOD: "AirPod",
  AST: "AirSwap",
  AIR: "AirToken",
  AIRT: "Aircraft",
  AKA: "Akroma",
  ALCE: "Alcedo",
  ALEX: "Alexandrite",
  PLM: "Algo.Land",
  ALG: "Algory",
  ABBC: "Alibabacoin",
  ALN: "AlienCoin",
  SOC: "All Sports Coin",
  AFO: "AllForOneBusiness",
  ASAFE2: "Allsafe",
  APC: "AlpaCoin",
  ALPS: "Alpenschillling",
  ALF: "AlphaCoin",
  AX: "AlphaX",
  ACAT: "Alphacat",
  ALP: "Alphacon",
  APZ: "Alprockz",
  ALQO: "Alqo",
  ALTCOM: "AltCommunity Coin",
  ALTOCAR: "AltoCar",
  ALUX: "Alux Bank",
  AS: "AmaStar",
  AMBER: "AmberCoin",
  AMB: "Ambrosus",
  AMC: "AmericanCoin",
  AMX: "Amero",
  AMMO: "Ammo Rewards",
  AMN: "Amon",
  AMOS: "Amos",
  VEO: "Amoveo",
  AMS: "Amsterdam Coin",
  AMY: "Amygws",
  ANCP: "Anacrypt",
  ANAL: "AnalCoin",
  ACP: "Anarchists Prime",
  AND: "AndromedaCoin",
  ANGL: "Angel Token",
  AVH: "Animation Vision Cash",
  ANI: "Animecoin",
  ANK: "Ankorus Token",
  ANKR: "Ankr Network",
  ANC: "Anoncoin",
  RYZ: "Anryze",
  ANTI: "Anti Bitcoin",
  ATHK: "AntiHACK.me",
  ANTC: "AntiLitecoin",
  CPX: "Apex Token",
  APEX: "ApexCoin",
  APH: "Aphelion",
  APL: "Apollo Currency",
  APXT: "ApolloX",
  XAP: "Apollon",
  APPC: "AppCoins",
  APT: "Aptcoin",
  APX: "Apx",
  AQUA: "Aquachain",
  ARCO: "AquariusCoin",
  "AR*": "Ar.cash",
  ARQ: "ArQmA",
  ALC: "Arab League Coin",
  ANT: "Aragon",
  ARAW: "Araw",
  ARBI: "Arbi",
  ARB: "Arbit Coin",
  ARCT: "ArbitrageCT",
  ABT: "ArcBlock",
  ARCX: "ArcadierX",
  ARCH: "ArchCoin",
  ARC: "ArcticCoin",
  ARDR: "Ardor",
  ARENA: "Arena",
  ARG: "Argentum",
  AGM: "Argoneum",
  ARGUS: "ArgusCoin",
  ARI: "AriCoin",
  ARO: "Arionum",
  BOTS: "ArkDAO",
  ARM: "Armory Coin",
  ARPA: "ArpaCoin",
  ABY: "ArtByte",
  ATP: "ArtPro",
  ARS: "Artcoin",
  ARTE: "Artemine",
  ATX: "ArtexCoin",
  AUA: "ArubaCoin",
  ASN: "Ascension Coin",
  XAS: "Asch",
  AC: "Asia Coin",
  ADCN: "Asiadigicoin",
  "ATX*": "Aston",
  "AST*": "Astral",
  ASTRO: "Astronaut",
  ATH: "Atheios",
  THO: "Athero",
  ATMOS: "Atmos",
  ATOM: "Atomic Coin",
  AWC: "Atomic Wallet Coin",
  ATMI: "Atonomi",
  ATTR: "Attrace",
  AUC: "Auctus",
  ADC: "AudioCoin",
  REP: "Augur",
  AUK: "Aukcecoin",
  AURS: "Aureus",
  AURA: "Aurora",
  AOA: "Aurora",
  AUR: "Aurora Coin",
  AURUM: "Aurum",
  AUD: "Aussie Digital",
  AUN: "Authoreon",
  ATS: "Authorship",
  AUPC: "Authpaper",
  ABX: "AutoBay",
  ATC: "AutoBlock",
  "NIO*": "Autonio",
  AUT: "Autoria",
  "ATM*": "Autumncoin",
  Auxilium: "Auxilium",
  AVA: "Avalon",
  AV: "Avatar Coin",
  AVT: "AventCoin",
  AOP: "Averopay",
  AVE: "Avesta",
  ACN: "AvonCoin",
  WORK: "Aworker",
  AXIOM: "Axiom Coin",
  AXYS: "Axys",
  AZART: "Azart",
  AZ: "Azbit",
  AZU: "Azultec",
  B21: "B21",
  B2B: "B2BX",
  B3: "B3 Coin",
  KB3: "B3Coin",
  BAX: "BABB",
  BAM: "BAM",
  BANCA: "BANCA",
  BKX: "BANKEX",
  BBN: "BBNCOIN",
  BCV: "BCV Blue Chip",
  BEAT: "BEAT Token",
  BEER: "BEER Coin",
  BERN: "BERNcash",
  BEX: "BEX token",
  BFT: "BF Token",
  BFEX: "BFEX",
  BHPC: "BHPCash",
  BITTO: "BITTO",
  VEE: "BLOCKv",
  BMT: "BMChain",
  "BSC*": "BOONSCoin",
  "BST*": "BOOSTO",
  BOS: "BOScoin",
  BQC: "BQCoin",
  BQTX: "BQT",
  BVO: "BRAVO Pay",
  BRAT: "BROTHER",
  BTCL: "BTC Lite",
  BTCM: "BTCMoon",
  BTU: "BTU Protocol",
  BAAS: "BaaSid",
  BAN: "Babes and Nerds",
  BKC: "Balkancoin",
  NANAS: "BananaBits",
  BNT: "Bancor Network Token",
  XBANK: "Bancryp",
  "B@": "BankCoin",
  BNK: "Bankera",
  BCOIN: "BannerCoin",
  "BBN*": "Banyan Network",
  BBCC: "BaseballCardCoin",
  BASHC: "BashCoin",
  BAT: "Basic Attention Token",
  BASIS: "Basis",
  BTA: "Bata",
  BCX: "BattleCoin",
  BSTK: "BattleStake",
  SAND: "BeachCoin",
  BEAM: "Beam",
  BEAN: "BeanCash",
  BRDD: "BeardDollars",
  XBTS: "Beats",
  BTZC: "BeatzCoin",
  BEC: "Beauty Chain",
  BVC: "BeaverCoin",
  BXY: "Beaxy",
  BEE: "Bee Token",
  BFDT: "Befund",
  BELA: "Bela",
  BBI: "BelugaPay",
  BMK: "Benchmark",
  BNC: "Benjacoin",
  BENJI: "BenjiRolls",
  BEST: "BestChain",
  KNG: "BetKings",
  CHART: "BetOnChart",
  BET: "BetaCoin",
  BFC: "Betform",
  BETHER: "Bethereum",
  KRO: "Betoken",
  BTRM: "Betrium Token",
  BETR: "BetterBetting",
  BTXC: "Bettex coin",
  BETT: "Bettium",
  BC: "Beverage.cash",
  BZNT: "Bezant",
  BEZ: "Bezop",
  BGG: "Bgogo Token",
  BNR: "BiNeuro",
  BBP: "BiblePay",
  BIX: "BiboxCoin",
  BID: "BidCoin",
  BDP: "Bidipass",
  DOOH: "Bidooh",
  BDB: "Big Data Block",
  BBG: "BigBang",
  HUGE: "BigCoin",
  LFC: "BigLifeCoin",
  BIGUP: "BigUp",
  BBO: "Bigbom",
  BHC: "BighanCoin",
  BIC: "Bikercoins",
  BLRY: "BillaryCoin",
  XBL: "Billionaire Token",
  BNB: "Binance Coin",
  "BRC*": "BinaryCoin",
  BIOB: "BioBar",
  BIOC: "BioCrypt",
  BIO: "Biocoin",
  BIOS: "BiosCrypto",
  BTRN: "Biotron",
  BIPC: "BipCoin",
  BIS: "Bismuth",
  BZ: "Bit-Z",
  BAS: "BitAsean",
  BTB: "BitBar",
  BAY: "BitBay",
  BBK: "BitBlocks",
  BBT: "BitBoost",
  BOSS: "BitBoss",
  BRONZ: "BitBronze",
  "BCD*": "BitCAD",
  BEN: "BitCOEN",
  BCNA: "BitCanna",
  BITCAR: "BitCar",
  "CAT*": "BitClave",
  COAL: "BitCoal",
  BCCOIN: "BitConnect Coin",
  BCR: "BitCredit",
  BTCRY: "BitCrystal",
  BCY: "BitCrystals",
  BTCR: "BitCurrency",
  BDG: "BitDegree",
  CSNO: "BitDice",
  HNY: "BitFence",
  BFX: "BitFinex Tokens",
  FLIP: "BitFlip",
  "FLX*": "BitFlux",
  BF: "BitForex Token",
  "HIRE*": "BitHIRE",
  BIH: "BitHostCoin",
  STU: "BitJob",
  BTLC: "BitLuckCoin",
  "LUX*": "BitLux",
  BTM: "BitMark",
  BMX: "BitMart Coin",
  BTMX: "BitMax Token",
  BTMI: "BitMiles",
  BITM: "BitMoney",
  BM: "BitMoon",
  BITOK: "BitOKX",
  BTQ: "BitQuark",
  RNTB: "BitRent",
  BIT: "BitRewards",
  BSCH: "BitSchool",
  BITX: "BitScreener",
  XSEED: "BitSeeds",
  BSD: "BitSend",
  "BTE*": "BitSerial",
  BSR: "BitSoar Coin",
  BTSG: "BitSong",
  STASH: "BitStash",
  BSTN: "BitStation",
  BST: "BitStone",
  SWIFT: "BitSwift",
  BXT: "BitTokens",
  BTT: "BitTorrent",
  TUBE: "BitTube",
  VEG: "BitVegan",
  VOLT: "BitVolt",
  BTW: "BitWhite",
  ZNY: "BitZeny",
  XBOND: "Bitacium",
  BTCA: "Bitair",
  BAC: "BitalphaCoin",
  BB1: "Bitbond",
  BOSE: "Bitbose",
  BTMG: "Bitcademy Football",
  BTD: "Bitcloud",
  BTDX: "Bitcloud 2.0",
  BTCN: "BitcoiNote",
  B2G: "Bitcoiin2Gen",
  BTC: "Bitcoin",
  BCA: "Bitcoin Atom",
  CDY: "Bitcoin Candy",
  BCH: "Bitcoin Cash",
  BTCC: "Bitcoin Core",
  BCD: "Bitcoin Diamond",
  BTG: "Bitcoin Gold",
  BITG: "Bitcoin Green",
  BTCH: "Bitcoin Hush",
  XBI: "Bitcoin Incognito",
  BCI: "Bitcoin Interest",
  BTN: "Bitcoin Nova",
  BTPL: "Bitcoin Planet",
  BTCP: "Bitcoin Private",
  BTCRED: "Bitcoin Red",
  "BCR*": "Bitcoin Royal",
  BSV: "Bitcoin SV",
  BTCS: "Bitcoin Scrypt",
  BT2: "Bitcoin SegWit2X",
  "BTCS*": "Bitcoin Supreme",
  BTCD: "BitcoinDark",
  "BTCE*": "BitcoinEX",
  BCF: "BitcoinFast",
  BIFI: "BitcoinFile",
  "BTF*": "BitcoinFor",
  BTCGO: "BitcoinGo",
  XBC: "BitcoinPlus",
  BWS: "BitcoinWSpectrum",
  "BCX*": "BitcoinX",
  BTCZ: "BitcoinZ",
  BTCUS: "Bitcoinus",
  "BM*": "Bitcomo",
  BTX: "Bitcore",
  DARX: "Bitdaric",
  BDL: "Bitdeal",
  XBX: "BiteX",
  BT1: "Bitfinex Bitcoin Future",
  BTR: "Bither",
  BTH: "Bithereum",
  KAN: "Bitkan",
  "BTCL*": "BitluckCoin",
  BIM: "BitminerCoin",
  BMXT: "Bitmxittz",
  XPAT: "Bitnation Pangea",
  BQ: "Bitqy",
  BRO: "Bitradio",
  BTL: "Bitrolium",
  BITSD: "Bits Digit",
  BINS: "Bitsense",
  BTS: "Bitshares",
  BSX: "Bitspace",
  XBS: "Bitstake",
  BITS: "BitstarCoin",
  "BITS*": "Bitswift",
  BWT: "Bittwatt",
  BTV: "Bitvote",
  BWT2: "Bitwin 2.0",
  BITZ: "Bitz Coin",
  BTZ: "BitzCoin",
  BTZN: "Bitzon",
  BXM: "Bixtrim",
  BZKY: "Bizkey",
  XBP: "Black Pearl Coin",
  BLK: "BlackCoin",
  BS: "BlackShadowCoin",
  BBOS: "Blackbox Foundation",
  "BHC*": "BlackholeCoin",
  BMC: "Blackmoon Crypto",
  BSTAR: "Blackstar",
  BBTC: "BlakeBitcoin",
  BLC: "BlakeCoin",
  BLAS: "BlakeStar",
  BLAZR: "BlazerCoin",
  BLKD: "Blinked",
  BLITZ: "BlitzCoin",
  "XBP*": "BlitzPredict",
  ARY: "Block Array",
  BLTG: "Block-Logic",
  BCAT: "BlockCAT",
  BCDN: "BlockCDN",
  LNC: "BlockLancer",
  BCPT: "BlockMason Credit Protocol",
  BMH: "BlockMesh",
  BLOCK: "BlockNet",
  BLOCKPAY: "BlockPay",
  BPL: "BlockPool",
  BCAP: "Blockchain Capital",
  BCDT: "Blockchain Certified Data Token",
  BLX: "Blockchain Index",
  BCT: "Blockchain Terminal",
  BTF: "Blockchain Traded Fund",
  BCIO: "Blockchain.io",
  BDT: "Blockonix",
  PASS: "Blockpass",
  BPT: "Blockport",
  "CCC*": "Blockshipping",
  TIX: "Blocktix",
  BKT: "Blocktrade token",
  BLV: "Blockvest",
  BNTN: "Blocnation",
  CYS: "BlooCYS",
  BLT: "Bloom Token",
  CDT: "Blox",
  BLU: "BlueCoin",
  BDR: "BlueDragon",
  BLZ: "Bluzelle",
  BNX: "BnrtxCoin",
  BOB: "Bob's Repair",
  BOT: "Bodhi",
  BOE: "Bodhi",
  BOG: "Bogcoin",
  BOLD: "Bold",
  "BLN*": "Bolenum",
  BOLI: "BolivarCoin",
  BOLTT: "BolttCoin",
  BOMB: "BombCoin",
  BONA: "Bonafi",
  "BON*": "BonesCoin",
  BON: "Bonpay",
  BBR: "Boolberry",
  BOST: "BoostCoin",
  BMG: "Borneo",
  BOSON: "BosonCoin",
  "BOS*": "Bostoken",
  BOTC: "BotChain",
  CAP: "BottleCaps",
  BTO: "Bottos",
  BOU: "Boulle",
  BNTE: "Bountie",
  XBTY: "Bounty",
  BNTY: "Bounty0x",
  BOUTS: "BoutsPro",
  "AHT*": "Bowhead Health",
  BSC: "BowsCoin",
  BOXY: "BoxyCoin",
  IMPCN: "Brain Space",
  BRAIN: "BrainCoin",
  BRAZ: "Brazio",
  BRD: "Bread token",
  BRX: "Breakout Stake",
  BRK: "BreakoutCoin",
  BRIA: "Briacoin",
  XBB: "BrickBlock",
  BCO: "BridgeCoin",
  BRC: "BrightCoin",
  BRIK: "BrikBit",
  BRIT: "BritCoin",
  BRNX: "Bronix",
  BXC: "BtcEX",
  BUBO: "Budbo",
  BUD: "Buddy",
  BGL: "Buglab",
  BT: "BuildTeam",
  BULLS: "BullshitCoin",
  BWK: "Bulwark",
  BUN: "BunnyCoin",
  BURST: "BurstCoin",
  BUZZ: "BuzzCoin",
  BYC: "ByteCent",
  BTE: "ByteCoin",
  BCN: "ByteCoin",
  GBYTE: "Byteball",
  BYTHER: "Bytether",
  "BTM*": "Bytom",
  XCT: "C-Bits",
  C25: "C25 Coin",
  CAIx: "CAIx",
  CBD: "CBD Crystals",
  CCC: "CCCoin",
  CDRX: "CDRX",
  CDX: "CDX Network",
  CEDEX: "CEDEX Coin",
  CEEK: "CEEK Smart VR Token",
  CETI: "CETUS Coin",
  CHIPS: "CHIPS",
  CIC: "CIChain",
  CINX: "CINDX",
  CINNI: "CINNICOIN",
  CLAM: "CLAMS",
  CO2: "CO2 Token",
  CMS: "COMSA",
  CNCT: "CONNECT",
  CPY: "COPYTRACK",
  COSS: "COSS",
  COTI: "COTI",
  "CPC*": "CPChain",
  MLS: "CPROP",
  CPROP: "CPROP",
  CRWD: "CRWD Network",
  CMZ: "CRYPTOMAGZ",
  "CRS*": "CRYSTALS",
  CYBR: "CYBR",
  CAB: "CabbageUnit",
  CHE: "Cache",
  CACH: "Cachecoin",
  CF: "Californium",
  CALC: "CaliphCoin",
  CLO: "Callisto Network",
  CAM: "Camcoin",
  CMPCO: "CampusCoin",
  CAN: "CanYaCoin",
  "CND*": "Canada eCoin",
  CDN: "Canada eCoin",
  CAND: "Canlead",
  CCN: "CannaCoin",
  XCI: "Cannabis Industry Coin",
  CANN: "CannabisCoin",
  CNAB: "Cannabium",
  XCD: "Capdax",
  CAPP: "Cappasity",
  CPC: "CapriCoin",
  CAR: "CarBlock",
  CTX: "CarTaxi",
  CV: "CarVertical",
  CARAT: "Carats Token",
  CUSD: "Carbon",
  CARBON: "Carboncoin",
  ADA: "Cardano",
  CARD: "Cardstack",
  "CARE*": "Care Token",
  CARE: "Carebit",
  CRGO: "CargoCoin",
  TICS: "CargoConX",
  CXO: "CargoX",
  DIEM: "CarpeDiemCoin",
  CTC: "CarterCoin",
  CNBC: "Cash & Back Coin",
  "CASH*": "Cash Poker Pro",
  "CBC*": "CashBagCoin",
  CBC: "CashBet Coin",
  CASH: "CashCoin",
  CSH: "CashOut",
  CAS: "Cashaa",
  CSC: "CasinoCoin",
  CST: "Casper API",
  CSP: "Caspian",
  CSTL: "Castle",
  CAT: "Catcoin",
  CATT: "Catex",
  CVTC: "CavatCoin",
  CAV: "Caviar",
  CCO: "Ccore",
  CEL: "Celsius Network",
  CTR: "Centra",
  CENNZ: "Centrality Token",
  CNT: "Centurion",
  CBS: "Cerberus",
  XCE: "Cerium",
  CHC: "ChainCoin",
  LINK: "ChainLink",
  ZILLA: "ChainZilla",
  CHX: "Chainium",
  CHAN: "ChanCoin",
  CAG: "Change",
  CHA: "Charity Coin",
  CHARM: "Charm Coin",
  CXC: "CheckCoin",
  CHK: "Chek",
  CHESS: "ChessCoin",
  CHILD: "ChildCoin",
  CNC: "ChinaCoin",
  CHIP: "Chip",
  CHOOF: "ChoofCoin",
  DAY: "Chronologic",
  CRX: "ChronosCoin",
  CHW: "Chrysalis Coin",
  CLPX: "Chynge.net",
  CIN: "CinderCoin",
  CND: "Cindicator",
  CIR: "CircuitCoin",
  COVAL: "Circuits of Value",
  CTL: "Citadel",
  CTW: "Citowise",
  CVC: "Civic",
  CLRTY: "Clarity",
  CAID: "ClearAid",
  XCLR: "ClearCoin",
  POLL: "ClearPoll",
  CLV: "CleverCoin",
  CHASH: "CleverHash",
  CLICK: "Clickcoin",
  CLIN: "Clinicoin",
  CLINT: "Clinton",
  CCCX: "Clipper Coin Capital",
  CLOAK: "CloakCoin",
  CKC: "Clockcoin",
  CLD: "Cloud",
  CLOUT: "Clout",
  CLDX: "Cloverdex",
  CLUD: "CludCoin",
  COE: "CoEval",
  COT: "CoTrader",
  COVEX: "CoVEX",
  COB: "Cobinhood",
  COX: "CobraCoin",
  CTT: "CodeTract",
  CFC: "CoffeeCoin",
  CFI: "Cofound.it",
  COGEN: "Cogenero",
  COGS: "Cogmento",
  COG: "Cognitio",
  "COIN*": "Coin",
  COY: "Coin Analyst",
  XMG: "Coin Magi",
  BTTF: "Coin to the Future",
  C2: "Coin.2",
  CONI: "CoinBene",
  CET: "CoinEx token",
  COFI: "CoinFi",
  IMP: "CoinIMP",
  XCJ: "CoinJob",
  CL: "CoinLancer",
  LION: "CoinLion",
  MEE: "CoinMeet",
  MEET: "CoinMeet",
  XCM: "CoinMetro",
  CPL: "CoinPlace Token",
  CHP: "CoinPoker Token",
  CPEX: "CoinPulseToken",
  CCH: "Coinchase",
  DAILY: "Coindaily",
  CGT: "Coingrid",
  CTIC: "Coinmatic",
  COI: "Coinnec",
  CNO: "Coino",
  CNMT: "Coinomat",
  CXT: "Coinonat",
  XCXT: "CoinonatX",
  TCJ: "Coinshare",
  CEN: "Coinsuper Ecosystem Network",
  COIN: "Coinvest",
  CWT: "Coinware",
  CXG: "Coinxes",
  COLX: "ColossusCoinXT",
  CLN: "Colu Local Network",
  CBP: "ComBox",
  CMTC: "CometCoin",
  CBT: "CommerceBlock Token",
  CMM: "Commercium",
  COMM: "Community Coin",
  COC: "Community Coin",
  CMP: "Compcoin",
  COMP: "Compound Coin",
  CPN: "CompuCoin",
  CYC: "ConSpiracy Coin",
  CNL: "ConcealCoin",
  RAIN: "Condensate",
  CFD: "Confido",
  CJR: "Conjure",
  CJT: "ConnectJob Token",
  CTY: "Connecty",
  CQST: "ConquestCoin",
  "SEN*": "Consensus",
  DAG: "Constellation",
  CNN: "Content Neutrality Network",
  "CAN*": "Content and AD Network",
  BOX: "ContentBox",
  COS: "Contentos",
  CUZ: "Cool Cousin",
  COOL: "CoolCoin",
  CCX: "CoolDarkCoin",
  XCPO: "Copico",
  CLR: "CopperLark",
  CORAL: "CoralPay",
  CORE: "Core Group Asset",
  COR: "Corion",
  CTXC: "Cortex",
  COSX: "Cosmecoin",
  CSMIC: "Cosmic",
  CMOS: "Cosmo",
  COSM: "CosmoChain",
  "ATOM*": "Cosmos",
  CMC: "CosmosCoin",
  COU: "Couchain",
  XCP: "CounterParty",
  CHT: "Countinghouse Fund",
  "COV*": "CovenCoin",
  COV: "Covesting",
  CPLO: "Cpollo",
  CRAB: "CrabCoin",
  CRACK: "CrackCoin",
  CRAFT: "Craftcoin",
  CFTY: "Crafty",
  CRAIG: "CraigsCoin",
  CRNK: "CrankCoin",
  "CRAVE*": "Crave-NG",
  CRAVE: "CraveCoin",
  CRV: "Cravy",
  CZC: "Crazy Coin",
  CRM: "Cream",
  XCRE: "Creatio",
  CREA: "CreativeChain",
  CRDNC: "Credence Coin",
  PESA: "Credible",
  CREDIT: "Credit",
  CRB: "Creditbit",
  "CRE*": "Creditcoin",
  CCOIN: "Creditcoin",
  CDPT: "Creditor Data Platform",
  CRE: "Credits",
  CRDS: "Credits",
  "CS*": "Credits",
  "CFT*": "Credo",
  CREDO: "Credo",
  CREVA: "Creva Coin",
  CROAT: "Croat",
  CMCT: "Crowd Machine",
  "CRC***": "CrowdCoin",
  CCOS: "CrowdCoinage",
  CRF: "CrowdForce",
  CDP: "CrowdPrecision",
  YUP: "Crowdholding",
  WIZ: "Crowdwiz",
  CRW: "Crown Coin",
  "CRC**": "CryCash",
  IPT: "Crypt-ON",
  CRYPT: "CryptCoin",
  CPT: "Cryptaur",
  CRL: "Cryptelo Coin",
  CRPT: "Crypterium",
  XCR: "Crypti",
  CRYP: "CrypticCoin",
  QRP: "Cryptics",
  CTO: "Crypto",
  ANGEL: "Crypto Angel",
  CESC: "Crypto Escudo",
  CIF: "Crypto Improvement Fund",
  CSPN: "Crypto Sports",
  TKT: "Crypto Tickets",
  CWEX: "Crypto Wine Exchange",
  CWIS: "Crypto Wisdom Coin",
  CWX: "Crypto-X",
  MCO: "Crypto.com",
  CRO: "Crypto.com Chain Token",
  C20: "Crypto20",
  CABS: "CryptoABS",
  BUK: "CryptoBuk",
  CBX: "CryptoBullion",
  CCRB: "CryptoCarbon",
  CIRC: "CryptoCircuits",
  FCS: "CryptoFocus",
  CFT: "CryptoForecast",
  CHBR: "CryptoHub",
  TKR: "CryptoInsight",
  CJ: "CryptoJacks",
  CJC: "CryptoJournal",
  LEU: "CryptoLEU",
  CPAY: "CryptoPay",
  CRPS: "CryptoPennies",
  PING: "CryptoPing",
  CP: "CryptoProfile",
  CREV: "CryptoRevolution",
  CR: "CryptoRiyal",
  CS: "CryptoSpots",
  CWV: "CryptoWave",
  CWXT: "CryptoWorldXToken",
  CRON: "Cryptocean",
  CCIN: "Cryptocoin Insurance",
  "CDX*": "Cryptodex",
  CGA: "Cryptographic Anomaly",
  CIX100: "Cryptoindex",
  CYT: "Cryptokenz",
  CIX: "Cryptonetix",
  CNX: "Cryptonex",
  XCN: "Cryptonite",
  CEFS: "CryptopiaFeeShares",
  CRS: "Cryptoreal",
  CXA: "CryptovationX",
  OXY2: "Cryptoxygen",
  MN: "Cryptsy Mining Contract",
  POINTS: "Cryptsy Points",
  CRTM: "Cryptum",
  CVCOIN: "Crypviser",
  CCT: "Crystal Clear Token",
  AUTO: "Cube",
  QBT: "Cubits",
  CUR: "Cura Network",
  CTKN: "Curaizon",
  CURE: "Curecoin",
  CRU: "Curium",
  CBUK: "CurveBlock",
  CHFT: "CustomCoin",
  CCL: "CyClean",
  XCS: "CybCSec Coin",
  CCI: "Cyber Capital Invest",
  CC: "CyberCoin",
  CMT: "CyberMiles",
  "CABS*": "CyberTrust",
  CVT: "CyberVein",
  "CRE**": "Cybereits Token",
  CYDER: "Cyder Coin",
  CYG: "Cygnus",
  CYP: "CypherPunkCoin",
  FUNK: "Cypherfunks Coin",
  CYRS: "Cyrus Token",
  DACH: "DACH Coin",
  DAC: "DACash",
  DADI: "DADI",
  DAX: "DAEX",
  "BET*": "DAO.casino",
  "GEN*": "DAOstack",
  DAPS: "DAPS Token",
  DAS: "DAS",
  DATX: "DATx",
  "DAV*": "DAV",
  DRP: "DCORP",
  DEEX: "DEEX",
  "CET*": "DICE Money",
  DIM: "DIMCOIN",
  DIW: "DIWtoken",
  DMT: "DMarket",
  DNTX: "DNAtix",
  DNN: "DNN Token",
  MTC: "DOCADEMIC",
  DOV: "DOVU",
  "DREAM*": "DREAM",
  DRPU: "DRP Utility",
  DRACO: "DT Token",
  DYNO: "DYNO",
  DAI: "Dai",
  DAN: "Daneel",
  "DXC*": "Daox",
  DAR: "Darcrus",
  PROD: "Darenta",
  DEC: "Darico",
  DARK: "Dark",
  DISK: "Dark Lisk",
  MOOND: "Dark Moon",
  DB: "DarkBit",
  DRKC: "DarkCash",
  DCC: "DarkCrave",
  DETH: "DarkEther",
  DGDC: "DarkGold",
  DKC: "DarkKnightCoin",
  DANK: "DarkKush",
  DSB: "DarkShibe",
  DT: "DarkToken",
  DRKT: "DarkTron",
  DNET: "Darknet",
  DASC: "DasCoin",
  DASH: "Dash",
  DSH: "Dashcoin",
  DTA: "Data",
  "DTT*": "Data Trading",
  DTX: "DataBroker DAO",
  DXT: "DataWallet",
  DTB: "Databits",
  DBCCOIN: "Datablockchain",
  "DTC*": "Datacoin",
  XDT: "Dataeum",
  DTN: "Datareum",
  DTRC: "Datarius",
  DAT: "Datum",
  DAV: "DavorCoin",
  DAXX: "DaxxCoin",
  DTC: "DayTrader Coin",
  DAYTA: "Dayta",
  DHT: "DeHedge Token",
  "DNET*": "DeNet",
  XNA: "DeOxyRibose",
  "DBC*": "Debit Coin",
  DBTC: "DebitCoin",
  DEB: "Debitum Token",
  DCT: "Decent",
  DBET: "Decent.bet",
  MANA: "Decentraland",
  DACC: "Decentralized Accessible Content Chain",
  DML: "Decentralized Machine Learning",
  DUBI: "Decentralized Universal Basic Income",
  HST: "Decision Token",
  DCR: "Decred",
  DEEP: "Deep Gold",
  DBC: "DeepBrain Chain",
  ONION: "DeepOnion",
  DEA: "Degas Coin",
  DEI: "Deimos",
  DKD: "Dekado",
  DEL: "DelChain",
  DPAY: "DelightPay",
  DPT: "Deliverers Power Token",
  DPY: "Delphy",
  DLXV: "Delta-X",
  DCRE: "DeltaCredits",
  DNR: "Denarius",
  DNO: "Denaro",
  DENT: "Dent",
  DCN: "Dentacoin",
  DFBT: "DentalFix",
  DEPO: "Depository Network",
  DERO: "Dero",
  DESI: "Desico",
  DSR: "Desire",
  DES: "Destiny",
  DTCT: "DetectorToken",
  DTH: "Dether",
  DVC: "DevCoin",
  EVE: "Devery",
  DEV: "Deviant Coin",
  DXG: "DexAge",
  DMD: "Diamond",
  DCK: "DickCoin",
  DIGS: "Diggits",
  DGB: "DigiByte",
  DGC: "DigiCoin",
  CUBE: "DigiCube",
  DEUR: "DigiEuro",
  DIGIF: "DigiFel",
  DFXT: "DigiFinexToken",
  DGM: "DigiMoney",
  DGPT: "DigiPulse",
  DGMS: "Digigems",
  DAGT: "Digital Asset Guarantee Token",
  DPP: "Digital Assets Power Play",
  DBG: "Digital Bullion Gold",
  DDF: "Digital Developers Fund",
  DFS: "Digital Fantasy Sports",
  TMTG: "Digital Gold Exchange",
  DRS: "Digital Rupees",
  XDN: "DigitalNote",
  DP: "DigitalPrice",
  DGTX: "Digitex Futures",
  WAGE: "Digiwage",
  DGD: "Digix DAO",
  DGX: "Digix Gold token",
  DIG: "Dignity",
  DIME: "DimeCoin",
  DCY: "Dinastycoin",
  DIN: "Dinero",
  XDQ: "Dirac Coin",
  DIS: "DiscoveryIoT",
  "DCC*": "Distributed Credit Chain",
  DIT: "Ditcoin",
  DIVX: "Divi Exchange Token",
  DIVI: "Divi Project",
  "DTC**": "DivotyCoin",
  DXC: "DixiCoin",
  DLISK: "Dlisk",
  NOTE: "Dnotes",
  DOC: "Doc Coin",
  NRN: "Doc.ai Neuron",
  DOCK: "Dock.io",
  DOGED: "DogeCoinDark",
  DGORE: "DogeGoreCoin",
  XDP: "DogeParty",
  DOGE: "Dogecoin",
  DLA: "Dolla",
  DT1: "Dollar Token 1",
  DLC: "DollarCoin",
  DLR: "DollarOnline",
  DRT: "DomRaider",
  DON: "DonationCoin",
  DDL: "Donocle",
  DOPE: "DopeCoin",
  DOR: "Dorado",
  DOT: "Dotcoin",
  BOAT: "Doubloon",
  Dow: "DowCoin",
  DRA: "DraculaCoin",
  DFT: "Draftcoin",
  DRG: "Dragon Coin",
  XDB: "DragonSphere",
  DRGN: "Dragonchain",
  DRM8: "Dream8Coin",
  DREAM: "DreamTeam Token",
  DRF: "Drife",
  DRZ: "Droidz",
  DRC: "Dropcoin",
  DROP: "Dropil",
  DRXNE: "Droxne",
  DUB: "DubCoin",
  DBIC: "DubaiCoin",
  DBIX: "DubaiCoin",
  DUCK: "DuckDuckCoin",
  DUSK: "Dusk Network",
  DUTCH: "Dutch Coin",
  DUX: "DuxCoin",
  DYC: "Dycoin",
  DYN: "Dynamic",
  DTR: "Dynamic Trading Rights",
  DYNCOIN: "Dyncoin",
  DTEM: "Dystem",
  DBR: "D\xFCber",
  "ECC*": "E-CurrencyCoin",
  EDR: "E-Dinar Coin",
  EFL: "E-Gulden",
  EB3: "EB3coin",
  EBC: "EBCoin",
  ECC: "ECC",
  OMI: "ECOMI",
  ECO: "ECOcoin",
  EDRC: "EDRCoin",
  EGO: "EGOcoin",
  EJAC: "EJA Coin",
  ELTCOIN: "ELTCOIN",
  EMANATE: "EMANATE",
  EMX: "EMX",
  ET: "ENDO",
  ENTRC: "ENTER COIN",
  ENTRY: "ENTRY",
  "EOT*": "EON",
  EOS: "EOS",
  EPIK: "EPIK Token",
  EQL: "EQUAL",
  EQ: "EQUI",
  EQUI: "EQUI Token",
  ERB: "ERBCoin",
  EST: "ESports Chain",
  XBASE: "ETERBASE",
  ETS: "ETH Share",
  ERA: "ETHA",
  ETHO: "ETHER-1",
  EGAS: "ETHGAS",
  EUNO: "EUNO",
  EVOS: "EVOS",
  EXMR: "EXMR",
  EXRN: "EXRNchain",
  ETE: "EXTRADECOIN",
  EYE: "EYE Token",
  EZX: "EZ Exchange",
  EZC: "EZCoin",
  EZM: "EZMarket",
  EZT: "EZToken",
  EA: "EagleCoin",
  EAGS: "EagsCoin",
  EARTH: "Earth Token",
  EAC: "EarthCoin",
  EGDC: "EasyGuide",
  EMT: "EasyMine",
  ETKN: "EasyToken",
  EBZ: "Ebitz",
  EBS: "EbolaShare",
  EKO: "EchoLink",
  EC: "Eclipse",
  ECOB: "EcoBit",
  ECR: "EcoVerse",
  EDDIE: "Eddie coin",
  EDN: "EdenChain",
  EDGE: "EdgeCoin",
  EDG: "Edgeless",
  EDU: "EduCoin",
  LEDU: "Education Ecosystem",
  EDC: "EducoinV",
  EGG: "EggCoin",
  EGT: "Egretia",
  EDO: "Eidoo",
  EMC2: "Einsteinium",
  ELC: "Elacoin",
  XEL: "Elastic",
  ELA: "Elastos",
  ECA: "Electra",
  ELEC: "Electrify.Asia",
  ELT: "Electron",
  ETN: "Electroneum",
  EKN: "Elektron",
  ELE: "Elementrem",
  ELM: "Elements",
  ELES: "Elements Estates",
  "ELI*": "Elicoin",
  ELI: "Eligma",
  ELIX: "Elixir",
  ELLA: "Ellaism",
  ELP: "Ellerium",
  ELLI: "ElliotCoin",
  ELY: "Elysian",
  ELS: "Elysium",
  "AEC*": "EmaratCoin",
  EMB: "EmberCoin",
  MBRS: "Embers",
  EMD: "Emerald",
  EMC: "Emercoin",
  EMN: "Eminent Token",
  EMIGR: "EmiratesGoldCoin",
  "EPY*": "Emphy",
  PLEO: "Empleos",
  EMPC: "EmporiumCoin",
  EPY: "Empyrean",
  ENCX: "Encrybit",
  DNA: "EncrypGen",
  ETT: "EncryptoTel",
  ENCN: "EndChain",
  "EDR*": "Endor Protocol Token",
  ENE: "EneCoin",
  ENQ: "Enecuum",
  NRG: "Energi",
  ETK: "Energi Token",
  TSL: "Energo",
  ENRG: "EnergyCoin",
  ENGT: "Engagement Token",
  EGCC: "Engine",
  XNG: "Enigma",
  ENG: "Enigma",
  ENJ: "Enjin Coin",
  ENK: "Enkidu",
  ENTER: "EnterCoin (ENTER)",
  ENTRP: "Entropy Token",
  ENU: "Enumivo",
  ENV: "Envienta",
  EVN: "Envion",
  NVOY: "Envoy",
  ZYM: "Enzym",
  EQUAL: "EqualCoin",
  EQT: "EquiTrader",
  EQB: "Equibit",
  EQM: "Equilibrium Coin",
  EQY: "Eqwity",
  ERE: "Erecoin",
  EFYT: "Ergo",
  "ERT*": "Eristica",
  ERO: "Eroscoin",
  ERR: "ErrorCoin",
  ERY: "Eryllium",
  ESP: "Espers",
  ERT: "Esports.com",
  ESS: "Essentia",
  ETALON: "Etalon",
  XEC: "Eternal Coin",
  XET: "Eternal Token",
  "ETT*": "Eternal Trusts",
  ENT: "Eternity",
  EBET: "EthBet",
  ETBS: "EthBits",
  LEND: "EthLend",
  HEAL: "Etheal",
  ETHB: "EtherBTC",
  EDT: "EtherDelta",
  DOGETH: "EtherDoge",
  ETI: "EtherInc",
  ETL: "EtherLite",
  ESZ: "EtherSportz",
  ETZ: "EtherZero",
  ECH: "EthereCash",
  ETH: "Ethereum",
  ETBT: "Ethereum Black",
  BLUE: "Ethereum Blue",
  ECASH: "Ethereum Cash",
  ETC: "Ethereum Classic",
  ETHD: "Ethereum Dark",
  ETG: "Ethereum Gold",
  ETHM: "Ethereum Meta",
  EMV: "Ethereum Movie Venture",
  ETHPR: "Ethereum Premium",
  EQC: "Ethereum Qchain Token",
  LNK: "Ethereum.Link",
  BTCE: "EthereumBitcoin",
  ETF: "EthereumFog",
  ELITE: "EthereumLite",
  ETHS: "EthereumScrypt",
  DIP: "Etherisc",
  RIYA: "Etheriya",
  DICE: "Etheroll",
  FUEL: "Etherparty",
  ESN: "Ethersocial",
  ESC: "Ethersportcoin",
  "NEC*": "Ethfinex Nectar Token",
  ETHIX: "EthicHub",
  HORSE: "Ethorse",
  ETHOS: "Ethos",
  ET4: "Eticket4",
  EUC: "Eurocoin",
  ECTE: "EurocoinToken",
  ERC: "EuropeCoin",
  "EVN*": "EvenCoin",
  EVENT: "Event Token",
  EVC: "Eventchain",
  EGC: "EverGreenCoin",
  EVER: "EverLife.AI",
  EVX: "Everex",
  IQ: "Everipedia",
  EVR: "Everus",
  EOC: "EveryonesCoin",
  EVIL: "EvilCoin",
  EXB: "ExaByte (EXB)",
  XUC: "Exchange Union",
  EXCC: "ExchangeCoin",
  EXN: "ExchangeN",
  EXCL: "Exclusive Coin",
  EXE: "ExeCoin",
  XNT: "Exenium",
  EXC: "Eximchain",
  EXIT: "ExitCoin",
  EXO: "Exosis",
  EXP: "Expanse",
  EXPR: "Expercoin",
  XP: "Experience Points",
  "EXT*": "Experience Token",
  EXY: "Experty",
  EON: "Exscudo",
  TAURI: "Extauri",
  EXTN: "Extensive Coin",
  XTRA: "ExtraCredit",
  "ELT*": "ExtraLovers",
  XSB: "Extreme Sportsbook",
  XT: "ExtremeCoin",
  F16: "F16Coin",
  FARM: "FARM Coin",
  FX: "FCoin",
  FIBRE: "FIBRE",
  eFIC: "FIC Network",
  FLASH: "FLASH coin",
  FLIK: "FLiK",
  FLM: "FOLM coin",
  FOREX: "FOREXCOIN",
  FRED: "FREDEnergy",
  FREE: "FREE coin",
  FXP: "FXPay",
  FABA: "Faba Invest",
  FT: "Fabric Token",
  FC: "Facecoin",
  FACE: "Faceter",
  FTR: "FactR",
  FCT: "Factoids",
  FAIR: "FairCoin",
  "FAIR*": "FairGame",
  FAIRC: "Faireum Token",
  FAME: "FameCoin",
  FAN: "Fan360",
  FANZ: "FanChain",
  XFT: "Fantasy Cash",
  FTM: "Fantom",
  FCN: "FantomCoin",
  FRD: "Farad",
  F2K: "Farm2Kitchen",
  FTT: "FarmaTrust",
  FST: "FastCoin",
  FTUM: "Fatum",
  "DROP*": "FaucetCoin",
  FAZZ: "FazzCoin",
  FTC: "FeatherCoin",
  TIPS: "FedoraCoin",
  FET: "Fetch.AI",
  FIH: "Fidelity House",
  FLC: "Fieldcoin",
  FIII: "Fiii",
  FIL: "FileCoin",
  FFM: "Files.fm Library",
  FILL: "Fillit",
  FNTB: "FinTab",
  FNX: "FinanceX",
  FIND: "FindCoin",
  FNL: "Finlocale",
  FIN: "Finom FIN Token",
  NOM: "Finom NOM Token",
  FTX: "FintruX",
  FIRE: "FireCoin",
  FLOT: "FireLotto",
  FRC: "FireRoosterCoin",
  FFC: "FireflyCoin",
  "1ST": "FirstBlood",
  FIRST: "FirstCoin",
  FRST: "FirstCoin",
  FIST: "FistBump",
  FIT: "Fitcoin",
  FRV: "Fitrova",
  FLAP: "Flappy Coin",
  FLX: "Flash",
  FLVR: "FlavorCoin",
  FNP: "FlipNpik",
  FLIXX: "Flixxo",
  FLO: "Flo",
  FLT: "FlutterCoin",
  FLUZ: "FluzFluz",
  FLY: "FlyCoin",
  FYP: "FlypMe",
  FOAM: "Foam",
  FLDC: "Folding Coin",
  FLLW: "Follow Coin",
  FNO: "Fonero",
  FONZ: "FonzieCoin",
  FOOD: "FoodCoin",
  FOPA: "Fopacoin",
  FOR: "Force Coin",
  XFC: "Forever Coin",
  FML: "FormulA",
  FFCT: "FortFC",
  FOTA: "Fortuna",
  FSBT: "Forty Seven Bank",
  FOXT: "Fox Trading",
  FRAC: "FractalCoin",
  FRN: "Francs",
  FRK: "Franko",
  FRWC: "Frankywillcoin",
  FRAZ: "FrazCoin",
  FGZ: "Free Game Zone",
  FRE: "FreeCoin",
  FRECN: "Freldo",
  FREC: "Freyrchain",
  FSC: "FriendshipCoin",
  FDZ: "Friendz",
  FUCK: "Fuck Token",
  FC2: "Fuel2Coin",
  FJC: "FujiCoin",
  NTO: "Fujinto",
  FLS: "Fuloos Coin",
  FUNC: "FunCoin",
  FUN: "FunFair",
  FUND: "Fund Platform",
  FUNDZ: "FundFantasy",
  FND: "FundRequest",
  FYN: "FundYourselfNow",
  ATON: "Further Network",
  "FSN*": "Fusion",
  FSN: "Fusion",
  FUTC: "FutCoin",
  FTRC: "FutureCoin",
  FTP: "FuturePoints",
  FTW: "FutureWorks",
  FPC: "Futurepia",
  FTO: "FuturoCoin",
  FXT: "FuzeX",
  FUZZ: "Fuzzballs",
  GAIA: "GAIA Platform",
  GAKH: "GAKHcoin",
  GTX: "GALLACTIC",
  GMB: "GAMB",
  GAT: "GATCOIN",
  GBRC: "GBR Coin",
  GGP: "GGPro",
  GGR: "GGRocket",
  GTO: "GIFTO",
  GIN: "GINcoin",
  GIZ: "GIZMOcoin",
  "GMC*": "GMC Coin",
  GPU: "GPU Coin",
  GSM: "GSM Coin",
  GXS: "GXChain",
  GBO: "Gabro.io",
  GEP: "Gaia",
  GNR: "Gainer",
  ORE: "Galactrum",
  GES: "Galaxy eSolutions",
  GLX: "GalaxyCoin",
  GALI: "Galilel",
  GLN: "Galion Token",
  GAM: "Gambit coin",
  GMCN: "GambleCoin",
  GTC: "Game",
  GXT: "Game Protocol",
  GBT: "GameBetCoin",
  GML: "GameLeagueCoin",
  GST: "GameStars",
  UNITS: "GameUnits",
  GX: "GameX",
  GAME: "Gamecredits",
  GDX: "Gamedex",
  FLP: "Gameflip",
  GNJ: "GanjaCoin V2",
  GAP: "Gapcoin",
  GRLC: "Garlicoin",
  GAS: "Gas",
  FORK: "Gastro Advisor Token",
  GBA: "Geeba",
  GEMA: "Gemera",
  GUSD: "Gemini Dollar",
  GEM: "Gems",
  GMS: "Gemstra",
  GEMZ: "Gemz Social",
  "GXC*": "GenXCoin",
  GNX: "Genaro Network",
  GENX: "Genesis Network",
  GVT: "Genesis Vision",
  XGS: "GenesisX",
  GSY: "GenesysCoin",
  GEN: "Genstake",
  GEO: "GeoCoin",
  GUNS: "GeoFunders",
  GEON: "Geon",
  GER: "GermanCoin",
  SPKTR: "Ghost Coin",
  GHC: "GhostCoin",
  GHOUL: "Ghoul Coin",
  GIC: "Giant",
  GIFT: "GiftNet",
  GFT: "Giftcoin",
  GIG: "GigCoin",
  GBTC: "GigTricks",
  WTT: "Giga Watt",
  GZB: "Gigzi",
  GGS: "Gilgam",
  GIM: "Gimli",
  GMR: "Gimmer",
  GGC: "Gingr",
  GOT: "Giotto Coin",
  GIVE: "GiveCoin",
  GLA: "Gladius",
  GLOBE: "Global",
  GCR: "Global Currency Reserve",
  GJC: "Global Jobcoin",
  GSC: "Global Social Chain",
  "GTC*": "Global Tour Coin",
  GTIB: "Global Trust Coin",
  BSTY: "GlobalBoost",
  GLC: "GlobalCoin",
  GLT: "GlobalToken",
  GVE: "Globalvillage Ecosystem",
  GSI: "Globex SCI",
  GBXT: "Globitex Token",
  GSX: "GlowShares",
  GLYPH: "GlyphCoin",
  GNO: "Gnosis",
  xGOx: "Go!",
  GBX: "GoByte",
  GO: "GoChain",
  "GOT*": "GoToken",
  GOA: "GoaCoin",
  GOAL: "Goal Bonanza",
  GOAT: "Goat",
  GBE: "Godbex",
  GDL: "GodlyCoin",
  XR: "Gofind XR",
  GPL: "Gold Pressed Latinum",
  GRX: "Gold Reward Token",
  GB: "GoldBlocks",
  GLD: "GoldCoin",
  MNTP: "GoldMint",
  GP: "GoldPieces",
  XGR: "GoldReserve",
  GMA: "Goldchip Mining Asset",
  GEA: "Goldea",
  XGH: "Golden Hash",
  XGB: "GoldenBird",
  GLDR: "Golder Coin",
  GMX: "Goldmaxcoin",
  GNT: "Golem Network Token",
  GOLF: "GolfCoin",
  GOLOS: "Golos",
  GBG: "Golos Gold",
  GOOD: "GoodCoin",
  "GOOD*": "Goodomy",
  GOON: "Goonies",
  "BUCKS*": "GorillaBucks",
  GOTX: "GothicCoin",
  GRFT: "Graft Blockchain",
  GDC: "GrandCoin",
  GAI: "GraphGrail AI",
  "77G": "GraphenTech",
  GRAV: "Graviton",
  GBIT: "GravityBit",
  WPP: "Green Energy Token",
  GRE: "GreenCoin",
  GRMD: "GreenMed",
  GEX: "GreenX",
  GNC: "Greencoin",
  GTN: "Greentoken",
  GREXIT: "GrexitCoin",
  GC: "Gric Coin",
  GRID: "Grid+",
  GRC: "GridCoin",
  GRM: "GridMaster",
  GMC: "Gridmaster",
  GRIN: "Grin",
  GRS: "Groestlcoin",
  GRO: "Gron Digital",
  GRWI: "Growers International",
  GROW: "GrownCoin",
  GRW: "GrowthCoin",
  GTR: "Gturbo",
  GET: "Guaranteed Entrance Token",
  GETX: "Guaranteed Ethurance Token Extra",
  GUAR: "Guarium",
  GCC: "GuccioneCoin",
  GUE: "GuerillaCoin",
  NLG: "Gulden",
  GUN: "GunCoin",
  GUP: "Guppy",
  GXC: "Gx Coin",
  HIDU: "H-Education World",
  HART: "HARA",
  HBZ: "HBZ Coin",
  HIX: "HELIX Orange",
  HELL: "HELL COIN",
  HRO: "HEROIC.com",
  PLAY: "HEROcoin",
  HOLD: "HOLD",
  HLDY: "HOLIDAY",
  HQX: "HOQU",
  HODL: "HOdlcoin",
  HTML: "HTML Coin",
  HTML5: "HTML5 Coin",
  HUS: "HUSSY",
  HYC: "HYCON",
  HYGH: "HYGH",
  HKN: "Hacken",
  HKG: "Hacker Gold",
  HAC: "Hackspace Capital",
  HPAY: "HadePay",
  HLC: "Halal-Chain",
  HAL: "Halcyon",
  HALLO: "Halloween Coin",
  HALO: "Halo Platform",
  HMT: "Hamster Marketplace Token",
  HAMS: "HamsterCoin",
  HANA: "Hanacoin",
  HPC: "HappyCoin",
  HCC: "HappyCreatorCoin",
  HRBE: "Harambee Token",
  HRB: "Harbour DAO",
  HMN: "Harvest Masternode Coin",
  HSC: "HashCoin",
  HGS: "HashGains",
  HASH: "Hashbon",
  GARD: "Hashgard",
  XHV: "Haven Protocol",
  HAT: "Hawala.Exchange",
  HZT: "HazMatCoin",
  HAZE: "HazeCoin",
  HDAC: "Hdac",
  HHEM: "Healthureum",
  WORM: "HealthyWorm",
  HB: "HeartBout",
  HEAT: "Heat Ledger",
  HVC: "HeavyCoin",
  HDG: "Hedge Token",
  HEDG: "HedgeTrade",
  HEDGE: "Hedgecoin",
  HEEL: "HeelCoin",
  HYS: "Heiss Shares",
  HLM: "Helium",
  HLX: "Helix3",
  HNC: "Hellenic Coin",
  HGT: "Hello Gold",
  HMP: "HempCoin",
  HERB: "HerbCoin",
  HERO: "Hero",
  HER: "Hero Node",
  HETA: "HetaChain",
  HEX: "HexCoin",
  HXC: "HexanCoin",
  HXT: "HextraCoin",
  HXX: "HexxCoin",
  HMC: "Hi Mutual Society",
  XHI: "HiCoin",
  HIH: "HiHealth",
  HPB: "High Performance Blockchain",
  HVCO: "High Voltage Coin",
  AIMS: "HighCastle Token",
  HV: "HighVibe.Network",
  HGO: "HireGo",
  HIRE: "HireMatch",
  HFT: "Hirefreehands",
  HIT: "HitChain",
  HTC: "Hitcoin",
  HIVE: "Hive",
  HVN: "Hiveterminal Token",
  HBN: "HoboNickels",
  HWC: "HollyWoodCoin",
  "HOT*": "Holo",
  HBC: "HomeBlockCoin",
  HMD: "Homelend",
  HONEY: "Honey",
  HZ: "Horizon",
  HSP: "Horse Power",
  HORUS: "HorusPay",
  HYT: "HoryouToken",
  HSR: "Hshare",
  HBT: "Hubii Network",
  HMQ: "Humaniq",
  "HNC*": "Huncoin",
  HUC: "HunterCoin",
  HT: "Huobi Token",
  HUR: "Hurify",
  HUSH: "Hush",
  HOT: "Hydro Protocol",
  HYDRO: "Hydrogen",
  H2O: "Hydrominer",
  H3O: "Hydrominer",
  HC: "HyperCash",
  HYPER: "HyperCoin",
  HLD: "HyperLending",
  HQT: "HyperQuant",
  HBX: "Hyperbridge",
  TREE: "HyperionX",
  HPSP: "Hyperspace",
  HYP: "Hyperstake",
  IHT: "I-House Token",
  I0C: "I0coin",
  IAG: "IAGON",
  IAM: "IAME Identity",
  ICASH: "ICASH",
  ICOO: "ICO OpenLedger",
  ICOS: "ICOBox",
  ICX: "ICON Project",
  ICST: "ICST",
  IDAC: "IDAC",
  IDAP: "IDAP",
  IDXM: "IDEX Membership",
  IDM: "IDM",
  IG: "IG Token",
  IGTT: "IGT",
  ILC: "ILCoin",
  ILCT: "ILCoin Token",
  IML: "IMMLA",
  ITR: "INTRO",
  IOC: "IOCoin",
  IOST: "IOS token",
  IOT: "IOTA",
  IOTW: "IOTW",
  IOUX: "IOU",
  IOU: "IOU1",
  IOV: "IOV",
  IPSX: "IP Exchange",
  "IPC*": "IPChain",
  IQN: "IQeon",
  IRC: "IRONCOIN",
  IVN: "IVN Security",
  IXC: "IXcoin",
  IZX: "IZX",
  ROCK2: "Ice Rock Mining",
  ICB: "IceBergCoin",
  ICHX: "IceChain",
  ICOB: "Icobid",
  ICON: "Iconic",
  ICN: "Iconomi",
  IDC: "IdealCoin",
  IGNIS: "Ignis",
  IC: "Ignition",
  REX: "Imbrex",
  IMGZ: "Imigize",
  IMVR: "ImmVRse",
  IMX: "Impact",
  IMPCH: "Impeach",
  IPC: "ImperialCoin",
  XIM: "Impresso",
  IMPS: "Impulse Coin",
  IN: "InCoin",
  INX: "InMax",
  NKA: "IncaKoin",
  INCNT: "Incent",
  INCP: "InceptionCoin",
  INC: "Incrementum",
  IDH: "IndaHash",
  IMS: "Independent Money System",
  ERC20: "Index ERC20",
  INDI: "IndiCoin",
  IND: "Indorse",
  IFX: "Infinex",
  IFC: "Infinite Coin",
  XIN: "Infinity Economics",
  INF8: "Infinium-8",
  IFLT: "InflationCoin",
  IFUM: "Infleum",
  INFLR: "Inflr",
  INTO: "Influ Token",
  INFX: "Influxcoin",
  INK: "Ink",
  XNK: "Ink Protocol",
  ILK: "Inlock",
  SOUND: "Inmusik",
  INN: "Innova",
  MINX: "InnovaMinex",
  INSN: "Insane Coin",
  INSANE: "InsaneCoin",
  WOLF: "Insanity Coin",
  INSTAR: "Insights Network",
  INS: "Insolar",
  ICC: "Insta Cash Coin",
  MINE: "Instamine Nuggets",
  SPON: "Instant Sponsor Token",
  INSUR: "InsurChain Coin",
  IPL: "InsurePal",
  ISR: "Insureum",
  IQB: "Intelligence Quotient Benefit",
  ITT: "Intelligent Trading",
  "XID*": "International Diamond Coin",
  INT: "Internet Node Token",
  IOP: "Internet of People",
  INXT: "Internxt",
  ISH: "Interstellar Holdings",
  ITZ: "Interzone",
  ICT: "Intrachain",
  "INV*": "Invacio",
  IDT: "InvestDigital",
  IFT: "InvestFeed",
  INVX: "Investx",
  IVC: "Investy Coin",
  INV: "Invictus",
  IHF: "Invictus Hyperion Fund",
  IVZ: "InvisibleCoin",
  INVOX: "Invox Finance",
  IZA: "Inzura",
  ITC: "IoT Chain",
  IOTX: "IoTeX Network",
  ION: "Ionomy",
  TLU: "Irene Energy",
  IRL: "IrishCoin",
  ISL: "IslaCoin",
  ITL: "Italian Lira",
  ITA: "Italocoin",
  ING: "Iungo",
  IEC: "IvugeoEvolutionCoin",
  IVY: "IvyKoin",
  IWT: "IwToken",
  J8T: "JET8",
  JEX: "JEX Token",
  JIO: "JIO Token",
  JOYS: "JOYS",
  "JOY*": "JOYSO",
  JSE: "JSEcoin",
  JANE: "JaneCoin",
  JNS: "Janus",
  JVY: "Javvy",
  JC: "JesusCoin",
  JET: "Jetcoin",
  JWL: "Jewels",
  JIB: "Jibbit",
  JNT: "Jibrel Network Token",
  JIF: "JiffyCoin",
  JCR: "Jincor",
  JINN: "Jinn",
  JOBS: "JobsCoin",
  J: "JoinCoin",
  JOINT: "Joint Ventures",
  JOK: "JokerCoin",
  XJO: "JouleCoin",
  JOYT: "JoyToken",
  JOY: "Joycoin",
  JUDGE: "JudgeCoin",
  JBS: "JumBucks Coin",
  JUMP: "Jumpcoin",
  JKC: "JunkCoin",
  JMC: "Junson Ming Chan Coin",
  JDC: "JustDatingSite",
  KSYS: "K-Systems",
  KAAS: "KAASY.AI",
  KAT: "KATZcoin",
  KEC: "KEYCO",
  KIBIS: "KIBIS",
  TOS: "KRATOS",
  KRC: "KRCoin",
  KREDS: "KREDS",
  KUBO: "KUBO",
  KWH: "KWHCoin",
  KZC: "KZCash",
  KLKS: "Kalkulus",
  KAPU: "Kapu",
  KBC: "Karatgold coin",
  KRB: "Karbo",
  KRM: "Karma",
  KARMA: "Karma",
  K2G: "Kasko2go",
  KAYI: "Kay\u0131",
  KCASH: "Kcash",
  KEK: "KekCoin",
  KEN: "Kencoin",
  KEP: "Kepler",
  KC: "Kernalcoin",
  KETAN: "Ketan",
  KEX: "KexCoin",
  "KEY*": "KeyCoin",
  KMX: "KiMex",
  KICK: "KickCoin",
  KLC: "KiloCoin",
  KIN: "Kin",
  KIND: "Kind Ads",
  KVT: "Kinesis Velocity Token",
  KING: "King93",
  "KNC**": "KingN Coin",
  MEOW: "Kittehcoin",
  KLK: "Klickzie",
  KED: "Klingon Empire Darsek",
  KDC: "Klondike Coin",
  KNW: "Knowledge",
  KOBO: "KoboCoin",
  KOLION: "Kolion",
  KMD: "Komodo",
  KORE: "Kore",
  KOTO: "Koto",
  KUSD: "Kowala",
  KRAK: "Kraken",
  KRONE: "Kronecoin",
  KSS: "Krosscoin",
  KGC: "KrugerCoin",
  KRL: "Kryll",
  KTK: "KryptCoin",
  KRP: "Kryptoin",
  KR: "Krypton",
  KBX: "KuBitX",
  KBR: "Kubera Coin",
  KUBOS: "KubosCoin",
  KCS: "Kucoin",
  KUE: "Kuende",
  KURT: "Kurrent",
  KUSH: "KushCoin",
  KUV: "Kuverit",
  "KVT*": "Kvantor",
  KNC: "Kyber Network",
  LAX: "LAPO",
  LA: "LATOKEN",
  LBC: "LBRY Credits",
  LAO: "LC Token",
  LEO: "LEOcoin",
  LGBTQ: "LGBTQoin",
  LHC: "LHCoin",
  LIFE: "LIFE",
  LN: "LINK",
  LNKC: "LINKCHAIN",
  VEEN: "LIVEEN",
  LIPC: "LIpcoin",
  LTBC: "LTBCoin",
  LTO: "LTO Network",
  LUMA: "LUMA Token",
  LUX: "LUXCoin",
  LVX: "LVX",
  LYN: "LYNCHPIN Token",
  LALA: "LaLa World",
  LBR: "LaborCrypto",
  LAB: "Labrys",
  "BAC*": "LakeBanker",
  TAU: "Lamden Tau",
  PIX: "Lampix",
  LANA: "LanaCoin",
  AXIS: "LaneAxis",
  LTH: "Lathaan",
  LAT: "Latium",
  LATX: "LatiumX",
  LAZ: "Lazarus",
  LEPEN: "LePenCoin",
  LEA: "LeaCoin",
  LDC: "LeadCoin",
  LEAF: "LeafCoin",
  LGD: "Legends Cryptocurrency",
  LGO: "Legolas Exchange",
  LELE: "Lelecoin",
  LEMON: "LemonCoin",
  LCT: "LendConnect",
  LND: "Lendingblock",
  LOAN: "Lendoit",
  LST: "Lendroid Support Token",
  LENIN: "LeninCoin",
  LIR: "Let it Ride",
  LTHN: "Lethean",
  "LVL*": "LevelNet Token",
  LVG: "Leverage Coin",
  LEV: "Leverj",
  XLC: "LeviarCoin",
  LIB: "Libellum",
  XLB: "LibertyCoin",
  LBA: "Libra Credit",
  LXC: "LibrexCoin",
  LIGER: "Ligercoin",
  LSD: "LightSpeedCoin",
  "LPC*": "Lightpaycoin",
  LIKE: "LikeCoin",
  LK: "Liker",
  LIMX: "LimeCoinX",
  LTD: "Limited Coin",
  LINDA: "Linda",
  LET: "LinkEye",
  "LNC*": "Linker Coin",
  LINX: "Linx",
  LIPS: "LipChain",
  LEN: "Liqnet",
  LQD: "Liquid",
  LQ8: "Liquid8",
  LQDN: "Liquidity Network",
  LSK: "Lisk",
  LTCC: "Listerclassic Coin",
  LBTC: "LiteBitcoin",
  LTG: "LiteCoin Gold",
  LTCU: "LiteCoin Ultra",
  LCWP: "LiteCoinW Plus",
  LTCR: "LiteCreed",
  LDOGE: "LiteDoge",
  LTB: "Litebar",
  LTC: "Litecoin",
  LTCH: "Litecoin Cash",
  LCP: "Litecoin Plus",
  LCASH: "LitecoinCash",
  LCC: "LitecoinCash",
  LTCD: "LitecoinDark",
  LTCP: "LitecoinPro",
  LTCX: "LitecoinX",
  LTZ: "Litecoinz",
  LNT: "Litenett",
  LTS: "Litestar Coin",
  LIT: "Lithium",
  LITION: "Lition",
  LTA: "Litra",
  LPC: "Little Phil",
  LIVE: "Live Stars",
  LVN: "LivenPay",
  LPT: "Livepeer",
  LIV: "LiviaCoin",
  LIZ: "Lizus Payment",
  LTE: "Local Token Exchange",
  LWF: "Local World Forwarders",
  LCS: "LocalCoinSwap",
  LOCI: "LociCoin",
  "LOC*": "LockTrip",
  LOC: "Loco",
  LGR: "Logarithm",
  LOKI: "Loki",
  LLG: "Loligo",
  LMC: "LomoCoin",
  LOOK: "LookCoin",
  LOOM: "Loom Network",
  LRC: "Loopring",
  LOT: "LottoCoin",
  LYK: "Loyakk Vega",
  LYL: "LoyalCoin",
  BASH: "LuckChain",
  LCK: "Luckbox",
  LK7: "Lucky7Coin",
  LUCKY: "LuckyBlocks",
  LKY: "LuckyCoin",
  LCR: "Lucre",
  LDM: "Ludum token",
  LNL: "LunarLink",
  LUN: "Lunyr",
  LC: "Lutetium Coin",
  "LUX**": "Luxmi Coin",
  LYC: "LycanCoin",
  LDN: "Lydiancoin",
  LYFE: "Lyfe",
  LKK: "Lykke",
  LYM: "Lympo",
  LYNK: "Lynked.World",
  LYNX: "Lynx",
  LYB: "LyraBar",
  M2O: "M2O Token",
  MDN: "MADANA",
  MAKE: "MAKE",
  MRK: "MARK.SPACE",
  MCAP: "MCAP",
  MCV: "MCV Token",
  MTEL: "MEDoctor",
  MEETONE: "MEET.ONE",
  MFX: "MFChain",
  MIMI: "MIMI Money",
  MIODIO: "MIODIOCOIN",
  MIS: "MIScoin",
  MILC: "MIcro Licensing Coin",
  MMNXT: "MMNXT",
  MMO: "MMOCoin",
  MMXVI: "MMXVI",
  MOAC: "MOAC",
  MOBU: "MOBU",
  MODEX: "MODEX Token",
  MOS: "MOS Coin",
  XDMC: "MPCX",
  MSD: "MSD",
  MTCMN: "MTC Mesh",
  MUN: "MUNcoin",
  MUSD: "MUSDcoin",
  MUST: "MUST Protocol",
  MVL: "MVL",
  YCE: "MYCE",
  MAC: "MachineCoin",
  MCRN: "MacronCoin",
  MRV: "Macroverse",
  "MDC*": "MadCoin",
  ART: "Maecenas",
  MAP: "Maester Protocol",
  "MAG**": "Maggie Token",
  MGN: "MagnaCoin",
  MAG: "Magnet",
  "MAG*": "Magos",
  MAID: "MaidSafe Coin",
  MMXIV: "MaieutiCoin",
  MNC: "MainCoin",
  MFT: "Mainframe",
  "MSC*": "MaisCoin",
  MIV: "MakeItViral",
  MKR: "Maker",
  "MAT*": "Manet Coin",
  MANNA: "Manna",
  MAPC: "MapCoin",
  MAR: "MarijuanaCoin",
  MASP: "Market.space",
  MRS: "MarsCoin",
  MARS: "MarsCoin",
  MXT: "MartexCoin",
  MARV: "Marvelous",
  MARX: "MarxCoin",
  MARYJ: "MaryJane Coin",
  MSR: "Masari",
  MC: "Mass Coin",
  MASS: "Mass.Cloud",
  MGD: "MassGrid",
  MCAR: "MasterCar",
  MSC: "MasterCoin",
  MM: "MasterMint",
  MTR: "MasterTraderCoin",
  "MAN*": "Matrix AI Network",
  MTX: "Matryx",
  MPG: "Max Property Group",
  MAX: "MaxCoin",
  MYC: "MayaCoin",
  MZC: "MazaCoin",
  MBIT: "Mbitbooks",
  MLITE: "MeLite",
  "MDT*": "Measurable Data Token",
  "MED*": "MediBloc",
  MEDI: "MediBond",
  MCU: "MediChain",
  MDS: "MediShares",
  "MNT*": "Media Network Coin",
  MPT: "Media Protocol Token",
  MEDX: "Mediblock",
  MDC: "MedicCoin",
  MEDIC: "MedicCoin",
  "MTN*": "Medicalchain",
  MHP: "MedicoHealth",
  MED: "MediterraneanCoin",
  MPRO: "MediumProject",
  MEC: "MegaCoin",
  MEGA: "MegaFlash",
  XMS: "Megastake",
  MEL: "Melior AI",
  MLN: "Melon",
  MBN: "Membrana",
  MET: "Memessenger",
  "EMT*": "Memority",
  MMC: "MemoryCoin",
  MPAY: "Menapay",
  ONE: "Menlo One",
  MENU: "MenuBuzz",
  MRN: "Mercoin",
  MVP: "Merculet",
  MER: "Mercury",
  GMT: "Mercury Protocol",
  MHC: "MetaHash",
  METM: "MetaMorph",
  META: "Metadium",
  MTL: "Metal",
  MTLM3: "Metal Music v3",
  METAL: "MetalCoin",
  ETP: "Metaverse",
  "MET*": "Metronome",
  MIT: "MiMiner",
  MBTC: "MicroBitcoin",
  AMM: "MicroMoney",
  MDT: "Midnight",
  MON: "MilionCoin",
  MUU: "MilkCoin",
  MIL: "Milllionaire Coin",
  MILO: "MiloCoin",
  MINC: "MinCoin",
  MG: "Mind Gene",
  MND: "MindCoin",
  MIC: "Mindexcoin",
  MAI: "Mindsync",
  "MINT*": "Mineable Token",
  MIO: "Miner One token",
  MIN: "Minerals Coin",
  MNE: "Minereum",
  MRT: "MinersReward",
  MNM: "Mineum",
  MINEX: "Minex",
  MNX: "MinexCoin",
  MAT: "MiniApps",
  MNTS: "Mint",
  MINT: "MintCoin",
  BIP: "Minter",
  MITH: "Mithril",
  "XIN*": "Mixin",
  MIB: "Mobile Integrated Blockchain",
  "CHF*": "MobileBridge Momentum",
  MGO: "MobileGo",
  MOLK: "Mobilink Token",
  MOBI: "Mobius",
  MTRC: "ModulTrade",
  "MDL*": "Modulum",
  MOD: "Modum",
  MDA: "Moeda",
  MOIN: "MoinCoin",
  MOJO: "Mojocoin",
  MOF: "Molecular Future",
  MOL: "Molecule",
  TAB: "MollyCoin",
  MMTM: "Momentum",
  MONA: "MonaCoin",
  MNZ: "Monaize",
  XMR: "Monero",
  ZMR: "Monero 0",
  XMC: "Monero Classic",
  XMRG: "Monero Gold",
  XMO: "Monero Original",
  XMV: "MoneroV",
  MONETA: "Moneta",
  MNV: "MonetaVerde",
  MUE: "MonetaryUnit",
  MTH: "Monetha",
  MTZ: "Monetizr",
  MNB: "MoneyBag",
  MONEY: "MoneyCoin",
  "MRP*": "MoneyRebel",
  IMT: "MoneyToken",
  MNY: "Monkey",
  MONK: "Monkey Project",
  XMCC: "Monoeci",
  MNR: "Monoreto",
  MBI: "Monster Byte Inc",
  MBLC: "Mont Blanc",
  MOON: "MoonCoin",
  LX: "Moonlight",
  MZG: "Moozicore",
  MITX: "Morpheus Infrastructure Token",
  MRPH: "Morpheus Network",
  MRP: "MorpheusCoin",
  MZX: "Mosaic Network",
  MOAT: "Mother Of All Tokens",
  MSP: "Mothership",
  XMN: "Motion",
  "MTN**": "Motion",
  MOTO: "Motocoin",
  MOV: "MovieCoin",
  MTK: "Moya Token",
  MRSA: "MrsaCoin",
  MUDRA: "MudraCoin",
  MTT: "MulTra",
  MLT: "MultiGames",
  MWC: "MultiWallet Coin",
  MBT: "Multibot",
  MTCN: "Multiven",
  MRY: "MurrayCoin",
  MUSE: "Muse",
  MITC: "MusicLife",
  MUSIC: "Musicoin",
  MCI: "Musiconomi",
  MST: "MustangCoin",
  MUT: "Mutual Coin",
  MBC: "My Big Coin",
  MYB: "MyBit",
  MCB: "MyCryptoBank",
  MYDFS: "MyDFS",
  MAZC: "MyMazzu",
  "MT*": "MyToken",
  WISH: "MyWish",
  MT: "Mycelium Token",
  MYO: "Mycro",
  MPXT: "Myplacex",
  XMY: "MyriadCoin",
  MYST: "Mysterium",
  NANJ: "NANJCOIN",
  XEM: "NEM",
  NEO: "NEO",
  NEOG: "NEO Gold",
  NEXO: "NEXO",
  NOX: "NITRO",
  NIX: "NIX",
  NKN: "NKN",
  NOAH: "NOAHCOIN",
  NBAR: "NOBAR",
  NOIA: "NOIA Network",
  NOIZ: "NOIZ",
  CHFN: "NOKU CHF",
  EURN: "NOKU EUR",
  NOKU: "NOKU Master token",
  NSP: "NOMAD.space",
  NOW: "NOW Token",
  NPC: "NPCcoin",
  NPER: "NPER",
  NVST: "NVO",
  NWP: "NWPSolution",
  NXE: "NXEcoin",
  NXTI: "NXTI",
  NXTTY: "NXTTY",
  NYN: "NYNJA",
  NYX: "NYXCOIN",
  NFN: "Nafen",
  NGC: "NagaCoin",
  NZE: "Nagezeni",
  NKT: "NakomotoDark",
  NAM: "Namacoin",
  NMH: "Namahe",
  NMC: "Namecoin",
  NMK: "Namek",
  NAMO: "NamoCoin",
  NANO: "Nano",
  NHCT: "Nano Healthcare Token",
  NAN: "NanoToken",
  NPX: "Napoleon X",
  NRVE: "Narrative",
  NAS2: "Nas2Coin",
  NAUT: "Nautilus Coin",
  NAV: "NavCoin",
  NAVI: "NaviAddress",
  NAVIB: "Navibration",
  NEBL: "Neblio",
  NEBU: "Nebuchadnezzar",
  NBAI: "Nebula AI",
  NAS: "Nebulas",
  "NDC*": "NeedleCoin",
  NEF: "NefariousCoin",
  NRX: "Neironix",
  NEC: "NeoCoin",
  NEX: "Neonexchange",
  NEOS: "NeosCoin",
  NTCC: "NeptuneClassic",
  NBIT: "NetBit",
  NET: "NetCoin",
  NTM: "NetM",
  NETKO: "Netko",
  OUT: "Netscouters",
  NTWK: "Network Token",
  NETC: "NetworkCoin",
  "NEU*": "NeuCoin",
  NEU: "Neumark",
  NRP: "Neural Protocol",
  NRO: "Neuro",
  NRC: "Neurocoin",
  NRM: "Neuromachine",
  NTK: "Neurotoken",
  NTRN: "Neutron",
  NEVA: "NevaCoin",
  NDC: "NeverDie",
  NIC: "NewInvestCoin",
  NYC: "NewYorkCoin",
  NZC: "NewZealandCoin",
  NEWB: "Newbium",
  NEW: "Newton",
  NCP: "Newton Coin",
  NXC: "Nexium",
  NEXT: "Next.exchange Token",
  Pakka: "NextPakk",
  NXS: "Nexus",
  NEXXO: "Nexxo",
  NGIN: "Ngin",
  NICE: "NiceCoin",
  NIHL: "Nihilo Coin",
  NMB: "Nimbus Coin",
  NIMFA: "Nimfamoney",
  NIM: "Nimiq",
  NTC: "NineElevenTruthCoin",
  NDOGE: "NinjaDoge",
  NBR: "Niobio Cash",
  NBC: "Niobium",
  $NOBS: "No BS Crypto",
  NLC: "NoLimitCoin",
  NLC2: "NoLimitCoin",
  NOBL: "NobleCoin",
  NODE: "Node",
  NODIS: "Nodis",
  NVDX: "Nodvix",
  NRB: "NoirBits",
  NRS: "NoirShares",
  NUSD: "Nomin USD",
  NZO: "NonZero",
  NOO: "Noocoin",
  NVC: "NovaCoin",
  MNVM: "Novam",
  NWCN: "NowCoin",
  NBX: "Noxbox",
  NBT: "NuBits",
  NSR: "NuShares",
  NUBIS: "NubisCoin",
  NCASH: "Nucleus Vision",
  NUKE: "NukeCoin",
  NKC: "Nukecoinz",
  NLX: "Nullex",
  NULS: "Nuls",
  N7: "Number7",
  NUM: "NumbersCoin",
  NMR: "Numeraire",
  "XNC*": "Numismatic Collections",
  NMS: "Numus",
  NXT: "Nxt",
  NYAN: "NyanCoin",
  NBL: "Nybble",
  OATH: "OATH Protocol",
  ODE: "ODEM",
  ODMC: "ODMCoin",
  OK: "OKCash",
  OKOIN: "OKOIN",
  ONAM: "ONAM",
  OPC: "OP Coin",
  "OPP*": "OPP Open WiFi",
  ORET: "ORET Token",
  ORM: "ORIUM",
  ORS: "ORS Group",
  OASC: "Oasis City",
  OBITS: "Obits Coin",
  OBS: "Obscurebay",
  ODN: "Obsidian",
  OCL: "Oceanlab",
  OTX: "Octanox",
  "OCTO*": "OctoBit Coin",
  OCTO: "OctoCoin",
  OWC: "Oduwa",
  OCN: "Odyssey",
  OFCR: "OfficerCoin",
  OJX: "Ojooo",
  OKB: "Okex",
  OLM: "Olam",
  ODNT: "Old Dogs New Tricks",
  OLDSF: "OldSafeCoin",
  OLV: "OldV",
  OLE: "Olive",
  OLYMP: "OlympCoin",
  MOT: "Olympus Labs",
  OMA: "OmegaCoin",
  OMGC: "OmiseGO Classic",
  OMG: "OmiseGo",
  OMNI: "Omni",
  OMC: "OmniCron",
  ECOM: "Omnitude",
  ONL: "On.Live",
  OGT: "One Game",
  OSF: "One Solution",
  OLT: "OneLedger",
  RNT: "OneRoot Network",
  ONX: "Onix",
  OIO: "Online",
  ONT: "Ontology",
  ONGAS: "Ontology Gas",
  OPQ: "Opacity",
  XPO: "Opair",
  OPAL: "OpalCoin",
  OPEN: "Open Platform",
  OTN: "Open Trading Network",
  OAX: "OpenANX",
  BRIX: "OpenBrix",
  CHAT: "OpenChat",
  OSC: "OpenSourceCoin",
  ZNT: "OpenZen",
  OPES: "Opes",
  OPP: "Opporty",
  OPEX: "Optherium Token",
  OSA: "Optimal Shelf Availability Token",
  OPTION: "OptionCoin",
  OPU: "Opu Coin",
  OPT: "Opus",
  OCT: "OracleChain",
  OC: "OrangeCoin",
  ORBS: "Orbis",
  ORB: "Orbitcoin",
  RDC: "Ordocoin",
  ORGT: "Organic Token",
  ORC: "Organicco",
  ORI: "Origami",
  "ORS*": "OriginSport",
  TRAC: "OriginTrail",
  OCC: "Original Crypto Coin",
  ORLY: "OrlyCoin",
  ORME: "Ormeus Coin",
  ORO: "OroCoin",
  OROC: "Orocrypt",
  ORV: "Orvium",
  OICOIN: "Osmium Investment Coin",
  OS76: "OsmiumCoin",
  OWD: "Owlstand",
  ZXC: "Oxcert",
  OXY: "Oxycoin",
  PRL: "Oyster Pearl",
  OYS: "Oyster Platform",
  SHL: "Oyster Shell",
  P2PS: "P2P Solutions Foundation",
  GENE: "PARKGENE",
  PAT: "PATRON",
  PAXEX: "PAXEX",
  PQT: "PAquarium",
  PAI: "PCHAIN",
  PGF7T: "PGF500",
  PHI: "PHI Token",
  PITCH: "PITCH",
  PLNC: "PLNCoin",
  PCH: "POPCHAIN",
  PPOVR: "POVR",
  TOSS: "PROOF OF TOSS",
  PROUD: "PROUD Money",
  PROOF: "PROVER",
  PSI: "PSIcoin",
  PVP: "PVPChain",
  PWR: "PWR Coin",
  PX: "PXcoin",
  PCS: "Pabyosi Coin",
  PBC: "PabyosiCoin",
  PAC: "PacCoin",
  PAK: "Pakcoin",
  PLMT: "Pallium",
  PND: "PandaCoin",
  PINKX: "PantherCoin",
  PAN: "Pantos",
  PRT: "Papusha",
  PRP: "Papyrus",
  PRG: "Paragon",
  DUO: "ParallelCoin",
  PARA: "ParanoiaCoin",
  PARETO: "Pareto Network Token",
  PKB: "ParkByte",
  PAR: "Parlay",
  PART: "Particl",
  PASC: "Pascal Coin",
  PASL: "Pascal Lite",
  PAS: "Passive Coin",
  PTO: "Patentico",
  PTOY: "Patientory",
  PAVO: "Pavocoin",
  PAX: "Paxos Standard",
  PBLK: "PayBlock",
  PYC: "PayCoin",
  XPY: "PayCoin",
  PFR: "PayFair",
  PAYP: "PayPeer",
  PPP: "PayPie",
  PYP: "PayPro",
  PYN: "Paycentos",
  CON_: "Paycon",
  "PGC*": "Paygine",
  PMNT: "Paymon",
  PYT: "Payther",
  PEC: "PeaceCoin",
  PRLPAY: "PearlPay",
  XPB: "Pebble Coin",
  PBL: "Pebbles",
  PCL: "Peculium",
  PCO: "Pecunio",
  PCN: "PeepCoin",
  PMTN: "Peer Mountain",
  PPC: "PeerCoin",
  GUESS: "Peerguess",
  PPY: "Peerplays",
  PGC: "Pegascoin",
  "PEN*": "PenCoin",
  PNT: "Penta",
  PTA: "PentaCoin",
  PNY: "Peony Coin",
  MAN: "People",
  MEME: "Pepe",
  PEPECASH: "Pepe Cash",
  XPR: "Permian",
  PIE: "Persistent Information Exchange",
  PERU: "PeruCoin",
  PTC: "PesetaCoin",
  PSB: "PesoBit",
  PETL: "Petlife",
  PTR: "Petro",
  XPD: "PetroDollar",
  PXL: "Phalanx",
  "SOUL*": "Phantasma",
  PNX: "PhantomX",
  XPH: "PharmaCoin",
  PHS: "PhilosophersStone",
  PXC: "PhoenixCoin",
  PHM: "Phomeum",
  "PHR*": "Phore",
  PHTC: "Photochain",
  PHO: "Photon",
  PHT: "Photon Token",
  PHR: "Phreak",
  PGN: "Pigeoncoin",
  PIGGY: "Piggy Coin",
  PKC: "Pikciochain",
  PLR: "Pillar",
  PTT: "Pink Taxi Token",
  PINK: "PinkCoin",
  PINMO: "Pinmo",
  PCOIN: "Pioneer Coin",
  PIO: "Pioneershares",
  SKULL: "Pirate Blocks",
  PIRATE: "PirateCash",
  PIRL: "Pirl",
  PIZZA: "PizzaCoin",
  PLAI: "Plair",
  PLAN: "Plancoin",
  PLANET: "PlanetCoin",
  PLNX: "Planumex",
  XPT: "Plata",
  PTNX: "Platin",
  "PLC*": "PlatinCoin",
  PNC: "PlatiniumCoin",
  XPTX: "PlatinumBAR",
  LUC: "Play 2 Live",
  PLA: "PlayChip",
  PXG: "PlayGame",
  PKT: "Playkey",
  DN8: "Pldgr",
  PLG: "Pledgecamp",
  PLX: "PlexCoin",
  PLURA: "PluraCoin",
  PLC: "PlusCoin",
  PLUS1: "PlusOneCoin",
  GPPT: "Pluto Project Coin",
  "PTC**": "Plutocoin",
  PLU: "Pluton",
  PLTX: "PlutusX",
  POE: "Po.et",
  POS: "PoSToken",
  POA: "Poa Network",
  XPS: "PoisonIvyCoin",
  XPOKE: "PokeChain",
  POKER: "PokerCoin",
  XPST: "PokerSports",
  PAL: "PolicyPal Network",
  POLIS: "PolisPay",
  POLY: "PolyBit",
  NCT: "PolySwarm",
  PLBT: "Polybius",
  "POLY*": "Polymath Network",
  PON: "Ponder",
  PSK: "Pool of Stake",
  XSP: "PoolStamp",
  PPS: "PopulStay",
  POP: "PopularCoin",
  PPT: "Populous",
  PEX: "PosEx",
  PSD: "Poseidon",
  PCCM: "Poseidon Chain",
  OCEAN: "Poseidon Foundation",
  POSQ: "Poseidon Quark",
  POST: "PostCoin",
  POT: "PotCoin",
  POWR: "Power Ledger",
  PSM: "Prasm",
  PRE: "Premium",
  ENTT: "Presale Ventures",
  "PRE*": "Presearch",
  HILL: "President Clinton",
  PRES: "President Trump",
  PBT: "Primalbase",
  PST: "Primas",
  PSF: "Prime Shipping Foundation",
  PXI: "Prime-X1",
  PRIME: "PrimeChain",
  XPM: "PrimeCoin",
  PRX: "Printerium",
  PRM: "PrismChain",
  PIVX: "Private Instant Verified Transaction",
  PRIX: "Privatix",
  PZM: "Prizm",
  PRA: "ProChain",
  XPRO: "ProCoin",
  PROC: "ProCurrency",
  PCM: "Procom",
  "PROD*": "Productivist",
  PHC: "Profit Hunters Coin",
  PDC: "Project Decorum",
  JTX: "Project J",
  "PAI*": "Project Pai",
  OMX: "Project Shivom",
  PRFT: "Proof Suite Token",
  PROPS: "Props",
  "PTC*": "Propthereum",
  PRO: "Propy",
  VRP: "Prosense.tv",
  PGL: "Prospectors",
  PRC: "ProsperCoin",
  PROTON: "Proton",
  XES: "Proxeus",
  XPX: "ProximaX",
  PSEUD: "PseudoCash",
  PSY: "Psilocybin",
  PULSE: "Pulse",
  PMA: "PumaPay",
  NPXS: "Pundi X",
  PUPA: "PupaCoin",
  PURA: "Pura",
  PURE: "Pure",
  VIDZ: "PureVidz",
  PGT: "Puregold token",
  PURK: "Purk",
  PRPL: "Purple Token",
  PRPS: "Purpose",
  HLP: "Purpose Coin",
  PUSHI: "Pushi",
  PUT: "PutinCoin",
  PYLNT: "Pylon Network",
  QLC: "QLC Chain",
  QTUM: "QTUM",
  QUSD: "QUSD",
  "QBT*": "Qbao",
  QOBI: "Qobit",
  QORA: "QoraCoin",
  XQR: "Qredit",
  QBK: "QuBuck Coin",
  eQUAD: "Quadrant Protocol",
  QNT: "Quant",
  QNTU: "Quanta",
  QUANT: "Quantler",
  QNTR: "Quantor",
  QSP: "Quantstamp",
  QAU: "Quantum",
  QRL: "Quantum Resistant Ledger",
  Q1S: "Quantum1Net",
  QKC: "QuarkChain",
  QRK: "QuarkCoin",
  QTZ: "Quartz",
  QUA: "Quasa",
  QTL: "Quatloo",
  QCN: "Quazar Coin",
  Q2C: "QubitCoin",
  QBC: "Quebecoin",
  QCX: "QuickX Protocol",
  QSLV: "Quicksilver coin",
  QUIZ: "Quizando",
  QUN: "QunQun",
  QASH: "Quoine Liquid",
  XQN: "Quotient",
  QVT: "Qvolta",
  QWARK: "Qwark",
  QWC: "Qwertycoin",
  RFL: "RAFL",
  RAIZER: "RAIZER",
  KRX: "RAVN Korrax",
  RAWG: "RAWG",
  RAC: "RAcoin",
  RHOC: "RChain",
  "RCN*": "RCoin",
  REAL: "REAL",
  REBL: "REBL",
  MWAT: "RED MegaWatt",
  RST: "REGA Risk Sharing Token",
  REME: "REME-Coin",
  REM: "REMME",
  RENC: "RENC",
  RGC: "RG Coin",
  RIF: "RIF Token",
  ROI: "ROIcoin",
  ROS: "ROS Coin",
  RFT: "RYFTS",
  RADI: "RadicalCoin",
  RADS: "Radium",
  RDN: "RadonPay",
  "RDN*": "Raiden Network",
  RF: "Raido Financial",
  RAINC: "RainCheck",
  RAP: "Rapture",
  ROC: "Rasputin Online Coin",
  RTE: "Rate3",
  XRA: "Ratecoin",
  RATIO: "Ratio",
  RAVE: "Ravelous",
  RVN: "Ravencoin",
  RAYS: "Rays Network",
  RZR: "RazorCoin",
  RWE: "Real-World Evidence",
  RCT: "RealChain",
  REA: "Realisto",
  RCC: "Reality Clash",
  XRK: "RecordsKeeper",
  RRT: "Recovery Right Tokens",
  RRC: "Recycling Regeneration Chain",
  PHX: "Red Pulse Phoenix",
  REDC: "RedCab",
  RCX: "RedCrowCoin",
  RED: "Redcoin",
  RDD: "Reddcoin",
  REDN: "Reden",
  REE: "ReeCoin",
  REF: "RefToken",
  RFR: "Refereum",
  REC: "Regalcoin",
  RDS: "Reger Diamond",
  RLX: "Relex",
  REL: "Reliance",
  REMCO: "Remco",
  RPM: "Render Payment",
  RNDR: "Render Token",
  RNS: "RenosCoin",
  BERRY: "Rentberry",
  REPO: "Repo Coin",
  RPB: "Republia",
  REN: "Republic Token",
  REPUX: "Repux",
  REQ: "Request Network",
  RMS: "Resumeo Shares",
  RGT: "Retail.Global",
  RBIT: "ReturnBit",
  RNC: "ReturnCoin",
  R: "Revain",
  REV: "Revenu",
  RVR: "Revolution VR",
  XRE: "RevolverCoin",
  RWD: "Reward Vision",
  RMOB: "RewardMob",
  RHEA: "Rhea",
  XRL: "Rialto.AI",
  RBR: "Ribbit Rewards",
  RICE: "RiceCoin",
  RIDE: "Ride My Car",
  RIC: "Riecoin",
  RMESH: "RightMesh",
  RBT: "Rimbit",
  RING: "RingCoin",
  RIPO: "RipOffCoin",
  RIPAX: "RipaEx",
  RCN: "Ripio",
  RIPT: "RiptideCoin",
  RBX: "RiptoBuX",
  RISE: "Rise",
  RVT: "Rivetz",
  "RAC**": "RoBET",
  ROBET: "RoBet",
  RBDT: "RoBust Defense Token",
  "PUT*": "Robin8 Profile Utility Token",
  "RAC*": "RoboAdvisorCoin",
  ROX: "Robotina",
  RKT: "Rock Token",
  ROK: "Rockchain",
  "ROCK*": "RocketCoin",
  RPC: "RonPaulCoin",
  RSC: "Ronaldinho Soccer Coin",
  ROOT: "RootCoin",
  ROOTS: "RootProject",
  RT2: "RotoCoin",
  ROUND: "RoundCoin",
  ROE: "Rover Coin",
  RKC: "Royal Kingdom Coin",
  RYC: "RoyalCoin",
  ROYAL: "RoyalCoin",
  RYCN: "RoyalCoin 2.0",
  RBIES: "Rubies",
  RUBY: "Rubius",
  RUBIT: "Rublebit",
  RBY: "RubyCoin",
  RUFF: "Ruff",
  RUPX: "Rupaya",
  RUP: "Rupee",
  RC: "Russiacoin",
  RMC: "Russian Mining Coin",
  RUST: "RustCoin",
  RUSTBITS: "Rustbits",
  RYO: "Ryo",
  S8C: "S88 Coin",
  SABR: "SABR Coin",
  SGA: "SAGA",
  "SAR*": "SARCoin",
  SLY: "SELFLLERY",
  SGAT: "SGAT",
  SGP: "SGPay",
  XSH: "SHIELD",
  SIDT: "SID Token",
  SKYFT: "SKYFchain",
  SMNX: "SMNX",
  SSX: "SOMESING",
  SNM: "SONM",
  SXDT: "SPECTRE Dividend Token",
  SXUT: "SPECTRE Utility Token",
  SPICE: "SPiCE Venture Capital",
  SRCOIN: "SRCoin",
  SSV: "SSVCoin",
  STAC: "STAC",
  STACS: "STACS Token",
  STRS: "STARS",
  EURS: "STASIS EURS",
  STEX: "STEX",
  STK: "STK Token",
  STS: "STRESScoin",
  STRY: "STRYKZ",
  SUQA: "SUQA",
  SaTT: "SaTT",
  HAVEN: "Safe Haven",
  XSTC: "Safe Trade Coin",
  SAFE: "SafeCoin",
  SAFEX: "SafeExchangeCoin",
  SFR: "SaffronCoin",
  SAF: "Safinus",
  SAGA: "SagaCoin",
  SFU: "Saifu",
  "SKB*": "Sakura Bloom",
  SKR: "Sakuracoin",
  SAL: "SalPay",
  SALT: "Salt Lending",
  SLS: "SaluS",
  SMSR: "Samsara Coin",
  SND: "Sandcoin",
  SAN: "Santiment",
  "SPN*": "Sapien Network",
  XAI: "SapienceCoin",
  XRF: "Sarf",
  XRN: "Saronite",
  SAT: "Satisfaction Token",
  STV: "Sativa Coin",
  "MAD*": "SatoshiMadness",
  SAT2: "Saturn2Coin",
  STO: "Save The Ocean",
  SANDG: "Save and Gain",
  SWC: "Scanetchain Token",
  ST: "Scienceroot",
  SNcoin: "ScientificCoin",
  SCOOBY: "Scooby coin",
  SCORE: "Scorecoin",
  SCOR: "Scorista",
  "SCR*": "Scorum",
  SCOT: "Scotcoin",
  SCRIBE: "Scribe Network",
  SCRL: "Scroll",
  DDD: "Scry.info",
  SCRPT: "ScryptCoin",
  SCT: "ScryptToken",
  SRT: "Scrypto",
  SEAL: "Seal Network",
  SECI: "Seci",
  SCRT: "SecretCoin",
  SRC: "SecureCoin",
  SEC: "SecureCryptoPayments",
  SRXIO: "Securix",
  SET: "Securosys",
  SEEDS: "SeedShares",
  SEELE: "Seele",
  B2X: "SegWit2x",
  SEL: "SelenCoin",
  STOR: "Self Storage Coin",
  KEY: "SelfKey",
  SSC: "SelfSell",
  SGO: "Selfie GO",
  SEM: "Semux",
  SDRN: "Senderon",
  SNS: "Sense",
  SENSE: "Sense Token",
  SEN: "Sentaro",
  EMOT: "Sentigraph.io",
  SENT: "Sentinel",
  SENC: "Sentinel Chain",
  UPP: "Sentinel Protocol",
  SNTVT: "Sentivate",
  SEQ: "Sequence",
  SERA: "Seraph",
  SRNT: "Serenity",
  SRV: "ServAdvisor",
  SETH: "Sether",
  SP: "Sex Pistols",
  SXC: "SexCoin",
  SHA: "Shacoin",
  SHADE: "ShadeCoin",
  SDC: "ShadowCash",
  SHARD: "ShardCoin",
  SS: "Sharder",
  SSS: "ShareChain",
  eSwitch: "ShareMeAll",
  SHR: "ShareRing",
  SAK: "SharkCoin",
  SHKG: "SharkGate",
  "SHP*": "Sharpe Capital",
  JEW: "Shekel",
  SHLD: "ShieldCoin",
  SHIFT: "Shift",
  SH: "Shilling",
  SHE: "Shine Chain",
  SHIP: "ShipChain",
  SHPT: "Shipit",
  SHORTY: "ShortyCoin",
  SHOW: "ShowCoin",
  HAND: "ShowHand",
  SHPING: "Shping Coin",
  SHREK: "ShrekCoin",
  SCH: "Sia Cash Coin",
  SC: "Siacoin",
  SIB: "SibCoin",
  SGL: "Sigil",
  SIG: "Signal",
  SGN: "Signals Network",
  SIGT: "Signatum",
  SNTR: "Silent Notary",
  SILKT: "SilkChain",
  SILK: "SilkCoin",
  OST: "Simple Token",
  SPLB: "SimpleBank",
  SIGU: "Singular",
  SNGLS: "SingularDTV",
  AGI: "SingularityNET",
  SRN: "SirinLabs",
  SKC: "Skeincoin",
  SKI: "Skillchain",
  SKIN: "Skincoin",
  SKRP: "Skraps",
  "SKR*": "Skrilla Token",
  SKM: "Skrumble Network",
  SKB: "SkullBuzz",
  SKYM: "SkyMap",
  SKY: "Skycoin",
  SLX: "Slate",
  SLM: "SlimCoin",
  SLING: "Sling Coin",
  RBTC: "Smart Bitcoin",
  SIFT: "Smart Investment Fund Token",
  POD: "Smart League",
  TASH: "Smart Trip Platform",
  VALOR: "Smart Valor",
  "SMART*": "SmartBillions",
  SMART: "SmartCash",
  SMC: "SmartCoin",
  SLST: "SmartLands",
  "SMT*": "SmartMesh",
  SMLY: "SmileyCoin",
  SMILO: "Smilo",
  SMOKE: "Smoke",
  SMF: "SmurfCoin",
  SNPC: "SnapCoin",
  SNIP: "SnipCoin",
  SNOV: "Snovio",
  XSG: "Snowgem",
  ONG: "SoMee.Social",
  SOAR: "Soarcoin",
  SLT: "Social Lending Network",
  SMAC: "Social Media Coin",
  SMT: "Social Media Market",
  SEND: "Social Send",
  SOCC: "SocialCoin",
  SG: "SocialGood",
  SREUR: "SocialRemit",
  XBOT: "SocialXbotCoin",
  SCL: "Sociall",
  SOIL: "SoilCoin",
  SOJ: "Sojourn Coin",
  SOL: "Sola",
  SDAO: "Solar DAO",
  SLR: "SolarCoin",
  CELL: "SolarFarm",
  SRX: "Solarex",
  SFC: "Solarflarecoin",
  XLR: "Solaris",
  SOLE: "SoleCoin",
  SOLID: "Solidified",
  "SCT*": "Soma",
  SONG: "Song Coin",
  SSD: "Sonic Screw Driver Coin",
  SOON: "SoonCoin",
  SPHTX: "SophiaTX",
  SNK: "Sosnovkino",
  SOUL: "SoulCoin",
  SPX: "Sp8de",
  SCASH: "SpaceCash",
  "SPC*": "SpaceChain",
  SPACE: "SpaceCoin",
  SPA: "SpainCoin",
  SPANK: "SpankChain",
  SPK: "SparksPay",
  SPEC: "SpecCoin",
  "SPX*": "Specie",
  XSPEC: "Spectre",
  SPEND: "Spend",
  SPHR: "Sphere Coin",
  XID: "Sphre AIR",
  SPIKE: "Spiking",
  SPC: "SpinCoin",
  "SPD*": "Spindle",
  SPKZ: "Spokkz",
  SPORT: "SportsCoin",
  SFT: "SportsFix",
  SPF: "SportyCo",
  SPOT: "Spotcoin",
  SPT: "Spots",
  SPOTS: "Spots",
  SPR: "Spreadcoin",
  SPRTZ: "SpritzCoin",
  SPRTS: "Sprouts",
  SQP: "SqPay",
  SQL: "Squall Coin",
  SQR: "Squeezer",
  XSI: "Stability Shares",
  SBC: "StableCoin",
  USDS: "StableUSD",
  DSLA: "Stacktical",
  STCN: "Stakecoin",
  XSN: "Stakenet",
  "STA*": "Stakers",
  STHR: "Stakerush",
  LABX: "Stakinglab",
  STALIN: "StalinCoin",
  STC: "StarChain",
  "STR*": "StarCoin",
  "STAR*": "StarCoin",
  "SRC*": "StarCredits",
  KST: "StarKST",
  STT: "Staramba",
  STAR: "Starbase",
  START: "StartCoin",
  STA: "Starta",
  STP: "StashPay",
  SQOIN: "StasyQ",
  SNT: "Status Network Token",
  STAX: "Staxcoin",
  XST: "StealthCoin",
  PNK: "SteamPunk",
  STEEM: "Steem",
  "SBD*": "Steem Backed Dollars",
  XLM: "Stellar",
  XTL: "Stellite",
  SCIA: "Stem Cell",
  STN: "Steneum Coin",
  STEPS: "Steps",
  SLG: "SterlingCoin",
  SPD: "Stipend",
  STIPS: "Stips",
  STOCKBET: "StockBet",
  SCC: "StockChain Coin",
  STQ: "Storiqa Token",
  STORJ: "Storj",
  SJCX: "StorjCoin",
  STORM: "Storm",
  STX: "Stox",
  STAK: "Straks",
  SISA: "Strategic Investments in Significant Areas",
  STRAT: "Stratis",
  SSH: "StreamSpace",
  STM: "Streamity",
  DATA: "Streamr DATAcoin",
  SHND: "StrongHands",
  SUT: "Suapp",
  "SUB*": "Subscriptio",
  SUB: "Substratum Network",
  SUCR: "Sucre",
  SGC: "Sudan Gold Coin",
  SGR: "Sugar Exchange",
  SUMO: "Sumokoin",
  SNC: "SunContract",
  SSTC: "SunShotCoin",
  SUP: "Supcoin",
  SBTC: "Super Bitcoin",
  SUPER: "SuperCoin",
  UNITY: "SuperNET",
  SEED: "Superbloom",
  M1: "SupplyShock",
  SPM: "Supreme",
  RMT: "SureRemit",
  SUR: "Suretly",
  SWA: "Swace",
  SWACH: "Swachhcoin",
  BUCKS: "SwagBucks",
  SWT: "Swarm City Token",
  SWM: "Swarm Fund",
  SWARM: "SwarmCoin",
  SWEET: "SweetStake",
  SWFTC: "SwftCoin",
  SWING: "SwingCoin",
  SCN: "Swiscoin",
  CHSB: "SwissBorg",
  "SRC**": "SwissRealCoin",
  SIC: "Swisscoin",
  SWTH: "Switcheo",
  SDP: "SydPakCoin",
  SYLO: "Sylo",
  SYNC: "SyncCoin",
  MFG: "SyncFab",
  SYC: "SynchroCoin",
  SYNCO: "Synco",
  SYNX: "Syndicate",
  AMP: "Synereo",
  SNRG: "Synergy",
  SNX: "Synthetix",
  SYS: "SysCoin",
  TBT: "T-BOT",
  TCX: "T-Coin",
  TZO: "TANZ\u014C",
  BAR: "TBIS token",
  TDFB: "TDFB",
  TFD: "TE-FOOD",
  TKY: "THEKEY Token",
  TTN: "TITA Project",
  TXM: "TMONEY",
  TOA: "TOA Coin",
  TPC: "TPCash",
  TRX: "TRON",
  XTROPTIONS: "TROPTIONS",
  TTV: "TV-TWO",
  TWISTR: "TWIST",
  TTU: "TaTaTu",
  TCHN: "Tachain",
  TAG: "TagCoin",
  TAJ: "TajCoin",
  TAK: "TakCoin",
  TKLN: "Taklimakan",
  TALAO: "Talao",
  TLNT: "Talent Token",
  TCOIN: "Talenthon",
  TAL: "Talentico",
  TAM: "TamaGucci",
  XTO: "Tao",
  TTT: "Tap Project",
  TAP: "TappingCoin",
  TGT: "TargetCoin",
  TAT: "Tatiana Coin",
  TSE: "TattooCoin",
  TEC: "TeCoin",
  TCHB: "Teachers Blockchain",
  TEAM: "TeamUP",
  TECH: "TechCoin",
  THS: "TechShares",
  TEK: "TekCoin",
  TEL: "Telcoin",
  GRAM: "Telegram Open Network",
  TELL: "Tellurion",
  PAY: "TenX",
  TENNET: "Tennet",
  TENZ: "Tenzorum",
  LED: "Terawatt",
  TERN: "Ternio",
  TRN: "Ternion",
  TVA: "Terra Virtua",
  TRC: "TerraCoin",
  TECO: "TerraEcoCoin",
  TGN: "TerraGreen",
  TER: "TerraNovaCoin",
  TESLA: "TeslaCoilCoin",
  TES: "TeslaCoin",
  USDT: "Tether",
  TRA: "Tetra",
  XTZ: "Tezos",
  THNX: "ThankYou",
  "0xDIARY": "The 0xDiary Token",
  ABYSS: "The Abyss",
  EFX: "The EFFECT Network",
  TFC: "The Freedom Coin",
  GOVT: "The Government Network",
  THC: "The Hempcoin",
  SUNEX: "The Sun Exchange",
  XVE: "The Vegan Initiative",
  CHIEF: "TheChiefCoin",
  "GCC*": "TheGCCcoin",
  VIG: "TheVig",
  TCR: "Thecreed",
  MAY: "Theresa May Coin",
  THETA: "Theta",
  TAGR: "Think And Get Rich Coin",
  THRT: "ThriveToken",
  TSC: "ThunderStake",
  TIA: "Tianhe",
  TBRS: "Tiberius",
  TDX: "Tidex Token",
  TNT: "Tierion",
  TIE: "Ties Network",
  TCH: "TigerCash",
  TGC: "TigerCoin",
  TIG: "Tigereum",
  XTC: "TileCoin",
  BILL: "TillBilly",
  TIME: "Time",
  TNB: "Time New Bank",
  TME: "Timereum",
  TMC: "TimesCoin",
  TIMI: "Timicoin",
  "TIO*": "Tio Tour Guides",
  TIP: "Tip Blockchain",
  TTC: "TitCoin",
  TITAN: "Titan",
  TBAR: "Titanium BAR",
  TIT: "TittieCoin",
  "TMT*": "ToTheMoon",
  TODAY: "TodayCoin",
  TKD: "Tokedo",
  TAAS: "Token as a Service",
  TKN: "TokenCard",
  TCT: "TokenClub",
  TDS: "TokenDesk",
  "TPAY*": "TokenPay",
  ACE: "TokenStars",
  TEAMT: "TokenStars TEAM Token",
  AIRE: "Tokenaire",
  TBX: "Tokenbox",
  TEN: "Tokenomy",
  TGTC: "Tokensgate",
  TKS: "Tokes",
  TKA: "Tokia",
  TOK: "TokugawaCoin",
  TOKC: "Tokyo Coin",
  TOM: "Tomahawkcoin",
  TBL: "Tombola",
  TOMO: "TomoChain",
  TOPC: "Topchain",
  TOR: "TorCoin",
  TOT: "TotCoin",
  TRET: "Tourist Review",
  BBC: "TraDove B2BCoin",
  MTN: "TrackNetToken",
  TRCT: "Tracto",
  TXP: "Trade Pharma Network",
  TIOX: "Trade Token X",
  TIO: "Trade.io",
  EXTP: "TradePlace",
  TDZ: "Tradelize",
  TRAID: "Traid",
  TRAK: "TrakInvest",
  TX: "Transfer",
  TBCX: "TrashBurn",
  AVALA: "Travala",
  TRV: "Travel Coin",
  TT: "TravelChain",
  TLT: "Travelertoken",
  TRF: "Travelflex",
  TRAVEL: "Travelvee",
  "TMT**": "Traxia Membership Token",
  "TOT*": "Trecento Blockchain Capital",
  TREX: "TreeBlock",
  TZC: "TrezarCoin",
  FORCE: "TriForce Tokens",
  TRIA: "Triaconta",
  TRI: "Triangles Coin",
  TRIBE: "TribeToken",
  TRICK: "TrickyCoin",
  TRDT: "Trident",
  GPS: "Triffic",
  ID: "TrigID",
  TRIG: "Trigger",
  TIIM: "TriipMiles",
  TNC: "Trinity Network Credit",
  TRIO: "Tripio",
  TRIP: "Trippki",
  TRVC: "Trivecoin",
  TRVR: "Trivver",
  TRW: "Triwer",
  TPG: "Troll Payment",
  "TKN*": "TrollTokens",
  TROLL: "Trollcoin",
  TRK: "TruckCoin",
  TRCK: "Truckcoin",
  TRUE: "True Chain",
  TFL: "True Flip Lottery",
  TUSD: "True USD",
  TDP: "TrueDeck",
  TGAME: "TrueGame",
  TIC: "TrueInvestmentCoin",
  TRUMP: "TrumpCoin",
  TRST: "TrustCoin",
  TRUST: "TrustPlus",
  TTB: "TrustaBit",
  FLEX: "TrustedCars FLEX",
  WHO: "Truwho",
  TYM: "Tryvium",
  TLP: "TulipCoin",
  TUR: "Turron",
  TRTL: "TurtleCoin",
  TUT: "Tutellus",
  TRT: "TuurnT",
  TWLV: "Twelve Coin",
  TWC: "Twilight",
  TWIST: "TwisterCoin",
  UUU: "U Network",
  UCASH: "U.CASH",
  UCN: "UC Coin",
  UCOINT: "UCOIN",
  UCT: "UCOT",
  UFO: "UFO Coin",
  HVE: "UHIVE",
  UMK: "UMKA",
  UNX: "UNEOX",
  XUP: "UPcoin",
  UR: "UR",
  URX: "URANIUMX",
  USAT: "USAT",
  USCOIN: "USCoin",
  USDC: "USD Coin",
  USDCT: "USDCT",
  USOAMIC: "USOAMIC",
  UBC: "Ubcoin",
  UBEX: "Ubex",
  UBQ: "Ubiq",
  UBIQ: "Ubiqoin",
  U: "Ucoin",
  USC: "Ultimate Secure Cash",
  UTC: "UltraCoin",
  XUN: "UltraNote",
  ULTC: "Umbrella",
  UMC: "Umbrella Coin",
  UNC: "UnCoin",
  UNAT: "Unattanium",
  NBOX: "Unboxed",
  UNB: "UnbreakableCoin",
  UNF: "Unfed Coin",
  UBT: "UniBright",
  CANDY: "UnicornGo Candy",
  USX: "Unified Society USDEX",
  UNIFY: "Unify",
  UKG: "UnikoinGold",
  UNIQ: "Uniqredit",
  USDE: "UnitaryStatus Dollar",
  UAEC: "United Arab Emirates Coin",
  UEC: "United Emirates Coin",
  UTT: "United Traders Token",
  UBTC: "UnitedBitcoin",
  GOALS: "UnitedFans",
  UIS: "Unitus",
  UTNP: "Universa",
  UNIT: "Universal Currency",
  UMO: "Universal Molecule",
  URT: "Universal Recognition Token",
  URP: "Universal Reward Protocol",
  UNRC: "UniversalRoyalCoin",
  UNI: "Universe",
  UNO: "Unobtanium",
  UP: "UpToken",
  UFR: "Upfiring",
  UQC: "Uquid Coin",
  URALS: "Urals Coin",
  URB: "Urbit Data",
  URO: "UroCoin",
  USE: "Usechain Token",
  UETL: "Useless Eth Token Lite",
  UET: "Useless Ethereum Token",
  UTH: "Uther",
  UTL: "Utile Network",
  UTIL: "Utility Coin",
  OOT: "Utrum",
  UTK: "Utrust",
  UWC: "Uwezocoin",
  VIDT: "V-ID",
  VANIG: "VANIG",
  VANM: "VANM",
  VAR: "VARcrypt",
  VEGA: "VEGA",
  VNTY: "VENOTY",
  "VRX Token": "VIARIUM",
  VIBE: "VIBEHub",
  VIP: "VIP Tokens",
  VITE: "VITE",
  VIVO: "VIVO Coin",
  VLUX: "VLUX",
  VTOS: "VTOS",
  VTUUR: "VTUUR",
  VTRD: "VTradeExchange",
  VVI: "VV Coin",
  VLD: "Valid",
  VALID: "Validator Token",
  VAL: "Valorbit",
  VLR: "Valorem",
  VANY: "Vanywhere",
  VPRC: "VapersCoin",
  VAPOR: "Vaporcoin",
  VAD: "Varanida",
  VLTC: "VaultCoin",
  XVC: "Vcash",
  VTHO: "VeChainThor",
  VC: "Vecap",
  VET: "Vechain",
  VEC2: "VectorCoin 2.0",
  VLX: "Velox",
  VLT: "Veltor",
  VENA: "Vena Network",
  VNS: "Venus",
  VENUS: "VenusEnergy",
  VRA: "Verasity",
  VNT: "Veredictum",
  XVG: "Verge",
  VRC: "VeriCoin",
  VME: "VeriME",
  VRF: "Verifier",
  SPY: "Verifier",
  CRED: "Verify",
  VERI: "Veritaseum",
  VRTY: "Verity",
  VRM: "Verium",
  VRN: "Vernam",
  VRS: "Veros",
  VERSA: "Versa Token",
  VTC: "Vertcoin",
  VTX: "Vertex",
  VTEX: "Vertex",
  VTL: "Vertical",
  VEST: "VestChain",
  VST: "Vestarin",
  VEX: "Vexanium",
  VZT: "Vezt",
  VIA: "ViaCoin",
  VIAZ: "Viaz",
  VIB: "Viberate",
  VIT: "Vice Industry Token",
  VTM: "Victorieum",
  VTY: "Victoriouscoin",
  VIC: "Victorium",
  VID: "VideoCoin",
  VDO: "VidioCoin",
  VIDI: "Vidion",
  VIDY: "Vidy",
  VIEW: "Viewly",
  VEOT: "Viewo",
  VIN: "VinChain",
  VIOR: "ViorCoin",
  IDORU: "Vip2Fan",
  VIRAL: "Viral Coin",
  VUC: "Virta Unique Coin",
  VTA: "VirtaCoin",
  XVP: "VirtacoinPlus",
  VRT: "Virtual Reality Technology",
  VRH: "Virtual Rehab",
  VMC: "VirtualMining Coin",
  VISIO: "Visio",
  VNX: "VisionX",
  VITAE: "Vitae",
  VIU: "Viuly",
  OGO: "VogoV",
  VOISE: "Voise",
  VOL: "VolAir",
  VLTX: "Volentix",
  VLP: "Volpo",
  VTN: "Voltroon",
  VOOT: "VootCoin",
  VOT: "Votecoin",
  VOYA: "Voyacoin",
  VSX: "Vsync",
  VTR: "Vtorrent",
  VULC: "Vulcano",
  W12: "W12 Protocol",
  W3C: "W3Coin",
  WAB: "WABnetwork",
  WIN: "WCoin",
  WETH: "WETH",
  WRL: "WHIRL",
  WMC: "WMCoin",
  WOM: "WOM",
  WOWX: "WOWX",
  WRT: "WRTcoin",
  WTXH: "WTX HUB",
  WU: "WULET",
  WABI: "WaBi",
  WGR: "Wagerr",
  WTC: "Waltonchain",
  WAN: "Wanchain",
  WAND: "WandX",
  "WRC*": "WarCoin",
  WARP: "WarpCoin",
  WASH: "WashingtonCoin",
  WUG: "WatchUGot",
  WMB: "WatermelonBlock",
  WAVES: "Waves",
  WCT: "Waves Community Token",
  WGO: "WavesGO",
  WNET: "Wavesnode.net",
  WAY: "WayCoin",
  WSX: "WeAreSatoshi",
  WBY: "WeBuy",
  WPR: "WePower",
  WT: "WeToken",
  WEALTH: "WealthCoin",
  WVR: "Weave",
  "WEB*": "Webchain",
  WEB: "Webcoin",
  WDX: "WeiDex",
  WELL: "Well",
  WLME: "Wellmee",
  WTL: "Welltrado",
  WMK: "Wemark",
  WEX: "Wexcoin",
  WHL: "WhaleCoin",
  AWT: "WhatsOnPic",
  WHEN: "WhenHub",
  WC: "WhiteCoin",
  XWC: "WhiteCoin",
  WIC: "Wi Coin",
  WIIX: "Wiix",
  WBB: "Wild Beast Coin",
  WILD: "Wild Crypto",
  WINS: "WinStars",
  WHN: "Windhan Energy",
  LIF: "Winding Tree",
  WINE: "WineCoin",
  WINGS: "Wings DAO",
  WINK: "Wink",
  WISC: "WisdomCoin",
  WSC: "WiserCoin",
  WSH: "Wish Finance",
  "WISH*": "WishFinance",
  WIT: "Witcoin",
  WLK: "Wolk",
  WOMEN: "WomenCoin",
  LOG: "Wood Coin",
  WBBC: "World Bit Bank",
  WCG: "World Crypto Gold",
  WGC: "World Gold Coin",
  XWT: "World Trade Funds",
  WOBTC: "WorldBTC",
  WDC: "WorldCoin",
  WOP: "WorldPay",
  WRC: "Worldcore",
  WPT: "Worldopoly",
  WAX: "Worldwide Asset eXchange",
  WBTC: "Wrapped Bitcoin",
  WYR: "Wyrify",
  WYS: "Wysker",
  XRED: "X Real Estate Development",
  XCASH: "X-CASH",
  XC: "X11 Coin",
  X2: "X2Coin",
  X8X: "X8Currency",
  CHI: "XAYA",
  XCZ: "XCOYNZ",
  XCO: "XCoin",
  XDE2: "XDE II",
  XDNA: "XDNA",
  XELS: "XELS Coin",
  XTN: "XEND token",
  XG: "XG Sports",
  XMX: "XMax",
  XOV: "XOVBank",
  XRP: "XRP",
  XBY: "XTRABYTES",
  XUEZ: "XUEZ",
  XXX: "XXXCoin",
  XYO: "XY Oracle",
  XNX: "XanaxCoin",
  XAU: "XauCoin",
  XAUR: "Xaurum",
  XCSH: "Xcash",
  XCEL: "XcelTrip",
  XCG: "Xchange",
  XNC: "XenCoin",
  XEN: "XenixCoin",
  XNN: "Xenon",
  XNB: "Xeonbit",
  MI: "XiaoMiCoin",
  XDCE: "XinFin Coin",
  XIOS: "Xios",
  XT3: "Xt3ch",
  XRBT: "Xtribe",
  YAY: "YAYcoin",
  YAC: "YAcCoin",
  YACHTCO: "Yachtco",
  YMC: "YamahaCoin",
  YMZ: "Yamzu",
  YBC: "YbCoin",
  YDY: "Ydentity",
  YEE: "Yee",
  YBT: "YellowBetter",
  YES: "YesCoin",
  YOC: "YoCoin",
  YOVI: "YobitVirtualCoin",
  YON: "YondoCoin",
  YSH: "Yoshi",
  U42: "You42",
  YOYOW: "Yoyow",
  YUM: "Yumerium",
  Z2: "Z2 Coin",
  ZAB: "ZABERcoin",
  ZAZA: "ZAZA",
  ZT: "ZB Global",
  ZCC: "ZCC Coin",
  ZEC: "ZCash",
  ZECD: "ZCashDarkCoin",
  ZCG: "ZCashGOLD",
  ZCL: "ZClassic",
  XZC: "ZCoin",
  ZEN: "ZEN",
  ZEPH: "ZEPHYR",
  ZINC: "ZINC",
  ZIX: "ZIX Token",
  ZLQ: "ZLiteQubit",
  ZMN: "ZMINE",
  ZNAQ: "ZNAQ",
  ZPR: "ZPER",
  ZSE: "ZSEcoin",
  ZEX: "Zaddex",
  ZAP: "Zap",
  ZAT: "ZatGo",
  ZYD: "ZayedCoin",
  ZXT: "Zcrypt",
  NZL: "Zealium",
  ZCO: "Zebi Coin",
  ZED: "ZedCoins",
  ZPT: "Zeepin",
  ZEEW: "Zeew",
  ZEIT: "ZeitCoin",
  ZEL: "Zelcash",
  ZP: "Zen Protocol",
  ZND: "Zenad",
  ZENI: "Zennies",
  ZNA: "Zenome",
  ZER: "Zero",
  ZCC1: "ZeroCarbon",
  "ZSC*": "ZeroState",
  ZEST: "ZestCoin",
  ZET2: "Zeta2Coin",
  ZET: "ZetaCoin",
  ZSC: "Zeusshield",
  ZUC: "Zeux",
  "ZCN*": "Zichain",
  ZBC: "Zilbercoin",
  ZLA: "Zilla",
  ZIL: "Zilliqa",
  ZIP: "Zipper",
  ZIPT: "Zippie",
  ZOI: "Zoin",
  ZNE: "ZoneCoin",
  ZOOM: "ZoomCoin",
  ZRC: "ZrCoin",
  ZUP: "Zupply Token",
  ZUR: "Zurcoin",
  ZUUM: "Zuum",
  AQU: "aQuest",
  AXPR: "aXpire",
  ELF: "aelf",
  AXC: "autoXchange",
  BPN: "beepnow",
  OX: "betbox",
  BITCNY: "bitCNY",
  BITGOLD: "bitGold",
  BITSILVER: "bitSilver",
  BITUSD: "bitUSD",
  CSQ: "cosquare",
  DCS: "deCLOUDs",
  DNT: "district0x",
  ECHT: "e-Chat",
  EBIT: "eBit",
  EBTC: "eBitcoin",
  EBST: "eBoost",
  ELTC2: "eLTC",
  LYQD: "eLYQD",
  DEM: "eMark",
  EMU: "eMusic",
  ePRX: "eProxy",
  EREAL: "eREAL",
  EMPR: "empowr",
  BLACK: "eosBLACK",
  EOSDAC: "eosDAC",
  XEP: "ephelants360",
  FDX: "fidentiaX",
  GCN: "gCn Coin",
  FFUEL: "getFIFO",
  HBE: "healthbank",
  ICHN: "i-chain",
  IBANK: "iBankCoin",
  DEAL: "iDealCash",
  ICE: "iDice",
  IETH: "iEthereum",
  RLC: "iEx.ec",
  ILT: "iOlite",
  ITU: "iTrue",
  IW: "iWallet",
  IXT: "iXledger",
  IMU: "imusify",
  ITM: "intimate.io",
  MCN: "mCoin",
  MVU: "meVu",
  MIBO: "miBoodle",
  MOOLYA: "moolyacoin",
  NOS: "nOS",
  redBUX: "redBUX",
  SUSD: "sUSD",
  SVD: "savedroid",
  SBA: "simplyBrand",
  UFT: "ufoodo",
  UGC: "ugChain",
  VSL: "vSlice",
  VTAG: "veriTAG Token",
  "WBTC*": "wBTC",
  OPET: "\xD5petFoundation"
};

// src/constants/routes.ts
var routes = {
  escrow: {
    dashboard: `${AppHostUrlMap.MAIN}/`,
    login: `${AppHostUrlMap.MAIN}/auth/login`,
    login2fa: `${AppHostUrlMap.MAIN}/auth/2fa`,
    register: `${AppHostUrlMap.MAIN}/auth/register`,
    forgotPassword: `${AppHostUrlMap.MAIN}/auth/reset-password`,
    authVerify2fa: `${AppHostUrlMap.MAIN}/auth/2fa/verify`,
    authVerifyEmail: `${AppHostUrlMap.MAIN}/auth/email/verify`,
    p2p: `${AppHostUrlMap.MAIN}/p2p`,
    wallet: `${AppHostUrlMap.MAIN}/wallet`,
    support: `${AppHostUrlMap.MAIN}/contact`,
    p2pTrades: `${AppHostUrlMap.MAIN}/p2p/trades`,
    p2pOrders: `${AppHostUrlMap.MAIN}/p2p/ads/orders`,
    p2pAds: `${AppHostUrlMap.MAIN}/p2p/ads`,
    remittance: `${AppHostUrlMap.MAIN}/remittance`,
    history: `${AppHostUrlMap.MAIN}/history`,
    rewards: `${AppHostUrlMap.MAIN}/rewards`,
    appBitcode: `${AppHostUrlMap.MAIN}/bitcode`,
    accountSettings: `${AppHostUrlMap.MAIN}/settings`,
    kycVerification: `${AppHostUrlMap.MAIN}/settings/kyc`,
    paymentSettings: `${AppHostUrlMap.MAIN}/settings/payment`
  },
  landing: {
    home: `${AppHostUrlMap.BITBARTER}/`,
    about: `${AppHostUrlMap.BITBARTER}/about`,
    support: `${AppHostUrlMap.BITBARTER}/support`,
    termOfUse: `${AppHostUrlMap.BITBARTER}/legal/termsOfUse`,
    amlPolicy: `${AppHostUrlMap.BITBARTER}/legal/amlPolicy`,
    privacyPolicy: `${AppHostUrlMap.BITBARTER}/legal/privacyPolicy`,
    cookiePolicy: `${AppHostUrlMap.BITBARTER}/legal/cookiePolicy`
  },
  bitcode: {
    home: `${AppHostUrlMap.BITCODE}/`,
    pay: `${AppHostUrlMap.BITCODE}/pay`
  }
};
var blogUrl = "https://blog.bitbarter.io";
var termsLink = "https://bitbarter.notion.site/BitBarter-Terms-of-Use-cb06431506e34576b0f890b84d0e28b5?pvs=4";
var telegramLink = "https://t.me/bitbarter_official";
var becomeMerchantLink = "https://forms.gle/h2SKWso7f6QWoha29";
var faqLink = "https://help.bitbarter.io/en/support/solutions";
var socialUrls = {
  twitter: "https://twitter.com/thebitbarter",
  facebook: "https://fb.me/bitbarter",
  telegram: "https://t.me/bitbarter_official",
  instagram: "https://instagram.com/thebitbarter",
  medium: "https://thebitbarter.medium.com",
  linkedIn: "https://linkedin.com/company/thebitbarter",
  blog: "https://blog.bitbarter.io",
  tiktok: "https://www.tiktok.com/@bitbarter"
};
var homeHeaderPaths = [
  {
    menu: "HOME" /* HOME */,
    title: "Home",
    href: `${AppHostUrlMap.BITBARTER}/`
  },
  {
    menu: "BITCODE" /* BITCODE */,
    title: "Bitcode",
    href: `${AppHostUrlMap.BITCODE}/`
  },
  {
    menu: "ABOUT" /* ABOUT */,
    title: "About Us",
    href: `${AppHostUrlMap.BITBARTER}/about`
  }
];
var homeFooterPaths = [
  {
    heading: "company",
    links: [
      {
        title: "about us",
        href: "/about",
        props: {}
      },
      {
        title: "contact us",
        href: "/contact",
        props: {}
      }
    ]
  },
  {
    heading: "service",
    links: [
      {
        title: "bitcode",
        href: routes.bitcode.home,
        props: {}
      },
      {
        title: "P2P Marketplace",
        href: "https://app.bitbarter.io/p2p",
        props: { external: true }
      },
      {
        title: "remittance services",
        href: "mailto:support@bitbarter.io",
        props: { external: true }
      },
      {
        title: "merchants application",
        href: "https://docs.google.com/forms/d/e/1FAIpQLScRTxbotUgQFLkbFCoOCm-3149UQbtG2EafZPfAaziVgtxX3A/viewform",
        props: { external: true }
      },
      {
        title: "institutional services",
        href: "mailto:support@bitbarter.io",
        props: { external: true }
      }
    ]
  },
  {
    heading: "support",
    links: [
      {
        title: "give us feedback",
        href: "https://help.bitbarter.io/en/support/tickets/new?ticket_form=feedback%2Fsuggestions",
        props: { external: true }
      },
      {
        title: "support center",
        href: "https://help.bitbarter.io/",
        props: { external: true }
      },
      {
        title: "submit ticket",
        href: "https://help.bitbarter.io/en/support/tickets/new",
        props: { external: true }
      },
      {
        title: "FAQs",
        href: "https://help.bitbarter.io/en/support/solutions",
        props: { external: true }
      }
    ]
  },
  {
    heading: "legal",
    links: [
      {
        title: "terms of use",
        href: "https://bitbarter.notion.site/BitBarter-Terms-of-Use-cb06431506e34576b0f890b84d0e28b5?pvs=4",
        props: { external: true }
      },
      {
        title: "privacy policy",
        href: "https://www.notion.so/bitbarter/BitBarter-Privacy-Policy-91698f4bfed04403bdb79e6ca210cc8c?pvs=4",
        props: { external: true }
      },
      {
        title: "cookie policy",
        href: "https://bitbarter.notion.site/BitBarter-Cookie-Policy-e0474c26b9d5455cbdd7a5636340bad3?pvs=4",
        props: { external: true }
      },
      {
        title: "merchant guild",
        href: "https://www.notion.so/bitbarter/BitBarter-Trading-Guide-bc636bfe51cf458ea25a68fd578902ee?pvs=4",
        props: { external: true }
      },
      {
        title: "P2P trading guide",
        href: "https://bitbarter.notion.site/BitBarter-Trading-Guide-bc636bfe51cf458ea25a68fd578902ee?pvs=4",
        props: { external: true }
      },
      {
        title: "P2P dispute guide",
        href: "https://bitbarter.notion.site/BitBarter-P2P-Dispute-Handling-Guide-e135a05d04d04901a8a13fd4989fdfa5?pvs=4",
        props: { external: true }
      }
    ]
  }
];

// src/constants/menuItems.ts
import {
  MenuBitcodeIconV2,
  MenuDashboardIconV2,
  MenuHistoryIconV2,
  MenuP2PIconV2,
  MenuRewardsIcon,
  MenuWalletIconV2
} from "icons";
var desktopMenuItems = [
  {
    key: "DASHBOARD" /* DASHBOARD */,
    title: "Dashboard",
    icon: MenuDashboardIconV2({}),
    path: routes.escrow.dashboard
  },
  {
    key: "WALLET" /* WALLET */,
    title: "Wallet",
    icon: MenuWalletIconV2({}),
    path: routes.escrow.wallet
  },
  {
    key: "BITCODE" /* BITCODE */,
    title: "Bitcode",
    icon: MenuBitcodeIconV2({}),
    path: routes.escrow.appBitcode
  },
  {
    key: "P2P_MARKET" /* P2P_MARKET */,
    title: "P2P",
    icon: MenuP2PIconV2({}),
    path: routes.escrow.p2p
  },
  {
    key: "HISTORY" /* HISTORY */,
    title: "History",
    icon: MenuHistoryIconV2({}),
    path: routes.escrow.history
  },
  {
    key: "REWARDS" /* REWARDS */,
    title: "Rewards",
    icon: MenuRewardsIcon({}),
    path: routes.escrow.rewards
  }
];

// src/constants/language.ts
var appName = "Bitbarter";
var appTagline = "Buy/Sell, Remit Crypto Safely";
var appMotto = "Simplifying Global Payment";
var payWithBitcodeAppName = `BitCode by BitBarter`;
var payWithBitcodeTagline = "Pay & Get Paid";
var aboutPageTitle = `About Us | ${appName}`;
var contactPageTitle = `Contact Us | ${appName}`;
var homeDefaultTitle = `${appTagline} | ${appMotto} | ${appName}`;
var bitcodeHomeTitle = `${payWithBitcodeTagline} | ${payWithBitcodeAppName}`;
var faqPageTitle = `Frequently Asked Questions | ${appName}`;
var termsOfUseTitle = `Terms of Use | ${appName}`;
var amlPolicyTitle = `AML/CFT Polict | ${appName}`;
var cookiePolicyTitle = `Cookie Policy | ${appName}`;
var appealHandlingTitle = `Appeal Handling Guild | ${appName}`;
var privacyPolicyTitle = `Privacy policy | ${appName}`;
var language = {
  appName,
  payWithBitcodeAppName,
  appTagline,
  payWithBitcodeTagline,
  homeDefaultTitle,
  bitcodeHomeTitle,
  aboutPageTitle,
  contactPageTitle,
  faqPageTitle,
  termsOfUseTitle,
  amlPolicyTitle,
  cookiePolicyTitle,
  appealHandlingTitle,
  privacyPolicyTitle
};

// src/constants/calendar.ts
var WeekdaysShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
var MonthFullNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
var MonthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

// src/constants/bitcode.ts
var bitcodeStatuses = [
  {
    label: "CANCELLED" /* CANCELLED */,
    key: "CANCELLED" /* CANCELLED */
  },
  {
    label: "PAID" /* PAID */,
    key: "PAID" /* PAID */
  },
  {
    label: "PENDING" /* PENDING */,
    key: "PENDING" /* PENDING */
  },
  {
    label: "PROCESSING" /* PROCESSING */,
    key: "PROCESSING" /* PROCESSING */
  },
  {
    label: "RECALLED" /* RECALLED */,
    key: "RECALLED" /* RECALLED */
  },
  {
    label: "SETTLED" /* SETTLED */,
    key: "SETTLED" /* SETTLED */
  }
];

// src/constants/app.ts
var DEFAULT_FIAT = "USD";
var LOGOUT_EVENT = "CLEAR_SESSION";
var TOKEN_HEADER_NAME = "x-auth-token";
var LOCAL_AUTH_COOKIE_NAME = "local-auth-token";
var REFRESH_CONFIG_HEADER = "x-auth-refresh";
var AUTH_TOKEN_HEADER = "x-auth-token";
var SKIP_INTERCEPTOR_ERROR = "x-skip-error-interceptor";
var MAX_UPLOAD_FILE_SIZE = 3;
var MAX_MULTI_FILES_COUNT = 2;
var SUPPORTED_IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png"
];

// src/constants/p2p.ts
var orderTypes = [
  {
    label: "BUY" /* BUY */,
    key: "BUY" /* BUY */
  },
  {
    label: "SELL" /* SELL */,
    key: "SELL" /* SELL */
  }
];
var orderStatuses = [
  {
    label: "CLOSED" /* CLOSED */,
    key: "CLOSED" /* CLOSED */
  },
  {
    label: "FILLED" /* FILLED */,
    key: "FILLED" /* FILLED */
  },
  {
    label: "LOCKED" /* LOCKED */,
    key: "LOCKED" /* LOCKED */
  },
  {
    label: "OPEN" /* OPEN */,
    key: "OPEN" /* OPEN */
  },
  {
    label: "PAUSED" /* PAUSED */,
    key: "PAUSED" /* PAUSED */
  }
];
var TRADE_TERM_CHARS = {
  MIN: 10,
  MAX: 150
};

// src/AuthCookie.ts
import Cookies from "universal-cookie";
var AuthCookie = class {
  constructor() {
    this.AUTH_COOKIE_KEY = "BITBARTER-EX-SESSION";
    this.AUTH_REFRESH_COOKIE_KEY = "BITBARTER-EX-SESSION-REFRESH";
    this.cookie = new Cookies(null, { path: "/" });
  }
  setAuthToken(token, expires) {
    this.cookie.set(this.AUTH_COOKIE_KEY, token, {
      expires: new Date(expires),
      domain: this.cookieDomain
    });
  }
  get authToken() {
    return this.cookie.get(this.AUTH_COOKIE_KEY);
  }
  setAuthRefreshToken(refreshToken, expires) {
    this.cookie.set(this.AUTH_REFRESH_COOKIE_KEY, refreshToken, {
      expires: new Date(expires)
    });
  }
  get refreshAuthToken() {
    return this.cookie.get(this.AUTH_REFRESH_COOKIE_KEY);
  }
  get cookieDomain() {
    const hostname = window.location.hostname;
    if (hostname === "localhost")
      return hostname;
    const hostParts = hostname.split(".");
    return `.${hostParts[hostParts.length - 2]}.${hostParts[hostParts.length - 1]}`;
  }
};
var AuthCookie_default = new AuthCookie();

// src/api/config.ts
import axios from "axios";
var AxiosInstance = axios.create({
  withCredentials: true,
  baseURL: apiConfig.restBaseUrl
});
var config_default = AxiosInstance;

// src/api/auth.ts
var getChannelChatToken = async (channel) => {
  const response = await config_default.get(`/auth/chat-token/${channel}`);
  return response == null ? void 0 : response.data;
};
var loginOrRegisterUser = async (loginRequest) => {
  const response = await clients_default.user.auth.authenticateUser(loginRequest);
  return response == null ? void 0 : response.data;
};
var verifyUserAuth = async (verifyRequestParams) => {
  const response = await clients_default.user.auth.verifyAuth(verifyRequestParams);
  return response == null ? void 0 : response.data;
};
var verifyUser2fa = async (verifyRequestParams) => {
  const response = await clients_default.user.auth.verifyAuth2fa(verifyRequestParams);
  return response == null ? void 0 : response.data;
};
var getAuthStatus = async () => {
  const response = await clients_default.user.auth.checkAuth();
  return response == null ? void 0 : response.data;
};
var resendLoginToken = async () => {
  const response = await clients_default.user.auth.resendAuthToken();
  return response == null ? void 0 : response.data;
};
var refreshUserAuth = async () => {
  const response = await clients_default.user.auth.refreshAuthToken({
    headers: {
      [REFRESH_CONFIG_HEADER]: true,
      [SKIP_INTERCEPTOR_ERROR]: true
    }
  });
  return response == null ? void 0 : response.data;
};
var logout = async () => {
  try {
    await clients_default.user.auth.logout({
      headers: { [SKIP_INTERCEPTOR_ERROR]: true }
    });
  } catch (e) {
  }
};

// src/api/interceptors.ts
var UpdateTokenOnRequest = (config) => {
  config.headers = config.headers || {};
  const token = !!config.headers[REFRESH_CONFIG_HEADER] ? AuthCookie_default.refreshAuthToken : AuthCookie_default.authToken;
  if (token) {
    config.headers[AUTH_TOKEN_HEADER] = token;
  }
  delete config.headers[REFRESH_CONFIG_HEADER];
  return config;
};
var OnResponseError = async (err) => {
  var _a, _b;
  const requestHeaders = ((_a = err == null ? void 0 : err.config) == null ? void 0 : _a.headers) || {};
  if (((_b = err == null ? void 0 : err.response) == null ? void 0 : _b.status) === 401 && !requestHeaders[SKIP_INTERCEPTOR_ERROR]) {
    try {
      const result = await refreshUserAuth();
      if (result.authToken) {
        AuthCookie_default.setAuthToken(result.authToken, result.expiresAt);
      }
      if (result.refreshAuthToken) {
        AuthCookie_default.setAuthRefreshToken(result.refreshAuthToken, result.refreshExpiresAt);
      }
      return new Promise((resolve, reject) => {
        axios2(err.config).then(resolve).catch(reject);
      });
    } catch (e) {
      window && window.dispatchEvent(new Event(LOGOUT_EVENT));
    }
  } else {
    return Promise.reject(err);
  }
};
var SaveTokenOnResponse = async (response) => {
  const result = response.data;
  if (result.authToken && result.expiresAt) {
    AuthCookie_default.setAuthToken(result.authToken, result.expiresAt);
  }
  if (result.refreshAuthToken && result.refreshExpiresAt) {
    AuthCookie_default.setAuthRefreshToken(result.refreshAuthToken, result.refreshExpiresAt);
  }
  return response;
};

// src/api/clients.ts
var axiosInstance = axios3.create();
axiosInstance.interceptors.response.use(SaveTokenOnResponse, OnResponseError);
axiosInstance.interceptors.request.use(UpdateTokenOnRequest, Promise.reject);
axiosInstance.interceptors.response.use((response) => response, OnResponseError);
var createApiClient = (Client, basePath, config) => {
  return new Client(config, basePath || apiConfig.exchangeGatewayBasePath, axiosInstance);
};
var authClient = createApiClient(UsersAuthClient);
var userClient = createApiClient(UsersAccountsClient);
var userSettingsClient = createApiClient(UsersSettingsClient);
var userSecurityClient = createApiClient(UsersSecuritiesClient);
var webhookConfigs = createApiClient(WebhookConfigsClient);
var webhookEvents = createApiClient(WebhookEventsClient);
var rewardsReferrals = createApiClient(RewardsReferralsClient);
var rewardsEarnings = createApiClient(RewardsEarningsClient);
var assetConfig = createApiClient(AssetConfigsConfigsClient);
var coreUtilsClient = createApiClient(CoreUtilsClient, apiConfig.coreAssetConfigsBaseUrl);
var apiClients = {
  configs: {
    asset: assetConfig,
    core: {
      utils: coreUtilsClient
    }
  },
  webhook: {
    events: webhookEvents,
    configs: webhookConfigs
  },
  rewards: {
    referrals: rewardsReferrals,
    earnings: rewardsEarnings
  },
  user: {
    auth: authClient,
    account: userClient,
    security: userSecurityClient,
    settings: userSettingsClient
  }
};
var clients_default = apiClients;

// src/api/asset-config.ts
var getBanks = async () => {
  const response = await clients_default.configs.asset.getBanks();
  return response.data.banks;
};
var getCountries = async () => {
  const response = await clients_default.configs.core.utils.getCountries();
  return response.data.countries;
};
var getCountryByCode = async (countryCode) => {
  const response = await clients_default.configs.core.utils.getCountryByCode(countryCode);
  return response.data;
};
var getTimezones = async () => {
  const response = await clients_default.configs.core.utils.getTimezones();
  return response.data.timezones;
};

// src/api/account.ts
var getUser = async () => {
  const response = await clients_default.user.account.getUserAccount();
  return response.data;
};
var setUserPhoneNumber = async (setPhoneParams) => {
  const response = await clients_default.user.account.setPhoneNumber(setPhoneParams);
  return response == null ? void 0 : response.data;
};
var verifyUserPhoneNumber = async (otpParams) => {
  return await clients_default.user.account.verifyPhoneNumber(otpParams);
};
var sendPhoneVerifyOtp = async () => {
  const response = await clients_default.user.account.sendVerifyPhoneToken();
  return response == null ? void 0 : response.data;
};
var getKycFlows = async (countryCode) => {
  var _a;
  const response = await clients_default.user.account.getIdentityKycFlows(countryCode);
  return ((_a = response == null ? void 0 : response.data) == null ? void 0 : _a.flows) || [];
};
var getAccountKyc = async () => {
  const response = await clients_default.user.account.getIdentityKyc();
  return response == null ? void 0 : response.data;
};
var startKycVerification = async (params) => {
  const response = await clients_default.user.account.startIdentityKyc(params);
  return response == null ? void 0 : response.data;
};
var submitKycVerification = async () => {
  const response = await clients_default.user.account.submitIdentityKyc();
  return response == null ? void 0 : response.data;
};

// src/api/rewards.ts
var createReferralCodes = async (command) => {
  const response = await clients_default.rewards.referrals.createReferralCode(command);
  return response.data;
};
var getReferralCodeByCode = async (refCode) => {
  const response = await clients_default.rewards.referrals.getReferralCodeByCode(refCode);
  return response.data;
};
var getReferralCodes = async () => {
  const response = await clients_default.rewards.referrals.getReferralCodes();
  return response.data;
};
var getReferrals = async (opts) => {
  const response = await clients_default.rewards.referrals.getReferrals(opts == null ? void 0 : opts.referralCode);
  return response.data;
};
var getReferralsStat = async () => {
  const response = await clients_default.rewards.referrals.getReferralsStat();
  return response.data;
};
var getEarningById = async (id) => {
  const response = await clients_default.rewards.earnings.getEarningById(id);
  return response.data;
};
var getEarningStats = async () => {
  const response = await clients_default.rewards.earnings.getEarningStats();
  return response.data;
};
var getEarningTransferById = async (id) => {
  const response = await clients_default.rewards.earnings.getEarningTransferById(id);
  return response.data;
};
var getEarningTransfers = async (opts) => {
  const response = await clients_default.rewards.earnings.getEarningTransfers(opts == null ? void 0 : opts.status, opts == null ? void 0 : opts.statuses, opts == null ? void 0 : opts.sort, opts == null ? void 0 : opts.limit, opts == null ? void 0 : opts.page);
  return response.data;
};
var getEarnings = async () => {
  const response = await clients_default.rewards.earnings.getEarnings();
  return response.data;
};
var transferEarning = async (command) => {
  const response = await clients_default.rewards.earnings.transferEarning(command);
  return response.data;
};

// src/api/utils.ts
var handleRateConvert = async (params) => {
  const response = await config_default.get("/utils/convert", {
    params
  });
  return response.data;
};
var handleBestRate = async (params) => {
  const response = await config_default.get("/utils/best-rate", {
    params
  });
  return response.data;
};

// src/api/webhook.ts
var getWebhookConfigs = async () => {
  const response = await clients_default.webhook.configs.getUserWebhookConfig();
  return response.data;
};
var createOrUpdateWebhookConfigs = async (command) => {
  const response = await clients_default.webhook.configs.upsertWebhookConfig(command);
  return response.data;
};
var resetWebhookSecret = async () => {
  const response = await clients_default.webhook.configs.resetWebhookSecret();
  return response.data;
};
var getWebhookEvents = async (paginate) => {
  const response = await clients_default.webhook.events.getWebhookEvents(paginate == null ? void 0 : paginate.sort, paginate == null ? void 0 : paginate.page, paginate == null ? void 0 : paginate.limit);
  return response.data;
};

// src/api/security.ts
var setupAuthenticator = async () => {
  const response = await clients_default.user.security.setupAuthenticator();
  return response == null ? void 0 : response.data;
};
var verifyAuthenticator = async (token) => {
  await clients_default.user.security.verifyAuthenticator({ token });
};
var deleteAuthenticator = async (token) => {
  await clients_default.user.security.deleteAuthenticator(token);
};
var resetAuthenticator = async (emailToken, smsToken) => {
  const { data } = await clients_default.user.security.resetAuthenticator({ smsToken, emailToken });
  return data;
};
var activateLogin2fa = async () => {
  await clients_default.user.security.activateLogin2fa();
};
var disableLogin2fa = async (token) => {
  await clients_default.user.security.disableLogin2fa(token);
};

// src/api/settings.ts
var getWebPushKey = async () => {
  const response = await clients_default.user.settings.getWebPushKeys();
  return response == null ? void 0 : response.data;
};
var setupWebPush = async (endpoint, keyAuth, keyP256dh) => {
  await clients_default.user.settings.activateWebPush({ endpoint, keyAuth, keyP256dh });
};
var disableWebPush = async () => {
  await clients_default.user.settings.disableWebPush();
};
var initTelegramSetup = async () => {
  const response = await clients_default.user.settings.linkTelegram();
  return response == null ? void 0 : response.data;
};
var unlikeTelegramSetup = async () => {
  await clients_default.user.settings.unlinkTelegram();
};
var updatePreference = async (payload) => {
  await clients_default.user.settings.updatePreference(payload);
};

// src/theme/typography.ts
var fonts = {
  heading: "'Fira Sans', sans-serif",
  body: "'Fira Sans', sans-serif",
  fira: "'Fira Sans', sans-serif"
};
var fontSizes = {
  s24: "24px",
  s18: "18px",
  s16: "16px",
  s14: "14px"
};
var fontWeights = {
  regular: 400,
  semiBold: 600,
  bold: 700,
  extraBold: 800
};
var lineHeights = {};
var letterSpacings = {};

// src/theme/config.ts
var themeConfig = {
  cssVarPrefix: "barter",
  initialColorMode: "light",
  useSystemColorMode: false
};
var config_default2 = themeConfig;

// src/theme/colors.ts
var colors = {
  bred: "#FF3232",
  bred2: "#BB3C3C",
  bgreen: "#14EE3B",
  bgreen2: "#3EA55B",
  bgray2: "#5D5D5D",
  bblue: {
    900: "#15133b",
    800: "#24205f",
    700: "#131140",
    600: "#332d84",
    500: "#252161",
    400: "#423aa9",
    300: "#5c54c3",
    200: "#7f7ad1",
    150: "#4136E0",
    100: "#443D9D",
    50: "#ADB5BD",
    20: "#4BCDE5",
    15: "#97BBF0"
  },
  byellow: {
    50: "#fff2dc",
    100: "#fddbb2",
    150: "#AA6300",
    200: "#F0911E",
    250: "#FFF9DB",
    300: "#f5ad57",
    400: "#f19628",
    500: "#F0911E",
    600: "#d77c0e",
    700: "#a76108",
    800: "#794504",
    900: "#4a2800"
  },
  bgray: {
    900: "#20262c",
    800: "#394047",
    700: "#868E96",
    600: "#515962",
    500: "#A9AFB5",
    400: "#9da6ae",
    300: "#b8bfc6",
    200: "#ADB5BD",
    100: "#d3d9e0",
    50: "#EEF5F9"
  },
  bwhite: {
    900: "#D8D8D8",
    800: "#DCDCDC",
    700: "#E1E1E1",
    600: "#E5E5E5",
    500: "#E9E9E9",
    400: "#EEEEEE",
    300: "#F2F2F2",
    200: "#F6F6F6",
    100: "#FBFBFB",
    50: "#FFFFFF"
  },
  bblack: {
    200: "#010E1B",
    100: "#212529"
  },
  bneutral: {
    200: "#5D5D5D",
    100: "#999999"
  },
  bpurple: {
    500: "#502D80",
    200: "#8D6ABD",
    100: "#AF70EE"
  },
  bviolet: {
    50: "#FDFBFF"
  },
  homeGray: "#EDECF6",
  homeGrayLight: "#F6F6F8",
  bitcodeColor: "#122633",
  bitcodeYellow: "#E59330",
  bitcodeHomeGray: "#F6FAFC"
};
var colors_default = colors;

// src/theme/styles.ts
var styles = {
  global: (props) => ({
    body: {
      fontFamily: "fira",
      bg: props.colorMode === "dark" ? "bgray.800" : "bgray.50",
      color: props.colorMode === "dark" ? "white" : "#353535"
    },
    heading: {
      fontFamily: "fira",
      fontWeight: "extrabold"
    }
  })
};
var styles_default = styles;

// src/theme/layerStyles.ts
var layerStyles = {
  header: {
    bg: "bblue.500",
    color: "white",
    fontFamily: "fira",
    fontSize: "sm",
    letterSpacing: "0",
    lineHeight: "20px",
    margin: 0,
    padding: 0
  },
  ccFooter: {
    textAlign: "center",
    fontSize: "xs",
    fontFamily: "fira",
    lineHeight: "16px",
    letterSpacing: "0",
    color: "bblack.100"
  },
  link: {
    fontFamily: "fira",
    fontWeight: "semiBold",
    textTransform: "capitalize",
    _hover: {
      opacity: 0.9,
      textDecoration: "none"
    },
    _focus: {
      fontWeight: "extraBold",
      textDecoration: "none"
    }
  },
  linkHover: {
    opacity: 0.9,
    textDecoration: "none"
  },
  linkFocus: {
    fontWeight: "extraBold",
    textDecoration: "none"
  },
  accHeaderStyle: {
    color: "bblack.100",
    fontFamily: "fira",
    fontWeight: "bold",
    fontSize: "16px"
  },
  formLabel: {
    fontFamily: "fira",
    color: "bblack.100",
    lineHeight: "18px"
  },
  formTitle: {
    textAlign: "center",
    fontFamily: "fira",
    fontWeight: "extraBold",
    fontSize: "24px",
    textTransform: "capitalize",
    lineHeight: "30px",
    color: "bblack.100"
  },
  stepItem: {
    overflow: "hidden",
    alignItems: "center",
    position: "relative",
    boxSizing: "border-box",
    flex: "1",
    _after: {
      content: `""`,
      width: "100%",
      border: "1px dashed #4BCDE5"
    }
  },
  stepItemLast: {
    overflow: "hidden",
    alignItems: "center",
    position: "relative",
    boxSizing: "border-box"
  },
  stepText: {
    fontFamily: "fira",
    fontWeight: "500",
    width: "100%",
    textAlign: "center",
    size: "sm"
  },
  newDeviceText: {
    textAlign: "center",
    fontFamily: "fira",
    fontWeight: "500",
    maxW: "23rem",
    fontSize: "15px"
  },
  selectLight: {
    bg: "white",
    borderRadius: "md",
    variant: "filled",
    color: "bblue.500",
    iconColor: "bblue.500",
    iconSize: "small",
    fontFamily: "fira",
    fontSize: "small",
    fontWeight: "semibold",
    shadow: "lg"
  },
  statusAlertText: {
    color: "bgray.800",
    fontFamily: "fira",
    fontSize: "small"
  },
  dailyHeaderText: {
    color: "bblack.100",
    fontWeight: "bold",
    fontSize: "md",
    fontFamily: "fira"
  },
  recentTableTab: {
    color: "bblue.500",
    fontFamily: "fira",
    fontWeight: "500",
    fontSize: "18px",
    maxWidth: "15em"
  },
  transactionItem: {
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    fontFamily: "fira"
  },
  boxContainer: {
    position: "relative",
    bg: "white",
    borderRadius: "9px",
    width: "100%",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.06), 0 1px 3px 0 rgba(0,0,0,0.1)"
  },
  barterCard: {
    boxShadow: "base",
    borderRadius: "8",
    position: "relative",
    display: "block",
    bgColor: "white",
    mb: "5",
    px: "6",
    py: "4"
  },
  barterCardTitle: {
    fontFamily: "fira",
    fontWeight: "bold",
    letterSpacing: "normal",
    color: "bblack.100",
    fontSize: "16"
  },
  coinBox: {
    bg: "bblue.500",
    color: "#fff",
    borderRadius: "16px",
    boxShadow: "base",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "6",
    fontFamily: "fira"
  },
  coinBox2: {
    borderRadius: "8px",
    boxShadow: "base",
    padding: "1"
  },
  blackText: {
    fontFamily: "fira",
    color: "bblack.100"
  },
  greyText: {
    fontFamily: "fira",
    color: "bgray.700"
  },
  yellowIconBg: {
    backgroundColor: "#F0911E",
    boxShadow: "0 2px 6px 0 rgba(189,106,4,0.21)",
    borderRadius: "50%"
  },
  networkButton: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flex: "1",
    cursor: "pointer",
    backgroundColor: "#fff"
  },
  activeNetworkButton: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flex: "1",
    cursor: "pointer",
    backgroundColor: "#252161",
    color: "#fff"
  },
  tBoxWrapper: {
    cursor: "pointer",
    boxShadow: "inset 0 -1px 0 0 rgba(33,37,41,0.12)",
    _hover: {
      backgroundColor: "rgba(240,145,30,0.1)"
    }
  },
  maxBox: {
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    borderRadius: "5px",
    border: "2px solid #252161",
    fontFamily: "fira",
    color: "#252161",
    fontSize: "16px",
    fontWeight: "600",
    padding: "5px 10px",
    right: "5px",
    cursor: "pointer"
  },
  bitcodeBox: {
    cursor: "pointer",
    width: ["100%", "100%", "100%"],
    maxW: "306px",
    padding: "12px 15px",
    borderRadius: "11px",
    boxShadow: "0 2px 6px 0 rgba(189,106,4,0.21)",
    backgroundColor: "#F0911E",
    fontFamily: "fira",
    color: "#FFFFFF",
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "600",
    border: " 2px solid #F0911E",
    _hover: {
      backgroundColor: "#F0911E"
    }
  },
  modalText: {
    fontFamily: "fira",
    textAlign: "center",
    fontSize: "sm",
    fontWeight: "normal",
    color: "bblack.100"
  },
  stepIconRowFirst: {
    _before: {
      content: `""`,
      width: "50%",
      left: "50%",
      position: "absolute",
      border: "1px dashed #4BCDE5"
    }
  },
  stepIconRowLast: {
    _before: {
      content: `""`,
      width: "50%",
      left: "0",
      position: "absolute",
      border: "1px dashed #4BCDE5"
    }
  },
  stepIconRowFull: {
    _before: {
      content: `""`,
      width: "100%",
      left: "0",
      position: "absolute",
      border: "1px dashed #4BCDE5"
    }
  },
  authModalTab: {
    color: "bgray.700",
    fontFamily: "fira",
    fontWeight: "600",
    fontSize: "md",
    _focus: { boxShadow: "none", outline: "none" },
    _selected: { borderColor: "bblack.100", color: "bblue.500" }
  },
  selectStyle: {
    color: "bgray.700",
    border: "1px solid rgba(33,37,41,0.27)",
    boxShadow: "0 1px 3px 0 rgba(33,37,41,0.12)",
    height: "44px",
    cursor: "pointer",
    _focus: {
      outline: "none"
    }
  },
  homeAssetBox: {
    flex: "1",
    boxShadow: "dark-lg",
    rounded: "xl",
    bg: "white",
    px: "4",
    py: "3",
    mb: ["4", "4", "0", "0"]
  },
  getStartedLine: {
    width: "0.5",
    as: "div",
    height: "100%",
    mx: "auto",
    bgGradient: "linear(to-b, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.87) 100%)"
  },
  bitcodeHeaderText: {
    maxW: "150px",
    fontFamily: "fira",
    fontWeight: "600",
    fontSize: ["12px", "14px"],
    color: "bgray.700"
  },
  p2pHeadButton: {
    fontFamily: "fira",
    color: "bblue.500",
    maxW: "100%",
    fontWeight: "500"
  },
  p2pHeadButtonActive: {
    fontFamily: "fira",
    color: "bblue.500",
    backgroundColor: "#E7E9ED",
    py: ["1", "1", "2"],
    paddingLeft: ["15px", "15px", "20px"],
    paddingRight: ["15px", "15px", "20px"],
    maxW: "100%"
  },
  barterInput: {
    borderRadius: "4px",
    borderColor: "bgray.200",
    _focus: { boxShadow: "none" },
    _hover: { borderColor: "bblue.500", boxShadow: "inherit" }
  }
};
var layerStyles_default = layerStyles;

// src/theme/index.ts
import { extendTheme } from "@chakra-ui/react";
var themeFonts = fonts;
var themeConfig2 = config_default2;
var themeColors = colors_default;
var themeFontSizes = fontSizes;
var themeFontWeights = fontWeights;
var themeLineHeights = lineHeights;
var themeLetterSpacings = letterSpacings;
var chackraCustomeTheme = extendTheme({
  config: config_default2,
  styles: styles_default,
  colors: colors_default,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  layerStyles: layerStyles_default
});

// src/hooks/useChat.ts
import { useEffect, useState } from "react";
var useChat = () => {
  const [senderChat, setSenderChat] = useState("");
  const [chatList, setChatList] = useState([]);
  useEffect(() => {
  }, [chatList]);
  return {
    senderChat,
    setSenderChat,
    chatList,
    setChatList
  };
};

// src/hooks/useForm.ts
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
function useFormWithSchema(schema, useFormProps) {
  return useForm({ ...useFormProps, resolver: yupResolver(schema) });
}
function useFormWithSchemaBuilder(schemaBuilder, useFormProps) {
  return useForm({ ...useFormProps, resolver: yupResolver(schemaBuilder(Yup)) });
}

// src/hooks/useNProgress.ts
import { useEffect as useEffect2 } from "react";
import { useRouter } from "next/router";
import NProgress from "nprogress";
var useNProgress = () => {
  const router = useRouter();
  useEffect2(() => {
    const handleStart = () => {
      NProgress.start();
    };
    const handleStop = () => {
      NProgress.done();
    };
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);
    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router]);
};

// src/hooks/useRouteProp.ts
import { useEffect as useEffect3, useState as useState2 } from "react";
import { useRouter as useRouter2 } from "next/router";
var useRouteProp = (key) => {
  const router = useRouter2();
  const [prop, setProp] = useState2();
  useEffect3(() => {
    if (router.isReady) {
      const _val = router == null ? void 0 : router.query[key];
      setProp(_val);
    }
  }, [router, key]);
  return prop;
};

// src/hooks/useCountryCode.ts
import { useState as useState3, useEffect as useEffect4 } from "react";
var useCountryCode = (country) => {
  const [selectedCountry, setSelectedCountry] = useState3(country);
  const [countryCode, setCountryCode] = useState3(getCountryTelCode(country) || "");
  useEffect4(() => {
    setSelectedCountry(country);
    setCountryCode(getCountryTelCode(country));
  }, [country]);
  const onCountryChange = (e) => {
    const { value } = e.target;
    setCountryCode(value);
    setSelectedCountry(value);
  };
  return { countryCode, selectedCountry, onCountryChange };
};

// src/hooks/useStickyStorage.ts
import { useEffect as useEffect5, useState as useState4 } from "react";
var useStickyStorage = (key, defaultVal) => {
  const [value, setValue] = useState4();
  useEffect5(() => {
    const _defaultVal = window.localStorage.getItem(key) ?? defaultVal;
    if (_defaultVal !== null && _defaultVal !== void 0) {
      setValue(_defaultVal);
    }
  }, []);
  useEffect5(() => {
    const stickyValue = window.localStorage.getItem(key);
    if (stickyValue !== null && stickyValue !== void 0) {
      try {
        const _value = JSON.parse(stickyValue);
        setValue(_value);
      } catch (error3) {
        setValue(stickyValue);
      }
    }
  }, [key]);
  useEffect5(() => {
    if (typeof value !== "undefined") {
      const _val = typeof value === "string" ? value : JSON.stringify(value);
      window.localStorage.setItem(key, _val);
    }
  }, [key, value]);
  return [value ?? defaultVal, setValue];
};

// src/cache/index.ts
import cache from "memory-cache";

// src/cache/LocalCookie.ts
import Cookies2 from "universal-cookie";
var LocalCookie = class {
  authTokenCookie() {
    return new Cookies2(null, { path: "/" });
  }
};
var LocalCookie_default = new LocalCookie();

// src/cache/index.ts
var getFromCache = (key) => {
  return cache.get(key);
};
var keepInCache = (key, data, expiresAfter, callback) => {
  const _expireAfter = expiresAfter ? expiresAfter * 60 * 1e3 : 24 * 60 * 60 * 1e3;
  cache.put(key, data, _expireAfter, callback);
  return data;
};

// src/redux/reducers/index.ts
var reducers_exports = {};
__export(reducers_exports, {
  addPhoneNumber: () => addPhoneNumber,
  addressUpdate: () => addressUpdate,
  ads: () => ads,
  appConfigs: () => appConfigs,
  assetAddresses: () => assetAddresses,
  auth: () => auth,
  auth2fa: () => auth2fa,
  bitcodeFee: () => bitcodeFee,
  bitcodeInvoiceDetailsRequest: () => bitcodeInvoiceDetailsRequest,
  bitcodePaymentRequest: () => bitcodePaymentRequest,
  bitcodeRecallVoucher: () => bitcodeRecallVoucher,
  bitcodeRedeemVoucher: () => bitcodeRedeemVoucher,
  bitcodeResendInvoice: () => bitcodeResendInvoice,
  bitcodeResendVoucher: () => bitcodeResendVoucher,
  bitcodeSendVoucher: () => bitcodeSendVoucher,
  bitcodeSettlementRequest: () => bitcodeSettlementRequest,
  bitcodeVoucherDetailsRequest: () => bitcodeVoucherDetailsRequest,
  bitcodes: () => bitcodes,
  cancelTrade: () => cancelTrade,
  changePassword: () => changePassword,
  chatToken: () => chatToken,
  client: () => client,
  closeAds: () => closeAds,
  confirmP2PTradePayment: () => confirmP2PTradePayment,
  countries: () => countries,
  cryptoAssets: () => cryptoAssets,
  customerTradeQuery: () => customerTradeQuery,
  fiatAssets: () => fiatAssets,
  getInvoice: () => getInvoice,
  languages: () => languages,
  login: () => login,
  markTradeAsPaid: () => markTradeAsPaid,
  marketOrderQuery: () => marketOrderQuery,
  marketplace: () => marketplace,
  me: () => me,
  merchantAd: () => merchantAd,
  merchantAdsQuery: () => merchantAdsQuery,
  merchantOrderQuery: () => merchantOrderQuery,
  merchantProfile: () => merchantProfile,
  mktRate: () => mktRate,
  mktUpdate: () => mktUpdate,
  orders: () => orders,
  otpRequest: () => otpRequest,
  p2pTradePairs: () => p2pTradePairs,
  passwordResetReq: () => passwordResetReq,
  paymentChannels: () => paymentChannels,
  postAdRequest: () => postAdRequest,
  recentBlogPosts: () => recentBlogPosts,
  register: () => register,
  reqLoginOtp: () => reqLoginOtp,
  reqVerifyEmailOtp: () => reqVerifyEmailOtp,
  resetPasswordReq: () => resetPasswordReq,
  setDisplayCurrenyRequest: () => setDisplayCurrenyRequest,
  timezones: () => timezones,
  trade: () => trade,
  tradeViewAttr: () => tradeViewAttr,
  trades: () => trades,
  transaction: () => transaction,
  ui: () => ui,
  user: () => user,
  userAuth: () => userAuth,
  userP2pConfig: () => userP2pConfig,
  verifyEmailAddress: () => verifyEmailAddress,
  verifyPhoneNumber: () => verifyPhoneNumber,
  verifyPhoneOtpRequest: () => verifyPhoneOtpRequest,
  wallet: () => wallet,
  withdrawal: () => withdrawal,
  withdrawalFee: () => withdrawalFee
});

// src/redux/constants.ts
var RATE_UPDATE = "RATE_UPDATE";
var SET_P2P_FIAT = "SET_P2P_FIAT";
var SET_P2P_CRYPTO = "SET_P2P_CRYPTO";
var RESET_P2P_CONFIG = "RESET_P2P_CONFIG";
var GET_AUTH_USER = "GET_AUTH_USER";
var CREATE_ACCOUNT = "REGISTER_ACCOUNT";
var INIT_LOGIN = "INIT_LOGIN";
var REFRESH_LOGIN = "REFRESH_LOGIN";
var VERIFY_LOGIN = "VERIFY_LOGIN";
var SEND_LOGIN_SMS = "SEND_LOGIN_SMS";
var INIT_PASSWORD_RESET = "INIT_PASSWORD_RESET";
var RESEND_RESET_TOKEN = "RESEND_RESET_TOKEN";
var RESET_PASSWORD = "RESET_PASSWORD";
var CHANGE_PASSWORD = "CHANGE_PASSWORD";
var SET_LOGGED_IN = "SET_LOGGED_IN";
var CLEAR_SESSION = "CLEAR_SESSION";
var REQUEST_CHAT_TOKEN = "REQUEST_CHAT_TOKEN";
var LOGOUT = "LOGOUT";
var GET_ACCOUNT = "GET_ACCOUNT";
var GET_PROFILE = "GET_PROFILE";
var VERIFY_EMAIL = "VERIFY_EMAIL";
var SEND_VERIFY_EMAIL_OTP = "SEND_VERIFY_EMAIL_OTP";
var INIT_2FA_SETUP = "INIT_2FA_SETUP";
var VERIFY_2FA_SETUP = "VERIFY_2FA_SETUP";
var ADD_MOBILE_NUMBER = "ADD_MOBILE_NUMBER";
var VERIFY_PHONE_NUMBER = "VERIFY_PHONE_NUMBER";
var SEND_VERIFY_PHONE_OTP = "SEND_VERIFY_PHONE_OTP";
var UPDATE_PREFERENCE = "UPDATE_PREFERENCE";
var UPDATE_ADDRESS = "UPDATE_ADDRESS";
var REQUEST_OTP = "REQUEST_OTP";
var LOAD_FULL_ACCOUNT = "LOAD_FULL_ACCOUNT";
var ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
var UPDATE_PAYMENT_OPTION = "PAYMENT_OPTION";
var DELETE_A_PAYMENT_OPTION = "DELETE_A_PAYMENT_OPTION";
var ENABLE_LOGIN_2FA = "ENABLE_LOGIN_2FA";
var DISABLE_LOGIN_2FA = "DISABLE_LOGIN_2FA";
var ACCOUNT_LOCAL_UPDATE = "ACCOUNT_LOCAL_UPDATE";
var MXN_INIT_KYC_VERIFICATION = "MXN_INIT_KYC_VERIFICATION";
var MXN_SUBMIT_KYC_VERIFICATION = "MXN_SUBMIT_KYC_VERIFICATION";
var GET_BITCODE_INVOICE = "GET_BITCODE_INVOICE";
var SEND_BITCODE_INVOICE = "SEND_BITCODE_INVOICE";
var SETTLE_BITCODE_INVOICE = "SETTLE_BITCODE_INVOICE";
var SEND_BITCODE_VOUCHER = "SEND_BITCODE_VOUCHER";
var REDEEM_BITCODE_VOUCHER = "REDEEM_BITCODE_VOUCHER";
var RECALL_VOUCHER = "RECALL_VOUCHER";
var RESEND_VOUCHER = "RESEND_VOUCHER";
var RESEND_INVOICE = "RESEND_INVOICE";
var GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS";
var GET_VOUCHER_DETAILS = "GET_VOUCHER_DETAILS";
var GET_BITCODE_FEE = "GET_BITCODE_FEE";
var GET_TRANSACTION = "GET_TRANSACTION";
var GET_BITCODES = "GET_BITCODES";
var GET_WALLET = "GET_WALLET";
var GET_ASSET_ADDRESSES = "GET_ASSET_ADDRESSES";
var CHANGE_WALLET_CURRENCY = "CHANGE_WALLET_CURRENCY";
var GET_WITHDRAWAL_FEE = "GET_WITHDRAWAL_FEE";
var WITHDRAW_ASSET = "WITHDRAW_ASSET";
var GET_COUNTRIES = "GET_COUNTRIES";
var GET_LANGUAGES = "GET_LANGUAGES";
var GET_TIMEZONES = "GET_TIMEZONES";
var GET_FIAT_ASSETS = "GET_FIAT_ASSETS";
var GET_CRYPTO_ASSETS = "GET_CRYPTO_ASSETS";
var GET_PAYMENT_CHANNELS = "GET_PAYMENT_CHANNELS";
var GET_P2P_ASSET_TRADE_PAIRS = "GET_P2P_ASSET_TRADE_PAIRS";
var GET_CONFIGS = "GET_CONFIGS";
var GET_ASSET_RATE = "GET_ASSET_RATE";
var GET_RECENT_BLOG_POSTS = "GET_RECENT_BLOG_POSTS";
var FETCH_CLIENT_DATA = "FETCH_CLIENT_DATA";
var TOGGLE_BALANCE = "TOGGLE_BALANCE";
var TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU";
var MKT_UPDATE = "MKT_UPDATE";
var POST_MERCHANT_AD = "POST_MERCHANT_AD";
var GET_OFFRAMP_ORDER = "GET_OFFRAMP_ORDER";
var GET_ONRAMP_ORDER = "GET_ONRAMP_ORDER";
var GET_MERCHANT_ADS = "GET_MERCHANT_ADS";
var QUERY_SINGLE_AD = "QUERY_SINGLE_AD";
var CLOSE_MERCHANT_AD = "CLOSE_MERCHANT_AD";
var PAUSE_OR_PLAY_AD = "PAUSE_OR_PLAY_AD";
var POST_OFFRAMP_TRADE = "POST_OFFRAMP_TRADE";
var POST_ONRAMP_TRADE = "POST_ONRAMP_TRADE";
var RAISE_TRADE_DISPUTE = "RAISE_TRADE_DISPUTE";
var RESOLVE_TRADE_DISPUTE = "RESOLVE_TRADE_DISPUTE";
var CONFIRM_P2P_TRADE = "CONFIRM_P2P_TRADE";
var GET_ADVERTISED_RATE = "GET_ADVERTISED_RATE";
var MARK_TRADE_AS_PAID = "MARK_TRADE_AS_PAID";
var CANCEL_P2P_TRADE = "CANCEL_P2P_TRADE";
var RATE_TRADE = "RATE_TRADE";
var GET_AD_ORDERS = "GET_AD_ORDERS";
var TOGGLE_TRADE_VIEW = "TOGGLE_TRADE_VIEW";
var SET_TRADE_ORDER_ID = "SET_TRADE_ORDER_ID";
var UPDATE_MERCHANT_AD = "UPDATE_MERCHANT_AD";
var GET_MERCHANT_PROFILE = "GET_MERCHANT_PROFILE";
var GET_MARKETPLACE_ADS = "GET_MARKETPLACE_ADS";
var GET_CUSTOMER_TRADES = "GET_CUSTOMER_TRADES";
var QUERY_SINGLE_P2P_TRADE = "QUERY_SINGLE_P2P_TRADE";
var RESET_TRADE_QUERY = "RESET_TRADE_QUERY";
var RESET_ORDER_QUERY = "RESET_ORDER_QUERY";
var UPDATE_ORDER_QUERY = "UPDATE_ORDER_QUERY";
var UPDATE_TRADE_QUERY = "UPDATE_TRADE_QUERY";
var RESET_MERCHANT_ADS_QUERY = "RESET_MERCHANT_ADS_QUERY";
var UPDATE_MERCHANT_ADS_QUERY = "UPDATE_MERCHANT_ADS_QUERY";
var RESET_MERCHANT_ORDER_QUERY = "RESET_MERCHANT_ORDER_QUERY";
var UPDATE_MERCHANT_ORDER_QUERY = "UPDATE_MERCHANT_ORDER_QUERY";
var ENABLE_WEB_PUSH_MUTATION = "ENABLE_WEB_PUSH_MUTATION";
var DISABLE_WEB_PUSH_MUTATION = "DISABLE_WEB_PUSH_MUTATION";
var ACTIVATE_WEB_PUSH_MUTATION = "ACTIVATE_WEB_PUSH_MUTATION";
var ENABLE_TELEGRAM_MUTATION = "ENABLE_TELEGRAM_MUTATION";
var DISABLE_TELEGRAM_MUTATION = "DISABLE_TELEGRAM_MUTATION";
var ACTIVATE_TELEGRAM_MUTATION = "ACTIVATE_TELEGRAM_MUTATION";

// src/redux/helper.ts
import { abort, error, success } from "@redux-requests/core";

// src/exceptions/ReduxRequestException.ts
var ReduxRequestException = class extends Error {
  constructor(payload) {
    super(payload.message);
    this.code = payload.code;
    this.data = payload.data;
    this.statusCode = payload.statusCode;
  }
  toObject() {
    const { message, statusCode, code, data } = this;
    return { message, statusCode, code, data };
  }
};

// src/redux/helper.ts
var reduxRequestReset = (actionType) => `${actionType}_RESET`;
var updateDataKey = (actionType) => `${actionType}_UPDATE_DATA`;
function handleDataUpdateAction(actionType) {
  return (payload, uniqueKey) => ({
    type: updateDataKey(actionType),
    uniqueKey,
    payload
  });
}
function handleDataResetAction(actionType) {
  return () => ({
    type: reduxRequestReset(actionType)
  });
}
function createAxiosReducer(actionKey, resetKey) {
  const initialState = {
    data: null,
    pending: 0,
    error: null
  };
  return (state = initialState, action) => {
    switch (action.type) {
      case actionKey:
        return { ...state, pending: state.pending + 1 };
      case success(actionKey):
        return {
          ...state,
          error: null,
          data: action.response.data,
          pending: state.pending - 1
        };
      case error(actionKey):
        return { data: null, pending: state.pending - 1, error: action.error };
      case abort(actionKey):
        return { ...state, pending: state.pending - 1 };
      case reduxRequestReset(actionKey):
        return initialState;
      case (resetKey && reduxRequestReset(resetKey)):
        return initialState;
      default:
        return state;
    }
  };
}
var createGraphqlReducer = (actionKey, dataKey, resetKey) => {
  const initialState = {
    data: null,
    pending: 0,
    error: null
  };
  return (state = initialState, action) => {
    var _a;
    const { type, response, payload, uniqueKey, error: actionError } = action;
    switch (type) {
      case actionKey:
        return { ...state, pending: state.pending + 1 };
      case success(actionKey):
        const data = dataKey ? (response == null ? void 0 : response.data[dataKey]) || response.data : response.data;
        return {
          ...state,
          data,
          error: null,
          pending: state.pending - 1
        };
      case error(actionKey):
        const _error = actionError instanceof ReduxRequestException ? actionError.toObject() : actionError;
        return { data: null, pending: state.pending - 1, error: _error };
      case abort(actionKey):
        return { ...state, pending: state.pending - 1 };
      case updateDataKey(actionKey):
        if (!payload)
          return { ...state, data: null };
        if (((_a = state.data) == null ? void 0 : _a.meta) && payload && uniqueKey) {
          let __data = state.data.data;
          __data = __data.map((x) => x[uniqueKey] === payload[uniqueKey] ? { ...x, ...payload } : x);
          return { ...state, data: { ...state.data, data: __data } };
        }
        return { ...state, data: { ...state.data, ...payload } };
      case reduxRequestReset(actionKey):
        return initialState;
      case (resetKey && reduxRequestReset(resetKey)):
        return initialState;
      default:
        return state;
    }
  };
};
var createMultiKeyGraphqlReducer = (actionKeys, dataKeys, resetKey) => {
  const initialState = {
    data: null,
    pending: 0,
    error: null
  };
  return (state = initialState, action) => {
    var _a, _b;
    const actionMap = /* @__PURE__ */ new Map();
    const keys = actionKeys.map((key, i) => {
      const dataKey = dataKeys && (dataKeys[i] || void 0) || void 0;
      return [key, dataKey];
    });
    for (const [actionKey, dataKey] of keys) {
      const initData = { ...state, pending: state.pending + 1 };
      let successData = dataKey && ((_a = action == null ? void 0 : action.response) == null ? void 0 : _a.data[dataKey]) || ((_b = action == null ? void 0 : action.response) == null ? void 0 : _b.data) || null;
      successData = {
        ...state,
        data: successData,
        error: null,
        pending: state.pending - 1
      };
      const errorData = {
        data: null,
        pending: state.pending - 1,
        error: action.error
      };
      const abortData = { ...state, pending: state.pending - 1 };
      if (actionKey) {
        actionMap.set(actionKey, initData);
        actionMap.set(success(actionKey), successData);
        actionMap.set(error(actionKey), errorData);
        actionMap.set(abort(actionKey), abortData);
        actionMap.set(reduxRequestReset(actionKey), initialState);
      }
      resetKey && actionMap.set(reduxRequestReset(resetKey), initialState);
    }
    if (actionMap.has(action.type)) {
      return actionMap.get(action.type);
    }
    return state;
  };
};

// src/redux/reducers/p2pReducer.ts
var ads = createGraphqlReducer(GET_MERCHANT_ADS, "ads", "ads");
var merchantProfile = createGraphqlReducer(GET_MERCHANT_PROFILE, "merchantProfile", "merchantProfile");
var trades = createGraphqlReducer(GET_CUSTOMER_TRADES, "trades", "trades");
var orders = createGraphqlReducer(GET_AD_ORDERS, "orders", "orders");
var postAdRequest = createGraphqlReducer(POST_MERCHANT_AD, "postMerchantAd", "postMerchantAd");
var trade = createGraphqlReducer(QUERY_SINGLE_P2P_TRADE, "getTrade", "getTrade");
var cancelTrade = createGraphqlReducer(CANCEL_P2P_TRADE, "cancelP2PTrade", "cancelP2PTrade");
var markTradeAsPaid = createGraphqlReducer(MARK_TRADE_AS_PAID, "markp2pPaid", "markp2pPaid");
var confirmP2PTradePayment = createGraphqlReducer(CONFIRM_P2P_TRADE, "confirmP2PPayment", "confirmP2PPayment");
var closeAds = createGraphqlReducer(CLOSE_MERCHANT_AD, "toggleP2PAds", "toggleP2PAds");
var marketplace = createGraphqlReducer(GET_MARKETPLACE_ADS, "marketplace", "marketplace");
var merchantAd = createGraphqlReducer(QUERY_SINGLE_AD, "merchantAd", "merchantAd");

// src/redux/reducers/uiReducers.ts
var initState = {
  expandSideMenu: true,
  showBalance: true
};
var ui = (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_BALANCE:
      return { ...state, showBalance: !state.showBalance };
    case TOGGLE_SIDE_MENU:
      return { ...state, expandSideMenu: !state.expandSideMenu };
    case reduxRequestReset("ui"):
      return initState;
    default:
      return state;
  }
};

// src/redux/reducers/authReducer.ts
var me = createGraphqlReducer(GET_AUTH_USER, "me", "me");
var auth = createGraphqlReducer(REFRESH_LOGIN, "refreshAuth", "refreshAuth");
var register = createGraphqlReducer(CREATE_ACCOUNT, "createAccount", "createAccount");
var login = createGraphqlReducer(INIT_LOGIN, "login", "login");
var auth2fa = createGraphqlReducer(VERIFY_LOGIN, "verifyLogin", "verifyLogin");
var reqLoginOtp = createGraphqlReducer(SEND_LOGIN_SMS, "getLoginSmsOtp", "getLoginOtp");
var passwordResetReq = createGraphqlReducer(INIT_PASSWORD_RESET, "requestResetPassword", "requestPasswordReset");
var resetPasswordReq = createGraphqlReducer(RESET_PASSWORD, "resetPassword", "resetPassword");
var changePassword = createGraphqlReducer(CHANGE_PASSWORD, "updatePassword", "changePassword");
var chatToken = createGraphqlReducer(REQUEST_CHAT_TOKEN, "grantChatToken", "grantChatToken");

// src/redux/reducers/dataReducer.ts
import { error as error2 } from "@redux-requests/core";
var initMktUpdate = {
  data: {},
  error: null
};
var mktUpdate = (state = initMktUpdate, action) => {
  switch (action.type) {
    case MKT_UPDATE:
      return { ...state, data: action.payload };
    case error2(MKT_UPDATE):
      return { ...state, error: action.error };
    default:
      return state;
  }
};

// src/redux/reducers/rateReducers.ts
var initMktRate = {};
var mktRate = (state = initMktRate, action) => {
  if (action.type === RATE_UPDATE) {
    return {
      ...state,
      [`${action.payload.from}${action.payload.to}`]: action.payload.price
    };
  }
  return state;
};

// src/redux/reducers/utilReducers.ts
var appConfigs = createGraphqlReducer(GET_CONFIGS);
var cryptoAssets = createAxiosReducer(GET_CRYPTO_ASSETS, "cryptoAssets");
var fiatAssets = createAxiosReducer(GET_FIAT_ASSETS, "fiatAssets");
var countries = createAxiosReducer(GET_COUNTRIES, "countries");
var languages = createAxiosReducer(GET_COUNTRIES, "languages");
var timezones = createAxiosReducer(GET_TIMEZONES, "timezones");
var p2pTradePairs = createAxiosReducer(GET_P2P_ASSET_TRADE_PAIRS, "p2pTradePairs");
var paymentChannels = createAxiosReducer(GET_PAYMENT_CHANNELS, "paymentChannels");
var recentBlogPosts = createGraphqlReducer(GET_RECENT_BLOG_POSTS, "recentBlogPosts");

// src/redux/reducers/walletReducers.ts
var wallet = createGraphqlReducer(GET_WALLET, "wallet", "wallet");
var assetAddresses = createGraphqlReducer(GET_ASSET_ADDRESSES, "addresses", "addresses");
var setDisplayCurrenyRequest = createGraphqlReducer(CHANGE_WALLET_CURRENCY, "setDisplayCurrency", "setDisplayCurrency");
var withdrawalFee = createGraphqlReducer(GET_WITHDRAWAL_FEE, "withdrawalFee", "withdrawalFee");
var withdrawal = createGraphqlReducer(WITHDRAW_ASSET, "withdraw", "withdraw");
var transaction = createGraphqlReducer(GET_TRANSACTION, "transactions", "transactions");

// src/redux/reducers/accountReducers.ts
var user = createGraphqlReducer(LOAD_FULL_ACCOUNT);
var otpRequest = createGraphqlReducer(REQUEST_OTP, "requestOtp", "requestOtp");
var reqVerifyEmailOtp = createGraphqlReducer(SEND_VERIFY_EMAIL_OTP, "reqVerifyEmailOtp", "reqVerifyEmailOtp");
var verifyEmailAddress = createGraphqlReducer(VERIFY_EMAIL, "verifyEmailAddress", "verifyEmailAddress");
var addPhoneNumber = createGraphqlReducer(ADD_MOBILE_NUMBER, "addPhoneNumber", "addPhoneNumber");
var verifyPhoneOtpRequest = createGraphqlReducer(SEND_VERIFY_PHONE_OTP, "reqVerifyPhoneOtp", "reqVerifyPhoneOtp");
var verifyPhoneNumber = createGraphqlReducer(VERIFY_PHONE_NUMBER, "verifyPhoneNumber", "verifyPhoneNumber");
var addressUpdate = createGraphqlReducer(UPDATE_ADDRESS, "updateAddress", "updateAddress");

// src/redux/reducers/serviceReducers.ts
var client = createAxiosReducer(FETCH_CLIENT_DATA);

// src/redux/reducers/bitcodeReducers.ts
var getInvoice = createGraphqlReducer(GET_BITCODE_INVOICE, "getInvoice", "getInvoice");
var bitcodes = createGraphqlReducer(GET_BITCODES, "bitcodes", "bitcodes");
var bitcodeInvoiceDetailsRequest = createGraphqlReducer(GET_INVOICE_DETAILS, "getInvoiceDetails", "getInvoiceDetails");
var bitcodeVoucherDetailsRequest = createGraphqlReducer(GET_VOUCHER_DETAILS, "getVoucher", "getVoucher");
var bitcodeFee = createGraphqlReducer(GET_BITCODE_FEE, "getBitcodeFee", "getBitcodeFee");
var bitcodePaymentRequest = createGraphqlReducer(SEND_BITCODE_INVOICE, "sendInvoice", "sendInvoice");
var bitcodeSettlementRequest = createGraphqlReducer(SETTLE_BITCODE_INVOICE, "settleInvoice", "settleInvoice");
var bitcodeSendVoucher = createGraphqlReducer(SEND_BITCODE_VOUCHER, "sendVoucher", "sendVoucher");
var bitcodeRedeemVoucher = createGraphqlReducer(REDEEM_BITCODE_VOUCHER, "redeemVoucher", "redeemVoucher");
var bitcodeRecallVoucher = createGraphqlReducer(RECALL_VOUCHER, "recallVoucher", "recallVoucher");
var bitcodeResendVoucher = createGraphqlReducer(RESEND_VOUCHER, "resendVoucher", "resendVoucher");
var bitcodeResendInvoice = createGraphqlReducer(RESEND_INVOICE, "resendInvoice", "resendInvoice");

// src/redux/reducers/userConfigReducers.ts
var initP2pConfigState = {
  fiat: void 0,
  crypto: void 0
};
var userP2pConfig = (state = initP2pConfigState, action) => {
  switch (action.type) {
    case SET_P2P_FIAT:
      return { ...state, fiat: action.payload };
    case SET_P2P_CRYPTO:
      return { ...state, crypto: action.payload };
    case RESET_P2P_CONFIG:
      return initP2pConfigState;
    default:
      return state;
  }
};

// src/redux/reducers/marketplaceReducers.ts
var initTradeQuery = {
  page: 1,
  limit: 20,
  statuses: ["PENDING" /* PENDING */, "PAID" /* PAID */]
};
var initMarketOrderQuery = {
  orderType: "BUY" /* BUY */,
  page: 1,
  limit: 20
};
var initMerchantAdsQuery = {
  page: 1,
  limit: 20
};
var merchantOrderQuery = (state = initTradeQuery, action) => {
  switch (action.type) {
    case UPDATE_MERCHANT_ORDER_QUERY:
      return { ...state, ...action.payload };
    case RESET_MERCHANT_ORDER_QUERY:
      return { ...initTradeQuery, ...action.payload };
    default:
      return state;
  }
};
var customerTradeQuery = (state = initTradeQuery, action) => {
  switch (action.type) {
    case UPDATE_TRADE_QUERY:
      return { ...state, ...action.payload };
    case RESET_TRADE_QUERY:
      return { ...initTradeQuery, ...action.payload };
    default:
      return state;
  }
};
var marketOrderQuery = (state = initMarketOrderQuery, action) => {
  switch (action.type) {
    case UPDATE_ORDER_QUERY:
      return { ...state, ...action.payload };
    case RESET_ORDER_QUERY:
      return initMarketOrderQuery;
    default:
      return state;
  }
};
var merchantAdsQuery = (state = initMerchantAdsQuery, action) => {
  switch (action.type) {
    case UPDATE_MERCHANT_ADS_QUERY:
      return { ...state, ...action.payload };
    case RESET_MERCHANT_ADS_QUERY:
      return initMerchantAdsQuery;
    default:
      return state;
  }
};
var tradeViewAttr = (state = { showForm: false }, action) => {
  switch (action.type) {
    case SET_TRADE_ORDER_ID:
      return { ...state, orderId: action.payload };
    case TOGGLE_TRADE_VIEW:
      return { ...state, showForm: action.payload ?? !state.showForm };
    default:
      return state;
  }
};

// src/redux/reducers/userAuthReducer.ts
var initState2 = {
  loggedIn: false,
  userId: void 0
};
var userAuth = (state = initState2, action) => {
  switch (action.type) {
    case SET_LOGGED_IN:
      return { loggedIn: true, userId: action.payload };
    case CLEAR_SESSION:
      return initState2;
    default:
      return state;
  }
};

// src/redux/actions/index.ts
var actions_exports = {};
__export(actions_exports, {
  addPaymentMethod: () => addPaymentMethod,
  authLogin: () => authLogin,
  authLogout: () => authLogout,
  cancelP2pTrade: () => cancelP2pTrade,
  closeMerchantAd: () => closeMerchantAd,
  confirmP2pTrade: () => confirmP2pTrade,
  createAccount: () => createAccount,
  deletePaymentOption: () => deletePaymentOption,
  getAdOrders: () => getAdOrders,
  getAppConfigs: () => getAppConfigs,
  getAssetAddresses: () => getAssetAddresses,
  getAssetRate: () => getAssetRate,
  getAuthUser: () => getAuthUser,
  getBitcodeFee: () => getBitcodeFee,
  getBitcodes: () => getBitcodes,
  getClientData: () => getClientData,
  getCustomerTrades: () => getCustomerTrades,
  getInvoiceAction: () => getInvoiceAction,
  getInvoiceDetails: () => getInvoiceDetails,
  getMarketplaceAds: () => getMarketplaceAds,
  getMerchantAd: () => getMerchantAd,
  getMerchantAds: () => getMerchantAds,
  getMerchantProfile: () => getMerchantProfile,
  getOfframpTradeOrder: () => getOfframpTradeOrder,
  getOnrampTradeOrder: () => getOnrampTradeOrder,
  getRecentBlogPosts: () => getRecentBlogPosts,
  getTransactions: () => getTransactions,
  getVoucherDetails: () => getVoucherDetails,
  getWallet: () => getWallet,
  getWithdrawalFee: () => getWithdrawalFee,
  loadCountries: () => loadCountries,
  loadCryptoAssets: () => loadCryptoAssets,
  loadFiatAssets: () => loadFiatAssets,
  loadLanguages: () => loadLanguages,
  loadP2pTradePairs: () => loadP2pTradePairs,
  loadPaymentChannels: () => loadPaymentChannels,
  loadTimezones: () => loadTimezones,
  loadUser: () => loadUser,
  logonUser: () => logonUser,
  markP2pTradePaid: () => markP2pTradePaid,
  pauseOrPlayAd: () => pauseOrPlayAd,
  postMerchantAd: () => postMerchantAd,
  postOfframpTrade: () => postOfframpTrade,
  postOnrampTrade: () => postOnrampTrade,
  querySingleP2pTrade: () => querySingleP2pTrade,
  raiseDispute: () => raiseDispute,
  rateTrade: () => rateTrade,
  recallVoucher: () => recallVoucher,
  redeemVoucher: () => redeemVoucher,
  refreshAuth: () => refreshAuth,
  requestChatToken: () => requestChatToken,
  requestOtp: () => requestOtp,
  requestPasswordReset: () => requestPasswordReset,
  resendInvoice: () => resendInvoice,
  resendVoucher: () => resendVoucher,
  resetCustomerTradeQuery: () => resetCustomerTradeQuery,
  resetMarketOrderQuery: () => resetMarketOrderQuery,
  resetMerchantAdsQuery: () => resetMerchantAdsQuery,
  resetMerchantOrderQuery: () => resetMerchantOrderQuery,
  resetPassword: () => resetPassword,
  resetUserAccount: () => resetUserAccount,
  resetUserP2pConfig: () => resetUserP2pConfig,
  resetWallet: () => resetWallet,
  resolveTradeDispute: () => resolveTradeDispute,
  sendInvoice: () => sendInvoice,
  sendLoginSms: () => sendLoginSms,
  sendVoucher: () => sendVoucher,
  setDisplayCurrency: () => setDisplayCurrency,
  setMktData: () => setMktData,
  setMktError: () => setMktError,
  setTradeOrderId: () => setTradeOrderId,
  setUserP2pCrypto: () => setUserP2pCrypto,
  setUserP2pFiat: () => setUserP2pFiat,
  settleInvoice: () => settleInvoice,
  toggleShowBalance: () => toggleShowBalance,
  toggleSideMenu: () => toggleSideMenu,
  toggleTradeView: () => toggleTradeView,
  updateAccountAction: () => updateAccountAction,
  updateAddress: () => updateAddress,
  updateCustomerTradeQuery: () => updateCustomerTradeQuery,
  updateMarketOrderQuery: () => updateMarketOrderQuery,
  updateMerchantAd: () => updateMerchantAd,
  updateMerchantAdsQuery: () => updateMerchantAdsQuery,
  updateMerchantOrderQuery: () => updateMerchantOrderQuery,
  updateOneMerchantAd: () => updateOneMerchantAd,
  updatePassword: () => updatePassword,
  updatePaymentMethod: () => updatePaymentMethod,
  updateRate: () => updateRate,
  updateTradeData: () => updateTradeData,
  updateUserAccount: () => updateUserAccount,
  verifyLogin2fa: () => verifyLogin2fa,
  withdrawAsset: () => withdrawAsset
});

// src/graphql/queries/util.ts
import { gql } from "@redux-requests/graphql";
var GET_CONFIGS_QUERY = gql`
  query AppConfigs {
    __typename
    configs {
      fiats {
        code
        name
        symbol
        flagUrl
        decimals
        rounding
        nativeSymbol
        allowP2pTrade
        allowRemittance
        minimumTradeAmount
      }
      cryptoAssets {
        name
        symbol
        isToken
        hasWallet
        logoUrl
        isActive
        allowSwap
        assetRank
        allowP2pTrade
        allowRemittance
        displayDecimals
        roundingDecimals
        minimumTradeQuantity
        networks {
          depositFee
          withdrawalFee
          minimumWithdrawal
          allowWithdrawal
          network {
            name
            symbol
            currency
            logoUrl
            addressBase
          }
        }
      }
      countries {
        code
        iso3Code
        name
        flagUrl
        dialCode
        capital
        continentCode
        continentName
      }
      timezones {
        id
        label
        value
      }
      serviceFees {
        service
        feeRate
        isPercentage
        cap
      }
      paymentChannels {
        slug
        title
        isGlobal
        channelColor
        options {
          type
          name
          default
          iconUrl
          label
          match
          minimum
          maximum
          readonly
          required
          values
        }
        allowedCountryCodes
      }
      languages {
        code
        name
        flagUrl
      }
    }
    webPushKeys {
      publicKey
    }
    verificationFlows {
      name
      flowId
      provider
      isGlobal
      countries
      documentType
      verificationType
    }
  }
`;
var GET_ASSET_RATE_QUERY = gql`
  query CryptAssetRate($amount: Float!, $from: String!, $to: String!) {
    __typename
    getAssetRate(ConvertAssetData: { amount: $amount, from: $from, to: $to }) {
      rate
      value
    }
  }
`;
var GET_RECENT_BLOG_POSTS_QUERY = gql`
  query RecentBlogPosts {
    __typename
    recentBlogPosts {
      excerpt
      featuredImage
      publishedAt
      title
      url
    }
  }
`;

// src/graphql/queries/auth.ts
import { gql as gql2 } from "@redux-requests/graphql";
var GET_AUTH_USER_QUERY = gql2`
  query GetAuthUser {
    __typename
    me {
      uid
      email
      level
      phone
      roles
      status
      username
      verified
      activeOtp
      checkSecondFactor
      isEmailVerified
      isPhoneVerified
      createdAt
      updatedAt
    }
  }
`;

// src/graphql/queries/p2p.ts
import { gql as gql7 } from "@redux-requests/graphql";

// src/graphql/shared/common.ts
import { gql as gql3 } from "@redux-requests/graphql";
var PAGE_META = gql3`
  fragment PageMetaFields on PageMetaDto {
    limit
    page
    pages
    total
    hasNextPage
    hasPreviousPage
  }
`;
var PAYMENT_METHOD = gql3`
  fragment PaymentMethodFields on PaymentOptionDto {
    qrcode
    email
    bankName
    bankBranch
    optionId
    username
    accountName
    accountTag
    accountNumber
    bankAccSwiftcode
  }
`;
var CHANNEL_FORM_OPTION = gql3`
  fragment ChannelFormOptions on PaymentChannelInputDto {
    type
    values
    label
    name
    default
    required
    readonly
    minimum
    maximum
    match
    iconUrl
  }
`;
var PAYMENT_CHANNEL = gql3`
  fragment PaymentChannelFields on PaymentChannelDto {
    slug
    title
    isGlobal
    channelColor
    allowedCountryCodes
  }
`;
var FULL_PAYMENT_METHOD = gql3`
  fragment FullPaymentMethodFields on PaymentOptionWithChannelDto {
    qrcode
    email
    bankName
    bankBranch
    optionId
    username
    accountName
    accountTag
    accountNumber
    bankAccSwiftcode
  }
`;

// src/graphql/shared/p2p.ts
import { gql as gql4 } from "@redux-requests/graphql";
var TRADE_STAT = gql4`
  fragment TradeStatFields on TradeStatSubDto {
    completed
    takerRating
    completedAfter
  }
`;
var AD_MERCHANT = gql4`
  ${TRADE_STAT}
  fragment AdMerchantFields on OrderMerchantDto {
    id
    username
    nickname
    p2pTradeStats {
      ...TradeStatFields
    }
  }
`;
var TRADE_ORDER = gql4`
  fragment TradeOrderFields on TradeOrderDto {
    advertisedRate
    orderBookId
    orderType
    paymentWindow
  }
`;
var TRADE_USER = gql4`
  fragment TradeUserFields on TradeUserDto {
    id
    nickname
    username
  }
`;
var TRADE_DISPUTE = gql4`
  fragment TradeDisputeFields on TradeDisputeDto {
    title
    description
    evidences
  }
`;
var TRADE_PAYMENT_METHOD = gql4`
  fragment TradePaymentMethodFields on TradePaymentMethodDto {
    email
    qrcode
    username
    bankName
    bankBranch
    accountTag
    accountName
    accountNumber
    bankAccSwiftcode
  }
`;
var P2P_AD_ORDER = gql4`
  fragment AdViewFields on P2pOrderDto {
    fiat
    crypto
    orderType
    orderBookId
    maxQuantity
    minQuantity
    minOrderAmount
    maxOrderAmount
    availableQuantity
    advertisedRate
    paymentWindow
    quantity
    tradeTerms
    status
  }
`;
var P2P_AD_FULL_ORDER = gql4`
  fragment AdFullViewFields on MerchantAdDto {
    fiat
    crypto
    orderType
    orderBookId
    maxQuantity
    minQuantity
    filledQuantity
    minOrderAmount
    maxOrderAmount
    orderedQuantity
    availableQuantity
    advertisedRate
    paymentWindow
    lastFillDate
    tradeTerms
    createdAt
    updatedAt
    offerRate
    quantity
    status
    rateType
    rateMargin
    maximumRate
    minimumRate
  }
`;
var P2P_ORDER_TRADE = gql4`
  fragment OrderTradeFields on P2pTradeDto {
    amount
    tradeType
    offerRate
    tradeOrderId
    advertisedRate
    amountToTransfer
    crypto
    disputed
    fiat
    disputeFrom
    quantity
    quantityToTransfer
    quantityToDebit
    disputableAfter
    status
    expiresAt
    updatedAt
    createdAt
  }
`;

// src/graphql/shared/wallet.ts
import { gql as gql5 } from "@redux-requests/graphql";
var COMMON_WALLET_ADDRESS = gql5`
  fragment WalletAddressFields on WallletAddressDto {
    memo
    network
    address
    currency
  }
`;
var COMMON_WALLET_ASSET = gql5`
  fragment WalletAssetFields on WalletAssetDto {
    currency
    balance
    lockedBalance
    availableBalance
    merchantCollateral
  }
`;
var COMMON_WALLET = gql5`
  fragment WalletFields on AccountWalletDto {
    id
    status
    dailyLimit
    spentToday
    totalBalance
    displayCurrency
    totalLockedBalance
    totalAvailableBalance
  }
`;

// src/graphql/shared/account.ts
import { gql as gql6 } from "@redux-requests/graphql";
var COMMON_ACCOUNT = gql6`
  fragment AccountFields on AccountDto {
    id
    uid
    email
    level
    phone
    roles
    status
    verified
    username
    nickname
    activeOtp
    createdAt
    updatedAt
    isEmailVerified
    isPhoneVerified
    activatedWebPush
    activatedTelegram
    checkSecondFactor
  }
`;
var COMMON_PREFERENCE = gql6`
  fragment PreferenceFields on PreferenceDto {
    theme
    language
    timezone
    emailAlert
    fiatCurrency
    webPushNotification
    telegramNotification
  }
`;
var COMMON_ADDRESS = gql6`
  fragment AddressFields on ProfileAddressDto {
    city
    state
    street
    lineTwo
    zipCode
    countryCode
    apartmentNo
    createdAt
    updatedAt
  }
`;
var COMMON_PROFILE = gql6`
  fragment ProfileFields on ProfileDto {
    dob
    age
    gender
    firstName
    lastName
    otherNames
    fullName
    countryCode
    idKycStatus
    addressKycStatus
    completed
    createdAt
    updatedAt
  }
`;
var COMMON_IDENTITY = gql6`
  fragment IdentityFields on IdentityDto {
    type
    createdAt
    updatedAt
    identityNumber
  }
`;

// src/graphql/queries/p2p.ts
var P2P_ADS_QUERY = gql7`
  ${PAGE_META}
  ${AD_MERCHANT}
  ${P2P_AD_ORDER}
  ${PAYMENT_CHANNEL}
  ${CHANNEL_FORM_OPTION}
  ${FULL_PAYMENT_METHOD}
  query ViewP2pMarketplace(
    $fiat: String
    $crypto: String
    $amount: Float
    $quantity: Float
    $paymentChannel: String
    $orderType: OrderType!
    $limit: Int
    $page: Int
  ) {
    __typename
    marketplace(
      FilterArgs: {
        fiat: $fiat
        crypto: $crypto
        amount: $amount
        quantity: $quantity
        orderType: $orderType
        paymentChannel: $paymentChannel
      }
      PaginationArgs: { limit: $limit, page: $page }
    ) {
      data {
        ...AdViewFields
        merchant {
          ...AdMerchantFields
        }
        paymentChannels {
          ...PaymentChannelFields
          options {
            ...ChannelFormOptions
          }
        }
        paymentOptions {
          ...FullPaymentMethodFields
          channel {
            ...PaymentChannelFields
            options {
              ...ChannelFormOptions
            }
          }
        }
      }
      meta {
        ...PageMetaFields
      }
    }
  }
`;
var CUSTOMER_TRADES_QUERY = gql7`
  ${PAGE_META}
  ${TRADE_USER}
  ${TRADE_ORDER}
  ${P2P_ORDER_TRADE}
  ${PAYMENT_CHANNEL}
  ${CHANNEL_FORM_OPTION}
  ${TRADE_PAYMENT_METHOD}
  query CustomerTrades(
    $fiat: String
    $crypto: String
    $disputed: Boolean
    $fromDate: DateTime
    $status: TradeStatus
    $statuses: [TradeStatus!]
    $toDate: DateTime
    $tradeType: TradeType
    $limit: Int = 10
    $page: Int = 1
  ) {
    __typename
    trades(
      FilterArgs: {
        fiat: $fiat
        crypto: $crypto
        status: $status
        disputed: $disputed
        statuses: $statuses
        tradeType: $tradeType
        fromDate: $fromDate
        toDate: $toDate
      }
      PaginationArgs: { limit: $limit, page: $page }
    ) {
      data {
        ...OrderTradeFields
        channel {
          ...PaymentChannelFields
          options {
            ...ChannelFormOptions
          }
        }
        maker {
          ...TradeUserFields
        }
        taker {
          ...TradeUserFields
        }
        order {
          ...TradeOrderFields
        }
        buyerPaymentMethod {
          ...TradePaymentMethodFields
        }
        sellerPaymentMethod {
          ...TradePaymentMethodFields
        }
      }
      meta {
        ...PageMetaFields
      }
    }
  }
`;
var MERCHANT_ORDERS_QUERY = gql7`
  ${PAGE_META}
  ${TRADE_USER}
  ${TRADE_ORDER}
  ${PAYMENT_CHANNEL}
  ${P2P_ORDER_TRADE}
  ${CHANNEL_FORM_OPTION}
  ${TRADE_PAYMENT_METHOD}
  query MerchantOrders(
    $fiat: String
    $crypto: String
    $disputed: Boolean
    $fromDate: DateTime
    $status: TradeStatus
    $statuses: [TradeStatus!]
    $toDate: DateTime
    $tradeType: TradeType
    $limit: Int = 10
    $page: Int = 1
  ) {
    __typename
    orders(
      FilterArgs: {
        fiat: $fiat
        crypto: $crypto
        status: $status
        disputed: $disputed
        statuses: $statuses
        tradeType: $tradeType
        fromDate: $fromDate
        toDate: $toDate
      }
      PaginationArgs: { limit: $limit, page: $page }
    ) {
      data {
        ...OrderTradeFields
        channel {
          ...PaymentChannelFields
          options {
            ...ChannelFormOptions
          }
        }
        maker {
          ...TradeUserFields
        }
        taker {
          ...TradeUserFields
        }
        order {
          ...TradeOrderFields
          offerRate
        }
        buyerPaymentMethod {
          ...TradePaymentMethodFields
        }
        sellerPaymentMethod {
          ...TradePaymentMethodFields
        }
      }
      meta {
        ...PageMetaFields
      }
    }
  }
`;
var MERCHANT_ADS_QUERY = gql7`
  ${PAGE_META}
  ${AD_MERCHANT}
  ${PAYMENT_METHOD}
  ${PAYMENT_CHANNEL}
  ${P2P_AD_FULL_ORDER}
  ${CHANNEL_FORM_OPTION}
  query MerchantAds(
    $fiat: String
    $crypto: String
    $toDate: DateTime
    $fromDate: DateTime
    $orderType: OrderType
    $status: OrderStatus
    $limit: Int = 10
    $page: Int = 1
  ) {
    __typename
    ads(
      FilterArgs: {
        fiat: $fiat
        crypto: $crypto
        status: $status
        orderType: $orderType
        fromDate: $fromDate
        toDate: $toDate
      }
      PaginationArgs: { limit: $limit, page: $page }
    ) {
      data {
        ...AdFullViewFields
        merchant {
          ...AdMerchantFields
        }
        paymentOptions {
          ...PaymentMethodFields
        }
        paymentChannels {
          ...PaymentChannelFields
          options {
            ...ChannelFormOptions
          }
        }
      }
      meta {
        ...PageMetaFields
      }
    }
  }
`;
var GET_TRADE_QUERY = gql7`
  ${TRADE_USER}
  ${TRADE_ORDER}
  ${TRADE_DISPUTE}
  ${P2P_ORDER_TRADE}
  ${PAYMENT_CHANNEL}
  ${CHANNEL_FORM_OPTION}
  ${TRADE_PAYMENT_METHOD}
  query GetSingleTrade($tradeOrderId: String!) {
    __typename
    getTrade(tradeOrderId: $tradeOrderId) {
      ...OrderTradeFields
      channel {
        ...PaymentChannelFields
        options {
          ...ChannelFormOptions
        }
      }
      maker {
        ...TradeUserFields
      }
      taker {
        ...TradeUserFields
      }
      order {
        ...TradeOrderFields
        offerRate
      }
      buyerPaymentMethod {
        ...TradePaymentMethodFields
      }
      sellerPaymentMethod {
        ...TradePaymentMethodFields
      }
      disputes {
        ...TradeDisputeFields
      }
    }
  }
`;
var GET_MERCHANT_AD_QUERY = gql7`
  ${AD_MERCHANT}
  ${PAYMENT_METHOD}
  ${PAYMENT_CHANNEL}
  ${P2P_AD_FULL_ORDER}
  ${CHANNEL_FORM_OPTION}
  query GetSingleMerchantAd($orderBookId: String!) {
    __typename
    merchantAd(orderBookId: $orderBookId) {
      ...AdFullViewFields
      merchant {
        ...AdMerchantFields
      }
      paymentOptions {
        ...PaymentMethodFields
      }
      paymentChannels {
        ...PaymentChannelFields
        options {
          ...ChannelFormOptions
        }
      }
    }
  }
`;
var GET_MERCHANT_PROFILE_QUERY = gql7`
  query GetMerchantProfile($merchant: String!) {
    getMerchantProfile(merchant: $merchant) {
      averageCompletionTime
      cancelledTradeCount
      completedTradeCount
      monthlyCompletionRate
      monthlyTradeCount
      nickname
      registeredInDays
      starRatingPoint
      tradeCount
      username
    }
  }
`;

// src/graphql/queries/account.ts
import { gql as gql8 } from "@redux-requests/graphql";
var ACCOUNT_PROPS_QUERY = gql8`
  ${PAYMENT_CHANNEL}
  ${COMMON_PROFILE}
  ${COMMON_IDENTITY}
  ${COMMON_ADDRESS}
  ${COMMON_PREFERENCE}
  ${CHANNEL_FORM_OPTION}
  ${FULL_PAYMENT_METHOD}
  query FullAccountProps {
    __typename
    account {
      id
      uid
      email
      level
      phone
      roles
      status
      verified
      username
      nickname
      activeOtp
      createdAt
      updatedAt
      isEmailVerified
      isPhoneVerified
      activatedWebPush
      activatedTelegram
      checkSecondFactor
      profile {
        ...ProfileFields
        identity {
          ...IdentityFields
        }
        address {
          ...AddressFields
        }
        preference {
          ...PreferenceFields
        }
      }
    }
    myPaymentOptions {
      ...FullPaymentMethodFields
      channel {
        ...PaymentChannelFields
        options {
          ...ChannelFormOptions
        }
      }
    }
  }
`;
var GET_ACCOUNT_QUERY = gql8`
  ${COMMON_PROFILE}
  ${COMMON_IDENTITY}
  ${COMMON_ADDRESS}
  ${COMMON_PREFERENCE}
  query GetFullAccount {
    __typename
    account {
      id
      uid
      email
      level
      phone
      roles
      status
      verified
      username
      nickname
      activeOtp
      createdAt
      updatedAt
      isEmailVerified
      isPhoneVerified
      activatedWebPush
      activatedTelegram
      checkSecondFactor
      profile {
        ...ProfileFields
        identity {
          ...IdentityFields
        }
        address {
          ...AddressFields
        }
        preference {
          ...PreferenceFields
        }
      }
    }
  }
`;
var GET_PROFILE_QUERY = gql8`
  query GetProfile {
    profile {
      firstName
      lastName
      otherNames
      fullName
      countryCode
      gender
      dob
      age
      idKycStatus
      addressKycStatus
      completed
      createdAt
      updatedAt
      identity {
        type
        identityNumber
        createdAt
        updatedAt
      }
      address {
        apartmentNo
        street
        lineTwo
        city
        state
        countryCode
        zipCode
        createdAt
        updatedAt
      }
      preference {
        language
        fiatCurrency
        theme
        timezone
        pushNotification
        emailAlert
        createdAt
        updatedAt
      }
    }
  }
`;

// src/graphql/queries/wallet.ts
import { gql as gql9 } from "@redux-requests/graphql";
var GET_WALLET_QUERY = gql9`
  query GetWallet {
    __typename
    wallet {
      id
      status
      displayCurrency
      dailyLimit
      spentToday
      totalBalance
      assets {
        currency
        balance
        lockedBalance
        availableBalance
        merchantCollateral
        addresses {
          currency
          address
          memo
          network
          createdAt
          updatedAt
        }
      }
    }
  }
`;
var GET_ASSET_ADDRESSES_QUERY = gql9`
  query GetWalletAssetAddresses($currency: WalletCurrency!) {
    __typename
    addresses(AssetFilter: { currency: $currency }) {
      currency
      network
      address
      memo
    }
  }
`;
var GET_WITHDRAWAL_FEE_QUERY = gql9`
  query GetWithdrawalFee($amount: Float!, $currency: WalletCurrency!, $network: AssetNetwork!) {
    __typename
    withdrawalFee(FeeArgs: {amount: $amount, currency: $currency, network: $network }) {
      feeInUSD
      paidFee
    }
  }
`;
var GET_TRANSACTIONS_QUERY = gql9`
    query GetTransactions(
        $side: TxType
        $status: TxStatus
        $category: TxCategory
        $orderId: String
        $createdAfter: DateTime
        $createdBefore: DateTime
        $currency: WalletCurrency
        $currencyTwo: WalletCurrency
        $limit: Int
        $page: Int
    ) {
        __typename
        transactions(
            FilterArgs: {
                side: $side
                status: $status
                category: $category
                orderId: $orderId
                createdAfter: $createdAfter
                createdBefore: $createdBefore
                currency: $currency
                currencyTwo: $currencyTwo
            },
            PaginationArgs: {
                limit: $limit
                page: $page
            }
        ) {
            data {
              amount
              amountTwo
              category
              confirmations
              createdAt
              currency
              currencyTwo
              description
              hash
              memo
              network
              orderId
              paidFee
              rateValue
              recipient
              refId
              reqConfirmations
              sender
              side
              status
              updatedAt
            }
            meta {
              page
              pages
              hasNextPage
              hasPreviousPage
            }
        }
    }`;

// src/graphql/queries/bitcode.ts
import { gql as gql10 } from "@redux-requests/graphql";
var GET_BITCODE_INVOICE_QUERY = gql10`
  query GetBitcodeInvoice($bbr: String!) {
    __typename
    getInvoice(PaymentData: { bbr: $bbr }) {
      bbr
      currency
      network
      amount
      depositAddress
      status
      note
      createdAt
    }
  }
`;
var GET_BITCODE_FEE_QUERY = gql10`
  query GetBitcodeFee(
    $amount: Float!
    $currency: WalletCurrency!
    $network: AssetNetwork!
  ) {
    __typename
    getBitcodeFee(
      FeeArgs: { amount: $amount, currency: $currency, network: $network }
    ) {
      feeInUSD
      paidFee
    }
  }
`;
var GET_INVOICE_DETAILS_QUERY = gql10`
  query GetInvoiceDetails($bbr: String!) {
    __typename
    getInvoiceDetails(InvoiceData: { bbr: $bbr }) {
      account {
        email
        fullName
        uid
        username
      }
      actualValue
      amount
      bbr
      createdAt
      currency
      network
      note
      paidFee
      settledAt
      status
    }
  }
`;
var GET_BITCODES_QUERY = gql10`
  query GetBitcodes(
    $bbr: String
    $status: BitcodeStatus
    $currency: WalletCurrency
    $bitcodeType: BitcodeSide
    $createdAfter: DateTime
    $createdBefore: DateTime
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    __typename
    bitcodes(
      FilterArgs: {
        bbr: $bbr
        status: $status
        currency: $currency
        bitcodeType: $bitcodeType
        createdAfter: $createdAfter
        createdBefore: $createdBefore
      }
      PaginateArgs: {
        after: $after
        before: $before
        first: $first
        last: $last
      }
    ) {
      edges {
        cursor
        node {
          amount
          bbr
          bitcodeType
          createdAt
          currency
          depositAddress
          network
          note
          paidFee
          recallReason
          recalledAt
          recipient {
            email
            name
          }
          settledAt
          status
        }
      }
      pageInfo {
        end
        hasNext
        hasPrevious
        start
      }
    }
  }
`;
var GET_VOUCHER_DETAILS_QUERY = gql10`
  query ViewVoucher($bbr: String!) {
    __typename
    getVoucher(FilterData: { bbr: $bbr }) {
      bbr
      amount
      currency
      note
      status
      recallReason
      createdAt
      recalledAt
      settledAt
    }
  }
`;

// src/graphql/mutations/auth.ts
import { gql as gql12 } from "@redux-requests/graphql";

// src/graphql/shared/auth.ts
import { gql as gql11 } from "@redux-requests/graphql";
var COMMON_AUTH_DATA = gql11`
    fragment AuthDataField on AuthDataDto {
        loggedIn
        requestId
        accessToken
        pendingOption
        otpOptions
        username
        phone
        email
        ttl
        sub
        exp
}`;

// src/graphql/mutations/auth.ts
var REQ_CREATE_ACCOUNT = gql12`
  ${COMMON_AUTH_DATA}
  mutation CreateAccount(
    $username: String!
    $email: String!
    $password: String!
    $confirmPassword: String!
    $referralCode: String
  ) {
    __typename
    createAccount(
      AccountData: {
        username: $username
        email: $email
        password: $password
        confirmPassword: $confirmPassword
        referralCode: $referralCode
      }
    ) {
      ...AuthDataField
    }
  }
`;
var REQ_LOGIN = gql12`
  ${COMMON_AUTH_DATA}
  mutation LoginUser($user: String!, $password: String!) {
    __typename
    login(LoginData: { user: $user, password: $password }) {
      ...AuthDataField
    }
  }
`;
var REQ_REFRESH_AUTH = gql12`
  ${COMMON_AUTH_DATA}
  mutation RefreshAuth {
    __typename
    refreshAuth {
      ...AuthDataField
    }
  }
`;
var REQ_LOGOUT = gql12`
  mutation Logout {
    logout {
      completed
      message
    }
  }
`;
var REQ_LOGIN_SMS = gql12`
  mutation ResendLoginSms($requestId: String!) {
    getLoginSmsOtp(ResendSmsData: { requestId: $requestId }) {
      completed
      message
      requestId
      ttl
    }
  }
`;
var REQ_VERIFY_LOGIN = gql12`
  ${COMMON_AUTH_DATA}
  mutation VerifyLogin(
    $requestId: String!
    $otpType: PhoneAppOtp!
    $token: String!
  ) {
    verifyLogin(
      VerifyLoginData: {
        requestId: $requestId
        otpType: $otpType
        token: $token
      }
    ) {
      ...AuthDataField
    }
  }
`;
var REQ_PASSWORD_RESET = gql12`
  mutation RequestPasswordReset($user: String!, $otpOption: PhoneEmailOtp!) {
    requestResetPassword(
      InitPasswordResetRequest: { user: $user, otpOption: $otpOption }
    ) {
      completed
      otpOption
      recipient
      requestId
      ttl
    }
  }
`;
var REQ_RESET_PASSWORD = gql12`
  mutation ResetPassword(
    $requestId: String!
    $token: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      ResetPasswordRequest: {
        requestId: $requestId
        token: $token
        password: $password
        confirmPassword: $confirmPassword
      }
    ) {
      completed
      message
    }
  }
`;
var REQ_CHANGE_PASSWORD = gql12`
  mutation ChangePassword(
    $password: String!
    $confirmPassword: String!
    $currentPassword: String!
  ) {
    __typename
    updatePassword(
      UpdatePasswordRequest: {
        password: $password
        confirmPassword: $confirmPassword
        currentPassword: $currentPassword
      }
    ) {
      completed
      message
    }
  }
`;
var GRANT_CHAT_TOKEN_MUTATION = gql12`
  mutation GrantChatToken {
    __typename
    grantChatToken {
      token
      ttl
    }
  }
`;

// src/graphql/mutations/account.ts
import { gql as gql13 } from "@redux-requests/graphql";
var REQ_CONFIRMATION_OTP = gql13`
  mutation RequestConfirmationOtp(
    $otpOption: PhoneEmailOtp!
    $otpRequestType: OtpReqType!
    $data: JSONObject
  ) {
    __typename
    requestOtp(
      OtpData: {
        otpOption: $otpOption
        otpRequestType: $otpRequestType
        data: $data
      }
    ) {
      completed
      message
      otpOption
      ttl
    }
  }
`;
var REQ_VERIFY_EMAIL_OTP = gql13`
  mutation RequestVerifyEmailOtp {
    __typename
    reqVerifyEmailOtp {
      completed
      message
      ttl
    }
  }
`;
var REQ_VERIFY_EMAIL = gql13`
  mutation VerifyEmailAddress($token: String!) {
    __typename
    verifyEmailAddress(OtpTokenData: { token: $token }) {
      completed
      message
    }
  }
`;
var REQ_ADD_MOBILE_NUMBER = gql13`
  mutation AddPhoneNumber($phone: String!) {
    __typename
    addPhoneNumber(PhoneNumberData: { phone: $phone }) {
      completed
      message
      ttl
    }
  }
`;
var REQ_VERIFY_PHONE_OTP = gql13`
  mutation ReqVerifyPhoneOtp {
    __typename
    reqVerifyPhoneOtp {
      completed
      message
      ttl
    }
  }
`;
var REQ_VERIFY_PHONE = gql13`
  mutation VerifyPhoneToken($token: String!) {
    __typename
    verifyPhoneNumber(OtpTokenData: { token: $token }) {
      completed
      message
    }
  }
`;
var REQ_INIT_2FA_SETUP = gql13`
  mutation InitAuthenticatorSetup {
    __typename
    init2faSetup {
      secret
      qrcodeUrl
    }
  }
`;
var REQ_VERIFY_2FA_SETUP = gql13`
  mutation VerifyAuthenticatorToken($token: String!) {
    __typename
    verify2faSetup(OtpTokenData: { token: $token }) {
      completed
      message
    }
  }
`;
var REQ_ENABLE_LOGIN_2FA = gql13`
  mutation Enable2faOnLogin {
    __typename
    enableLogin2fa {
      completed
      message
    }
  }
`;
var REQ_DISABLE_LOGIN_2FA = gql13`
  mutation Disable2faOnLogin($otpOption: PhoneAppOtp!, $token: String!) {
    __typename
    disableLogin2fa(OtpData: { otpOption: $otpOption, token: $token }) {
      completed
      message
    }
  }
`;
var UPDATE_PROFILE_ADDRESS_MUTATION = gql13`
  mutation UpdateAddress(
    $street: String!
    $lineTwo: String
    $apartmentNo: String
    $city: String!
    $state: String!
    $zipCode: String!
    $countryCode: String!
  ) {
    updateProfileAddress(
      AddressData: {
        city: $city
        state: $state
        street: $street
        lineTwo: $lineTwo
        zipCode: $zipCode
        apartmentNo: $apartmentNo
        countryCode: $countryCode
      }
    ) {
      street
      lineTwo
      apartmentNo
      city
      state
      zipCode
      countryCode
      createdAt
      updatedAt
    }
  }
`;
var UPDATE_PROFILE_PREFERENCE_MUTATION = gql13`
  ${COMMON_PREFERENCE}
  mutation UpdateProfilePreference(
    $theme: Theme
    $timezone: String
    $language: AppLanguage
    $emailAlert: Boolean
    $fiatCurrency: String
  ) {
    updateProfilePreference(
      PreferenceData: {
        fiatCurrency: $fiatCurrency
        language: $language
        timezone: $timezone
        theme: $theme
        emailAlert: $emailAlert
      }
    ) {
      ...PreferenceFields
    }
  }
`;
var UPDATE_PAYMENT_OPTION_MUTATION = gql13`
  mutation UpdatePaymentOption(
    $channel: String
    $accountNumber: String
    $username: String
    $email: String
    $bankName: String
    $bankBranch: String
    $accountTag: String
    $bankAccSwiftcode: String
    $qrcode: String
    $id: ID!
  ) {
    updatePaymentOption(
      UpdatePaymentOptionData: {
        channel: $channel
        accountNumber: $accountNumber
        username: $username
        email: $email
        bankName: $bankName
        accountTag: $accountTag
        bankBranch: $bankBranch
        bankAccSwiftcode: $bankAccSwiftcode
        qrcode: $qrcode
      }
      PaymentOptionId: { id: $id }
    ) {
      affectedRows
      changedRows
    }
  }
`;
var DELETE_PAYMENT_OPTION_MUTATION = gql13`
  mutation DeletePaymentOption($id: ID!) {
    deletePaymentOption(PaymentOptionId: { id: $id }) {
      affectedRows
      changedRows
    }
  }
`;
var ADD_PAYMENT_METHOD_MUTATION = gql13`
  mutation AddPaymentMethod(
    $email: String
    $bankName: String
    $channel: String!
    $accountTag: String
    $username: String
    $accountNumber: String
    $bankBranch: String
    $bankAccSwiftcode: String
    $token: String!
    $otpOption: PhoneAppOtp!
  ) {
    __typename
    addPaymentOption(
      OtpData: { otpOption: $otpOption, token: $token }
      PaymentOptionData: {
        email: $email
        channel: $channel
        username: $username
        bankName: $bankName
        bankBranch: $bankBranch
        accountTag: $accountTag
        accountNumber: $accountNumber
        bankAccSwiftcode: $bankAccSwiftcode
      }
    ) {
      optionId
    }
  }
`;
var EDIT_PAYMENT_METHOD_MUTATION = gql13`
  mutation UpdatePaymentMethod(
    $token: String!
    $email: String
    $optionId: String!
    $bankName: String
    $accountTag: String
    $username: String
    $accountNumber: String
    $bankBranch: String
    $bankAccSwiftcode: String
    $otpOption: PhoneAppOtp!
  ) {
    __typename
    updatePaymentOption(
      OtpData: { otpOption: $otpOption, token: $token }
      PaymentOptionData: {
        optionId: $optionId
        email: $email
        username: $username
        bankName: $bankName
        bankBranch: $bankBranch
        accountTag: $accountTag
        accountNumber: $accountNumber
        bankAccSwiftcode: $bankAccSwiftcode
      }
    ) {
      message
      completed
    }
  }
`;
var DELETE_PAYMENT_OPTION = gql13`
  mutation DeletePaymentOption($optionId: String!) {
    __typename
    deletePaymentOption(OptionId: $optionId) {
      completed
      message
    }
  }
`;
var ACTIVATE_WEB_PUSH = gql13`
  mutation ActivateWebPush(
    $endpoint: String!
    $keyAuth: String!
    $keyP256dh: String!
  ) {
    __typename
    activateWebPushNotification(
      WebPushData: {
        endpoint: $endpoint
        keyAuth: $keyAuth
        keyP256dh: $keyP256dh
      }
    ) {
      completed
      message
    }
  }
`;
var ENABLE_WEB_PUSH = gql13`
  mutation EnableWebPush {
    __typename
    enableWebPushNotification {
      completed
      message
    }
  }
`;
var DISABLE_WEB_PUSH = gql13`
  mutation DisableWebPush {
    __typename
    disableWebPushNotification {
      completed
      message
    }
  }
`;
var ACTIVATE_TELEGRAM = gql13`
  mutation ActivateTelegram {
    __typename
    activateTelegram {
      activationUrl
    }
  }
`;
var ENABLE_TELEGRAM = gql13`
  mutation EnableTelegram {
    __typename
    enableTelegramNotification {
      completed
      message
    }
  }
`;
var DISABLE_TELEGRAM = gql13`
  mutation DisableTelegram {
    __typename
    disableTelegramNotification {
      completed
      message
    }
  }
`;
var INIT_KYC_VERIFICATION = gql13`
  mutation InitKycVerification(
      $country:String!
      $flowId:String!
    ) {
      __typename
      initIdentityVerification(IdentityData: {
        country:$country
        flowId:$flowId
      }) {
        jobId
        provider
        sdkConfig
      }
  }
`;
var SUBMIT_KYC_VERIFICATION = gql13`
  mutation SubmitKycVerification {
    __typename
    onVerificationSubmit {
      message
      completed
    }
  }
`;

// src/graphql/mutations/wallet.ts
import { gql as gql14 } from "@redux-requests/graphql";
var REQ_CHANGE_CURRENCY = gql14`
  mutation SetDisplayCurrency($currency: WalletCurrency!) {
    __typename
    setDisplayCurrency(DisplayCurrency: $currency) {
      completed
      message
    }
  }
`;
var WITHDRAW_ASSET_MUTATION = gql14`
  mutation Withdraw(
    $emailToken: String!, $smsToken: String, $totpToken: String, 
    $amount: Float!, $currency: WalletCurrency!, $description: String, 
    $memo: String, $network: AssetNetwork!, $recipient: String!) {
      __typename withdraw(
        OtpData: {emailToken: $emailToken, smsToken: $smsToken, totpToken: $totpToken}, 
        WithdrawData: {
          amount: $amount, currency: $currency, description: $description,
          memo: $memo, network: $network, recipient: $recipient
        }
      ) 
    {
      completed
      message
    }
  }
`;

// src/graphql/mutations/bitcode.ts
import { gql as gql15 } from "@redux-requests/graphql";
var SEND_BITCODE_INVOICE_MUTATION = gql15`
  mutation SendInvoice(
    $amount: Float!
    $currency: WalletCurrency!
    $network: AssetNetwork!
    $note: String
    $recipient: InvoiceRecipient!
  ) {
    __typename
    sendInvoice(
      RequestData: {
        amount: $amount
        currency: $currency
        network: $network
        note: $note
        recipient: $recipient
      }
    ) {
      bbr
      bitcodeType
    }
  }
`;
var SETTLE_BITCODE_INVOICE_MUTATION = gql15`
  mutation SettleInvoice(
    $otpOption: PhoneAppOtp!
    $token: String!
    $bbr: String!
  ) {
    __typename
    settleInvoice(
      OtpData: { otpOption: $otpOption, token: $token }
      PaymentData: { bbr: $bbr }
    ) {
      message
      completed
    }
  }
`;
var SEND_BITCODE_VOUCHER_MUTATION = gql15`
  mutation SendVoucher(
    $otpOption: PhoneAppOtp!
    $token: String!
    $amount: Float!
    $currency: WalletCurrency!
    $note: String
    $recipient: VoucherRecipient!
  ) {
    __typename
    sendVoucher(
      OtpData: { otpOption: $otpOption, token: $token }
      VoucherData: {
        amount: $amount
        currency: $currency
        note: $note
        recipient: $recipient
      }
    ) {
      bbr
      bitcodeType
    }
  }
`;
var REDEEM_VOUCHER_MUTATION = gql15`
  mutation RedeemVoucher($bbr: String!, $pin: String!) {
    __typename
    redeemVoucher(BitcodeData: { bbr: $bbr, pin: $pin }) {
      message
      completed
    }
  }
`;
var RECALL_VOUCHER_MUTATION = gql15`
  mutation RecallVoucher($bbr: String!, $recallReason: String) {
    __typename
    recallVoucher(VoucherData: { bbr: $bbr, recallReason: $recallReason }) {
      message
      completed
    }
  }
`;
var RESEND_INVOICE_MUTATION = gql15`
  mutation ResendInvoice($bbr: String!) {
    __typename
    resendInvoice(bbr: $bbr) {
      message
      completed
    }
  }
`;
var RESEND_VOUCHER_MUTATION = gql15`
  mutation ResendVoucher($bbr: String!) {
    __typename
    resendVoucher(bbr: $bbr) {
      message
      completed
    }
  }
`;

// src/graphql/mutations/p2p.ts
import { gql as gql16 } from "@redux-requests/graphql";
var POST_AD_MUTATION = gql16`
  mutation PostMerchantAd(
    $crypto: String!
    $fiat: String!
    $maxOrderAmount: Float
    $maxQuantity: Float
    $minOrderAmount: Float
    $minQuantity: Float
    $offerRate: Float!
    $orderType: OrderType!
    $paymentChannelSlugs: [String!]
    $paymentOptionIds: [String!]
    $paymentWindow: Int!
    $quantity: Float!
    $tradeTerms: String!
    $rateType: RateType!
    $maximumRate: Float
    $minimumRate: Float
    $rateMargin: Float!
  ) {
    __typename
    postMerchantAd(
      PostAdData: {
        fiat: $fiat
        crypto: $crypto
        orderType: $orderType
        minQuantity: $minQuantity
        maxQuantity: $maxQuantity
        minOrderAmount: $minOrderAmount
        maxOrderAmount: $maxOrderAmount
        offerRate: $offerRate
        paymentWindow: $paymentWindow
        quantity: $quantity
        paymentOptionIds: $paymentOptionIds
        paymentChannelSlugs: $paymentChannelSlugs
        tradeTerms: $tradeTerms
        rateType: $rateType
        rateMargin: $rateMargin
        minimumRate: $minimumRate
        maximumRate: $maximumRate
      }
    ) {
      orderBookId
      advertisedRate
    }
  }
`;
var RATE_TRADE_MUTATION = gql16`
  mutation RateTrade(
    $comment: String
    $rating: Float!
    $tradeOrderId: String!
    ) {
    __typename
    rateTrade(
      InputData: {
        comment: $comment
         rating: $rating
         tradeOrderId: $tradeOrderId 
      }
    ) {
      completed
      message
    }
  }
`;
var POST_ONRAMP_MUTATION = gql16`
  ${TRADE_USER}
  ${TRADE_ORDER}
  ${P2P_ORDER_TRADE}
  ${PAYMENT_CHANNEL}
  ${CHANNEL_FORM_OPTION}
  ${TRADE_PAYMENT_METHOD}
  mutation CreateP2pBuyOrder(
    $amount: Float!
    $quantity: Float!
    $orderBookId: String!
    $paymentOptionId: String!
  ) {
    createP2pBuyTrade(
      P2pBuyTradeData: {
        orderBookId: $orderBookId
        quantity: $quantity
        amount: $amount
        paymentOptionId: $paymentOptionId
      }
    ) {
      ...OrderTradeFields
      channel {
        ...PaymentChannelFields
        options {
          ...ChannelFormOptions
        }
      }
      maker {
        ...TradeUserFields
      }
      taker {
        ...TradeUserFields
      }
      order {
        ...TradeOrderFields
      }
      buyerPaymentMethod {
        ...TradePaymentMethodFields
      }
      sellerPaymentMethod {
        ...TradePaymentMethodFields
      }
    }
  }
`;
var POST_OFFRAMP_MMUTAION = gql16`
  ${TRADE_USER}
  ${TRADE_ORDER}
  ${P2P_ORDER_TRADE}
  ${PAYMENT_CHANNEL}
  ${CHANNEL_FORM_OPTION}
  ${TRADE_PAYMENT_METHOD}
  mutation CreateP2pBuyOrder(
    $amount: Float!
    $quantity: Float!
    $orderBookId: String!
    $paymentOptionId: String!
  ) {
    createP2pSellTrade(
      P2pSellTradeData: {
        orderBookId: $orderBookId
        paymentOptionId: $paymentOptionId
        quantity: $quantity
        amount: $amount
      }
    ) {
      ...OrderTradeFields
      channel {
        ...PaymentChannelFields
        options {
          ...ChannelFormOptions
        }
      }
      maker {
        ...TradeUserFields
      }
      taker {
        ...TradeUserFields
      }
      order {
        ...TradeOrderFields
      }
      buyerPaymentMethod {
        ...TradePaymentMethodFields
      }
      sellerPaymentMethod {
        ...TradePaymentMethodFields
      }
    }
  }
`;
var CLOSE_MERCHANT_AD_MUTATION = gql16`
  mutation CloseP2pOrder($orderBookId: String!) {
    __typename
    closeP2pOrder(orderBookId: $orderBookId) {
      orderBookId
      orderType
      minOrderAmount
      maxOrderAmount
      availableQuantity
      advertisedRate
      paymentWindow
      quantity
      status
      crypto
      fiat
      paymentChannels {
        slug
        title
      }
      merchant {
        username
        nickname
        p2pTradeStats {
          completedAfter
          takerRating
        }
      }
    }
  }
`;
var PAUSE_PLAY_AD_MUTATION = gql16`
  mutation PauseOrActivateAd($orderBookId: String!) {
    __typename
    openOrPauseAd(orderBookId: $orderBookId) {
      message
      completed
    }
  }
`;
var CANCEL_TRADE_MUTATION = gql16`
  mutation CancelTrade($tradeOrderId: String!) {
    __typename
    cancelP2pTrade(tradeOrderId: $tradeOrderId) {
      completed
      message
    }
  }
`;
var MARK_TRADE_PAID_MUTATION = gql16`
  ${TRADE_USER}
  ${TRADE_ORDER}
  ${P2P_ORDER_TRADE}
  ${PAYMENT_CHANNEL}
  ${CHANNEL_FORM_OPTION}
  ${TRADE_PAYMENT_METHOD}
  mutation MarkP2PPaid($tradeOrderId: String!) {
    __typename
    markP2pTradeAsPaid(tradeOrderId: $tradeOrderId) {
      ...OrderTradeFields
      channel {
        ...PaymentChannelFields
        options {
          ...ChannelFormOptions
        }
      }
      maker {
        ...TradeUserFields
      }
      taker {
        ...TradeUserFields
      }
      order {
        ...TradeOrderFields
      }
      buyerPaymentMethod {
        ...TradePaymentMethodFields
      }
      sellerPaymentMethod {
        ...TradePaymentMethodFields
      }
    }
  }
`;
var CONFIRM_P2P_TRADE_MUTATION = gql16`
  mutation ConfirmP2P(
    $otpOption: PhoneAppOtp!
    $token: String!
    $tradeOrderId: String!
  ) {
    confirmP2pTrade(
      ConfirmP2pTradeData: {
        otpOption: $otpOption
        token: $token
        tradeOrderId: $tradeOrderId
      }
    ) {
      completed
      message
    }
  }
`;
var RESOLVE_TRADE_DISPUTE_MUTATION = gql16`
  mutation ResolveTradeDispoute($tradeOrderId: String!) {
    __typename
    resolveDispute(tradeOrderId: $tradeOrderId) {
      message
      completed
    }
  }
`;
var RAISE_TRADE_DISPUTE_MUTATION = gql16`
  mutation RaiseTradeDispute(
    $tradeOrderId: String!
    $title: String!
    $description: String!
    $evidences: [Upload!]!
  ) {
    __typename
    raiseDispute(
      DisputeData: {
        tradeOrderId: $tradeOrderId
        title: $title
        description: $description
        evidences: $evidences
      }
    ) {
      completed
      message
    }
  }
`;
var UPDATE_MERCHANT_AD_MUTATION = gql16`
  mutation UpdateMerchantAd(
    $offerRate: Float!
    $orderType: OrderType!
    $orderBookId: String!
    $minQuantity: Float
    $minOrderAmount: Float
    $maxQuantity: Float
    $maxOrderAmount: Float
    $paymentOptionIds: [String!]
    $paymentChannelSlugs: [String!]
    $paymentWindow: Int!
    $tradeTerms: String!
    $rateMargin: Float
    $rateType: RateType!
    $quantity: Float!
    $maximumRate: Float
    $minimumRate: Float
  ) {
    __typename
    updateAd(UpdateAdData: {
      offerRate: $offerRate
      orderType: $orderType
      tradeTerms: $tradeTerms
      orderBookId: $orderBookId
      minQuantity: $minQuantity
      minOrderAmount: $minOrderAmount
      maxQuantity: $maxQuantity
      paymentWindow: $paymentWindow
      maxOrderAmount: $maxOrderAmount
      paymentOptionIds: $paymentOptionIds
      paymentChannelSlugs: $paymentChannelSlugs
      rateMargin: $rateMargin
      rateType: $rateType
      quantity: $quantity
      maximumRate: $maximumRate
      minimumRate: $minimumRate
    }) {
      message
      completed
    }
  }
`;

// src/redux/actions/graphql/authActions.ts
var getAuthUser = () => ({
  type: GET_AUTH_USER,
  request: {
    query: GET_AUTH_USER_QUERY
  },
  meta: {
    driver: "auth"
  }
});
var createAccount = (data) => ({
  type: CREATE_ACCOUNT,
  request: {
    query: REQ_CREATE_ACCOUNT,
    variables: { ...data }
  },
  meta: {
    driver: "auth"
  }
});
var authLogin = (user2, password) => ({
  type: INIT_LOGIN,
  request: {
    query: REQ_LOGIN,
    variables: { user: user2, password }
  },
  meta: {
    driver: "auth",
    runOnError: false
  }
});
var refreshAuth = () => ({
  type: REFRESH_LOGIN,
  request: {
    query: REQ_REFRESH_AUTH
  },
  meta: {
    driver: "auth",
    runOnError: false
  }
});
var authLogout = () => ({
  type: LOGOUT,
  request: {
    query: REQ_LOGOUT
  },
  meta: {
    driver: "auth"
  }
});
var sendLoginSms = (requestId) => ({
  type: SEND_LOGIN_SMS,
  request: {
    query: REQ_LOGIN_SMS,
    variables: { requestId }
  },
  meta: {
    driver: "auth"
  }
});
var verifyLogin2fa = (requestId, otpType, token) => ({
  type: VERIFY_LOGIN,
  request: {
    query: REQ_VERIFY_LOGIN,
    variables: { requestId, otpType, token }
  },
  meta: {
    driver: "auth"
  }
});
var requestPasswordReset = (user2, otpOption) => ({
  type: INIT_PASSWORD_RESET,
  request: {
    query: REQ_PASSWORD_RESET,
    variables: { user: user2, otpOption }
  },
  meta: {
    driver: "auth"
  }
});
var resetPassword = (variables) => ({
  type: RESET_PASSWORD,
  request: {
    query: REQ_RESET_PASSWORD,
    variables
  },
  meta: {
    driver: "auth"
  }
});
var updatePassword = (variables) => ({
  type: CHANGE_PASSWORD,
  request: {
    query: REQ_CHANGE_PASSWORD,
    variables
  },
  meta: {
    driver: "auth"
  }
});
var requestChatToken = () => ({
  type: REQUEST_CHAT_TOKEN,
  request: {
    query: GRANT_CHAT_TOKEN_MUTATION
  },
  meta: {
    driver: "auth"
  }
});

// src/redux/actions/graphql/walletActions.ts
var getWallet = () => ({
  type: GET_WALLET,
  request: {
    query: GET_WALLET_QUERY
  },
  meta: {
    driver: "wallet"
  }
});
var getAssetAddresses = (currency) => ({
  type: GET_ASSET_ADDRESSES,
  request: {
    query: GET_ASSET_ADDRESSES_QUERY,
    variables: { currency }
  },
  meta: {
    driver: "wallet",
    requestKey: currency
  }
});
var setDisplayCurrency = (currency) => ({
  type: CHANGE_WALLET_CURRENCY,
  request: {
    query: REQ_CHANGE_CURRENCY,
    variables: { currency }
  },
  meta: {
    driver: "wallet"
  }
});
var getWithdrawalFee = (feeArgs) => ({
  type: GET_WITHDRAWAL_FEE,
  request: {
    query: GET_WITHDRAWAL_FEE_QUERY,
    variables: { ...feeArgs }
  },
  meta: {
    driver: "wallet"
  }
});
var withdrawAsset = (otpData, withdrawData) => ({
  type: WITHDRAW_ASSET,
  request: {
    query: WITHDRAW_ASSET_MUTATION,
    variables: { ...otpData, ...withdrawData }
  },
  meta: {
    driver: "wallet"
  }
});
var getTransactions = (page = 1, limit = 10, filter) => ({
  type: GET_TRANSACTION,
  request: {
    query: GET_TRANSACTIONS_QUERY,
    variables: { ...filter, page, limit }
  },
  meta: {
    driver: "wallet"
  }
});
var resetWallet = handleDataResetAction(GET_WALLET);

// src/redux/actions/graphql/accountActions.ts
var loadUser = () => ({
  type: LOAD_FULL_ACCOUNT,
  request: {
    query: ACCOUNT_PROPS_QUERY
  },
  meta: {
    driver: "account"
  }
});
var updateAddress = (data) => ({
  type: UPDATE_ADDRESS,
  request: {
    query: UPDATE_PROFILE_ADDRESS_MUTATION,
    variables: { ...data }
  },
  meta: {
    driver: "account"
  }
});
var requestOtp = (variables) => ({
  type: REQUEST_OTP,
  request: {
    query: REQ_CONFIRMATION_OTP,
    variables
  },
  meta: {
    driver: "account"
  }
});
var addPaymentMethod = (variables) => ({
  type: ADD_PAYMENT_METHOD,
  request: {
    query: ADD_PAYMENT_METHOD_MUTATION,
    variables
  },
  meta: {
    driver: "account"
  }
});
var updatePaymentMethod = (variables) => ({
  type: UPDATE_PAYMENT_OPTION,
  request: {
    query: EDIT_PAYMENT_METHOD_MUTATION,
    variables
  },
  meta: {
    driver: "account"
  }
});
var deletePaymentOption = (optionId) => ({
  type: DELETE_A_PAYMENT_OPTION,
  request: {
    query: DELETE_PAYMENT_OPTION,
    variables: { optionId }
  },
  meta: {
    driver: "account"
  }
});
var updateUserAccount = handleDataUpdateAction(LOAD_FULL_ACCOUNT);
var resetUserAccount = handleDataResetAction(LOAD_FULL_ACCOUNT);

// src/redux/actions/graphql/bitcodeActions.ts
var getInvoiceAction = (bbr) => ({
  type: GET_BITCODE_INVOICE,
  request: {
    query: GET_BITCODE_INVOICE_QUERY,
    variables: { bbr }
  },
  meta: {
    driver: "bitcode"
  }
});
var getBitcodes = (variables = {}) => ({
  type: GET_BITCODES,
  request: {
    query: GET_BITCODES_QUERY,
    variables
  },
  meta: { driver: "bitcode" }
});
var getInvoiceDetails = (paymentData) => ({
  type: GET_INVOICE_DETAILS,
  request: {
    query: GET_INVOICE_DETAILS_QUERY,
    variables: { ...paymentData }
  },
  meta: { driver: "bitcode" }
});
var getVoucherDetails = (paymentData) => ({
  type: GET_VOUCHER_DETAILS,
  request: {
    query: GET_VOUCHER_DETAILS_QUERY,
    variables: { ...paymentData }
  },
  meta: { driver: "bitcode" }
});
var getBitcodeFee = (feeArgs) => ({
  type: GET_BITCODE_FEE,
  request: {
    query: GET_BITCODE_FEE_QUERY,
    variables: { ...feeArgs }
  },
  meta: { driver: "bitcode" }
});
var sendInvoice = (requestData) => ({
  type: SEND_BITCODE_INVOICE,
  request: {
    query: SEND_BITCODE_INVOICE_MUTATION,
    variables: { ...requestData }
  },
  meta: { driver: "bitcode" }
});
var settleInvoice = (otpData, paymentData) => ({
  type: SETTLE_BITCODE_INVOICE,
  request: {
    query: SETTLE_BITCODE_INVOICE_MUTATION,
    variables: { ...otpData, ...paymentData }
  },
  meta: { driver: "bitcode" }
});
var sendVoucher = (otpData, voucherData) => ({
  type: SEND_BITCODE_VOUCHER,
  request: {
    query: SEND_BITCODE_VOUCHER_MUTATION,
    variables: { ...otpData, ...voucherData }
  },
  meta: { driver: "bitcode" }
});
var redeemVoucher = (bitcodeData) => ({
  type: REDEEM_BITCODE_VOUCHER,
  request: {
    query: REDEEM_VOUCHER_MUTATION,
    variables: { ...bitcodeData }
  },
  meta: { driver: "bitcode" }
});
var recallVoucher = (voucherData) => ({
  type: RECALL_VOUCHER,
  request: {
    query: RECALL_VOUCHER_MUTATION,
    variables: { ...voucherData }
  },
  meta: { driver: "bitcode" }
});
var resendVoucher = (voucherData) => ({
  type: RESEND_VOUCHER,
  request: {
    query: RESEND_VOUCHER_MUTATION,
    variables: { ...voucherData }
  },
  meta: { driver: "bitcode" }
});
var resendInvoice = (invoiceData) => ({
  type: RESEND_INVOICE,
  request: {
    query: RESEND_INVOICE_MUTATION,
    variables: { ...invoiceData }
  },
  meta: { driver: "bitcode" }
});

// src/redux/actions/graphql/utilGqlActions.ts
var getAppConfigs = () => ({
  type: GET_CONFIGS,
  request: {
    query: GET_CONFIGS_QUERY
  },
  meta: {
    driver: "util"
  }
});
var getRecentBlogPosts = () => ({
  type: GET_RECENT_BLOG_POSTS,
  request: {
    query: GET_RECENT_BLOG_POSTS_QUERY
  },
  meta: {
    driver: "util"
  }
});
var getAssetRate = ({ amount = 1, from, to }) => ({
  type: GET_ASSET_RATE,
  request: {
    query: GET_ASSET_RATE_QUERY,
    variables: { amount, from, to }
  },
  meta: {
    silent: true,
    driver: "util",
    requestKey: `${from}:${to}`
  }
});

// src/redux/actions/graphql/p2pActions.ts
var postMerchantAd = (variables) => ({
  type: POST_MERCHANT_AD,
  request: {
    query: POST_AD_MUTATION,
    variables
  },
  meta: {
    driver: "p2p"
  }
});
var getCustomerTrades = (variables) => ({
  type: GET_CUSTOMER_TRADES,
  request: {
    query: CUSTOMER_TRADES_QUERY,
    variables
  },
  meta: {
    driver: "p2p"
  }
});
var getAdOrders = (variables) => ({
  type: GET_AD_ORDERS,
  request: {
    query: MERCHANT_ORDERS_QUERY,
    variables
  },
  meta: {
    driver: "p2p"
  }
});
var getMarketplaceAds = (variables) => ({
  type: GET_MARKETPLACE_ADS,
  request: {
    query: P2P_ADS_QUERY,
    variables
  },
  meta: {
    driver: "p2p"
  }
});
var getMerchantAds = (variables) => ({
  type: GET_MERCHANT_ADS,
  request: {
    query: MERCHANT_ADS_QUERY,
    variables
  },
  meta: {
    driver: "p2p"
  }
});
var getMerchantProfile = (merchant) => ({
  type: GET_MERCHANT_PROFILE,
  request: {
    query: GET_MERCHANT_PROFILE_QUERY,
    variables: { merchant }
  },
  meta: {
    driver: "p2p"
  }
});
var getOfframpTradeOrder = (data) => ({
  type: GET_OFFRAMP_ORDER,
  request: {
    query: P2P_ADS_QUERY,
    variables: { ...data, orderType: "BUY" /* BUY */ }
  },
  meta: {
    driver: "p2p"
  }
});
var getOnrampTradeOrder = (data) => ({
  type: GET_ONRAMP_ORDER,
  request: {
    query: P2P_ADS_QUERY,
    variables: { ...data, orderType: "SELL" /* SELL */ }
  },
  meta: {
    driver: "p2p"
  }
});
var getMerchantAd = (orderBookId) => ({
  type: QUERY_SINGLE_AD,
  request: {
    query: GET_MERCHANT_AD_QUERY,
    variables: { orderBookId }
  },
  meta: {
    driver: "p2p"
  }
});
var closeMerchantAd = (orderBookId) => ({
  type: CLOSE_MERCHANT_AD,
  request: {
    query: CLOSE_MERCHANT_AD_MUTATION,
    variables: { orderBookId }
  },
  meta: {
    driver: "p2p"
  }
});
var pauseOrPlayAd = (orderBookId) => ({
  type: PAUSE_OR_PLAY_AD,
  request: {
    query: PAUSE_PLAY_AD_MUTATION,
    variables: { orderBookId }
  },
  meta: {
    driver: "p2p"
  }
});
var postOnrampTrade = (data) => ({
  type: POST_ONRAMP_TRADE,
  request: {
    query: POST_ONRAMP_MUTATION,
    variables: { ...data }
  },
  meta: {
    driver: "p2p"
  }
});
var postOfframpTrade = (data) => ({
  type: POST_OFFRAMP_TRADE,
  request: {
    query: POST_OFFRAMP_MMUTAION,
    variables: { ...data }
  },
  meta: {
    driver: "p2p"
  }
});
var querySingleP2pTrade = (tradeOrderId) => ({
  type: QUERY_SINGLE_P2P_TRADE,
  request: {
    query: GET_TRADE_QUERY,
    variables: { tradeOrderId }
  },
  meta: {
    driver: "p2p",
    requestKey: tradeOrderId,
    poll: 10
  }
});
var cancelP2pTrade = (tradeOrderId) => ({
  type: CANCEL_P2P_TRADE,
  request: {
    query: CANCEL_TRADE_MUTATION,
    variables: { tradeOrderId }
  },
  meta: {
    driver: "p2p"
  }
});
var markP2pTradePaid = (tradeOrderId) => ({
  type: MARK_TRADE_AS_PAID,
  request: {
    query: MARK_TRADE_PAID_MUTATION,
    variables: { tradeOrderId }
  },
  meta: {
    driver: "p2p"
  }
});
var confirmP2pTrade = (data) => ({
  type: CONFIRM_P2P_TRADE,
  request: {
    query: CONFIRM_P2P_TRADE_MUTATION,
    variables: { ...data }
  },
  meta: {
    driver: "p2p"
  }
});
var resolveTradeDispute = (tradeOrderId) => ({
  type: RESOLVE_TRADE_DISPUTE,
  request: {
    query: RESOLVE_TRADE_DISPUTE_MUTATION,
    variables: { tradeOrderId }
  },
  meta: {
    driver: "p2p"
  }
});
var raiseDispute = (variables) => ({
  type: RAISE_TRADE_DISPUTE,
  request: {
    query: RAISE_TRADE_DISPUTE_MUTATION,
    variables
  },
  meta: {
    driver: "p2p"
  }
});
var rateTrade = (variables) => ({
  type: RATE_TRADE,
  request: {
    query: RATE_TRADE_MUTATION,
    variables
  },
  meta: {
    driver: "p2p"
  }
});
var updateMerchantAd = (variables) => ({
  type: UPDATE_MERCHANT_AD,
  request: {
    query: UPDATE_MERCHANT_AD_MUTATION,
    variables
  },
  meta: {
    driver: "p2p"
  }
});
var updateTradeData = handleDataUpdateAction(QUERY_SINGLE_P2P_TRADE);
var updateOneMerchantAd = handleDataUpdateAction(GET_MERCHANT_ADS);

// src/redux/actions/axios/utilActions.ts
var loadCountries = () => ({
  type: GET_COUNTRIES,
  request: {
    url: `${apiConfig.restBaseUrl}/utils/countries`
  }
});
var loadLanguages = () => ({
  type: GET_LANGUAGES,
  request: {
    url: `${apiConfig.restBaseUrl}/utils/languages`
  }
});
var loadTimezones = () => ({
  type: GET_TIMEZONES,
  request: {
    url: `${apiConfig.restBaseUrl}/utils/timezones`
  }
});
var loadCryptoAssets = () => ({
  type: GET_CRYPTO_ASSETS,
  request: {
    url: `${apiConfig.restBaseUrl}/utils/assets`,
    params: { type: "CRYPTO" /* CRYPTO */ }
  }
});
var loadFiatAssets = () => ({
  type: GET_FIAT_ASSETS,
  request: {
    url: `${apiConfig.restBaseUrl}/utils/assets`,
    params: { type: "FIAT" /* FIAT */ }
  }
});
var loadPaymentChannels = () => ({
  type: GET_PAYMENT_CHANNELS,
  request: {
    url: `${apiConfig.restBaseUrl}/utils/payment-channels`
  }
});
var loadP2pTradePairs = () => ({
  type: GET_P2P_ASSET_TRADE_PAIRS,
  request: {
    url: `${apiConfig.restBaseUrl}/utils/assets/p2p-trade-pairs`
  }
});

// src/redux/actions/axios/serviceActions.ts
var getClientData = () => ({
  type: FETCH_CLIENT_DATA,
  request: {
    url: `services/visitor`
  },
  meta: {
    driver: "api"
  }
});

// src/redux/actions/local/auth.ts
var logonUser = (payload) => ({
  type: SET_LOGGED_IN,
  payload
});

// src/redux/actions/local/uiActions.ts
var toggleShowBalance = () => ({
  type: TOGGLE_BALANCE
});
var toggleSideMenu = () => ({
  type: TOGGLE_SIDE_MENU
});

// src/redux/actions/local/rateActions.ts
var updateRate = (payload) => ({
  type: RATE_UPDATE,
  payload
});

// src/redux/actions/local/localUpdate.ts
var updateAccountAction = (updateData) => ({
  type: ACCOUNT_LOCAL_UPDATE,
  meta: {
    mutations: {
      [GET_ACCOUNT]: {
        updateData: (data) => ({
          ...data,
          ...updateData
        }),
        local: true
      }
    }
  }
});

// src/redux/actions/local/marketplace.ts
var updateMarketOrderQuery = (payload) => ({
  type: UPDATE_ORDER_QUERY,
  payload
});
var resetMarketOrderQuery = () => ({
  type: RESET_ORDER_QUERY
});
var updateMerchantOrderQuery = (payload) => ({
  type: UPDATE_MERCHANT_ORDER_QUERY,
  payload
});
var resetMerchantOrderQuery = (payload) => ({
  type: RESET_MERCHANT_ORDER_QUERY,
  payload
});
var updateCustomerTradeQuery = (payload) => ({
  type: UPDATE_TRADE_QUERY,
  payload
});
var resetCustomerTradeQuery = (payload) => ({
  type: RESET_TRADE_QUERY,
  payload
});
var updateMerchantAdsQuery = (payload) => ({
  type: UPDATE_MERCHANT_ADS_QUERY,
  payload
});
var resetMerchantAdsQuery = () => ({
  type: RESET_MERCHANT_ADS_QUERY
});
var setTradeOrderId = (payload) => ({
  type: SET_TRADE_ORDER_ID,
  payload
});
var toggleTradeView = (payload) => ({
  type: TOGGLE_TRADE_VIEW,
  payload
});

// src/redux/actions/local/userConfigActions.ts
var setUserP2pFiat = (payload) => ({
  type: SET_P2P_FIAT,
  payload
});
var setUserP2pCrypto = (payload) => ({
  type: SET_P2P_CRYPTO,
  payload
});
var resetUserP2pConfig = () => ({
  type: RESET_P2P_CONFIG
});

// src/redux/actions/websocket/dataWsActions.ts
var setMktData = (payload) => ({
  type: MKT_UPDATE,
  payload
});
var setMktError = (error3) => ({
  type: error3(MKT_UPDATE),
  error: error3
});

// src/redux/drivers/index.ts
var drivers_exports = {};
__export(drivers_exports, {
  account: () => account,
  api: () => api,
  auth: () => auth2,
  bitcode: () => bitcode,
  defaultDriver: () => defaultDriver,
  p2p: () => p2p,
  util: () => util,
  wallet: () => wallet2
});

// src/redux/drivers/axios/default.ts
import axios4 from "axios";
import { createDriver } from "@redux-requests/axios";
var axiosInstance2 = axios4.create({
  baseURL: apiConfig.restBaseUrl,
  withCredentials: true
});
axiosInstance2.interceptors.request.use(UpdateTokenOnRequest);
var defaultDriver = createDriver(axiosInstance2);

// src/redux/drivers/axios/localApi.ts
import { createDriver as createDriver2 } from "@redux-requests/axios";
import axios5 from "axios";
var axiosInstance3 = axios5.create({
  baseURL: "/api",
  withCredentials: true
});
axiosInstance3.interceptors.request.use(UpdateTokenOnRequest);
var api = createDriver2(axiosInstance3);

// src/redux/drivers/graphql/graphqlDriver.ts
import axios6 from "axios";
import { extractFiles } from "extract-files";
var calculateProgress = (progressEvent) => {
  return parseInt(String(progressEvent.loaded / progressEvent.total * 100), 10);
};
var createGqlDriver = ({
  headers,
  ...rest
}) => {
  const axiosInstance5 = axios6.create({ headers, ...rest });
  axiosInstance5.interceptors.request.use(UpdateTokenOnRequest);
  return (requestConfig, requestAction, driverActions) => {
    const abortSource = axios6.CancelToken.source();
    const { clone, files } = extractFiles({
      query: requestConfig.query,
      variables: requestConfig.variables
    });
    let data;
    if (files.size === 0) {
      data = clone;
    } else {
      data = new FormData();
      data.append("operations", JSON.stringify(clone));
      let i = 0;
      const map = {};
      files.forEach((paths) => {
        map[i++] = paths;
      });
      data.append("map", JSON.stringify(map));
      i = 0;
      files.forEach((_, file) => {
        data.append(String(i++), file, file.name);
      });
    }
    const responsePromise = axiosInstance5({
      ...rest,
      cancelToken: abortSource.token,
      method: "post",
      data,
      headers: { ...headers, ...requestConfig.headers },
      onDownloadProgress: driverActions.setDownloadProgress && ((progressEvent) => {
        if (progressEvent.lengthComputable) {
          driverActions.setDownloadProgress(calculateProgress(progressEvent));
        }
      }),
      onUploadProgress: driverActions.setUploadProgress && ((progressEvent) => {
        if (progressEvent.lengthComputable) {
          driverActions.setUploadProgress(calculateProgress(progressEvent));
        }
      })
    }).then((response) => {
      if (response.data.errors) {
        throw response;
      } else {
        return response.data;
      }
    }).catch((error3) => {
      if (axios6.isCancel(error3)) {
        throw "REQUEST_ABORTED";
      }
      throw error3;
    });
    responsePromise.cancel = () => abortSource.cancel();
    return responsePromise;
  };
};

// src/redux/drivers/graphql/graphqlUtil.ts
var util = createGqlDriver({
  url: `${apiConfig.gqlBaseUrl}/util`
});

// src/redux/drivers/graphql/graphqlAuth.ts
var auth2 = createGqlDriver({
  url: `${apiConfig.gqlBaseUrl}/auth`,
  withCredentials: true
});

// src/redux/drivers/graphql/graphqlWallet.ts
var wallet2 = createGqlDriver({
  url: `${apiConfig.gqlBaseUrl}/wallet`,
  withCredentials: true
});

// src/redux/drivers/graphql/graphqlAccount.ts
var account = createGqlDriver({
  url: `${apiConfig.gqlBaseUrl}/account`,
  withCredentials: true
});

// src/redux/drivers/graphql/graphqlBitcode.ts
var bitcode = createGqlDriver({
  url: `${apiConfig.gqlBaseUrl}/bitcode`,
  withCredentials: true
});

// src/redux/drivers/graphql/graphqlP2p.ts
var p2p = createGqlDriver({
  url: `${apiConfig.gqlBaseUrl}/p2p`,
  withCredentials: true
});

// src/redux/interceptors/onAbort.ts
var onAbort = (requestAction, store) => {
};

// src/helpers/common.helper.ts
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { AsYouType } from "libphonenumber-js";
dayjs.extend(advancedFormat);
var iconBaseUrl = "https://res.cloudinary.com/bitbarter/image/upload/web-assets/coin-logos";
var mungeEmailAddress = (s) => {
  if (!(s == null ? void 0 : s.length))
    return s;
  const i = s.indexOf("@");
  const startIndex = i * 0.2 | 0;
  const endIndex = i * 0.9 | 0;
  return s.slice(0, startIndex) + s.slice(startIndex, endIndex).replace(/./g, "*") + s.slice(endIndex);
};
var mungReferralCode = (s) => {
  if (typeof s === "string" && s.length > 5) {
    return `${s.slice(0, 2)}***${s.slice(-3)}`;
  }
  return s;
};
var mungPhoneNumber = (s) => {
  if (!(s == null ? void 0 : s.length))
    return s;
  const _le = s.length - 7;
  if (_le <= 0)
    return s;
  return s.slice(0, 4) + Array(_le).fill("*").join("") + s.slice(-3);
};
var phoneNumber = (_text) => {
  const asYouType = new AsYouType();
  return ((_asYouType) => {
    _asYouType.input(_text);
    return _asYouType;
  })(asYouType);
};
var getCurrencySymbol = (currencyCode) => {
  if (typeof currencyCode !== "string") {
    return currencyCode;
  }
  const code = currencyCode.toUpperCase();
  if (!Object.keys(currencySymbols).includes(code)) {
    return code;
  }
  return currencySymbols[code];
};
var cryptoName = (currencyCode) => {
  const code = currencyCode.toUpperCase();
  if (!Object.keys(cryptoNames).includes(code)) {
    return code;
  }
  return cryptoNames[code];
};
var toFiatWithSymbol = (amount, currencyCode, decimals = 2) => {
  const symbol = getCurrencySymbol(currencyCode);
  const _formatted = toCommaValue(amount, decimals);
  return `${symbol} ${_formatted}`;
};
var getNetworkName = (network) => {
  return networkNames[network] ?? network;
};
var getCoinLogoSrc = (coin) => {
  return `${iconBaseUrl}/${coin.toLowerCase()}.png`;
};
var toLowerFixed = (amt, decimals) => {
  var _a;
  const xplr = 10 ** (decimals ?? 0);
  return (_a = Math.floor(amt * xplr) / xplr) == null ? void 0 : _a.toFixed(decimals);
};
var toCommaValue = (amount, decimals = 2) => {
  var _a, _b;
  if (isNaN(parseFloat(String(amount))))
    return "";
  const xplr = 10 ** decimals;
  const toFixed = (_a = Math.floor(+amount * xplr) / xplr) == null ? void 0 : _a.toFixed(decimals);
  const parts = toFixed.split(".", 2);
  const wholePart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const decimalPart = decimals > 2 ? ((_b = String(parseFloat(toFixed)).split(".")) == null ? void 0 : _b[1]) ?? "0" : parts[1];
  return `${wholePart}.${decimalPart}`;
};
var sizeToMegabyte = (size) => {
  var _a;
  if (isNaN(parseFloat(String(size))) || +size <= 0)
    return 0;
  return parseFloat((_a = +size / 1024 / 1024) == null ? void 0 : _a.toFixed(4));
};
var handleNavigate = (location, newTab = false) => {
  const link = document.createElement("a");
  link.href = location;
  if (newTab) {
    link.setAttribute("target", "_blank");
  }
  link.click();
};

// src/helpers/account.helper.ts
var getAccountLevelPercent = (level) => {
  if (level < 1)
    return 0;
  if (level > 4)
    return 100;
  const _percent = level / 4 * 100;
  return parseInt(String(_percent), 10);
};
var getAccountLevelData = (accLevel) => {
  const _levelData = accountLevelData[accLevel];
  const percent = getAccountLevelPercent(_levelData.level);
  return { ..._levelData, percent };
};
var formatDailyLimit = (limit, currencyCode = "USD", decimals = 2) => {
  return toFiatWithSymbol(limit, currencyCode, decimals);
};

// src/helpers/api.helpers.ts
import axios7 from "axios";
import path from "path";
var axiosInstance4 = axios7;
axiosInstance4.defaults.timeout = 5e3;
axiosInstance4.defaults.baseURL = apiConfig.restBaseUrl;
var buildRestServicePath = (segments = []) => {
  let reqPath = "";
  if (Array.isArray(segments)) {
    segments.forEach((p) => {
      reqPath = path.join(reqPath, p);
    });
  } else {
    reqPath = path.join(reqPath, segments);
  }
  return reqPath;
};

// src/helpers/asset.helper.ts
function sortAssets(assets, order) {
  const sorted = [];
  order.map((currency) => {
    assets == null ? void 0 : assets.map((asset) => {
      asset.currency == currency && sorted.push(asset);
    });
  });
  return sorted;
}

// src/helpers/axios-error.helper.ts
var transformAxiosError = (error3, defaultErrorMessage) => {
  var _a, _b;
  const responseData = (_a = error3 == null ? void 0 : error3.response) == null ? void 0 : _a.data;
  const message = (responseData == null ? void 0 : responseData.message) ?? defaultErrorMessage ?? "We encountered an unexpected error. Please retry.";
  const data = (responseData == null ? void 0 : responseData.data) ?? null;
  const code = (responseData == null ? void 0 : responseData.code) ?? "UNKNOWN_ERROR";
  const statusCode = ((_b = error3 == null ? void 0 : error3.response) == null ? void 0 : _b.status) ?? "000";
  return { statusCode, message, data, code };
};

// src/helpers/error.helper.ts
var transformGQLAxiosError = (error3) => {
  var _a, _b, _c, _d;
  const message = ((_b = (_a = error3 == null ? void 0 : error3.data) == null ? void 0 : _a.errors[0]) == null ? void 0 : _b.message) ?? "We encountered unexpected error. Please retry.";
  const extensions = ((_d = (_c = error3 == null ? void 0 : error3.data) == null ? void 0 : _c.errors[0]) == null ? void 0 : _d.extensions) ?? null;
  const data = extractValidationErrors(extensions == null ? void 0 : extensions.data);
  const code = (extensions == null ? void 0 : extensions.code) ?? "UNKNOWN_ERROR";
  const statusCode = (extensions == null ? void 0 : extensions.statusCode) ?? "000";
  return { statusCode, message, data, code };
};
var extractValidationErrors = (data) => {
  if (!data)
    return null;
  const errors = {};
  Object.keys(data).forEach((key) => {
    errors[key] = data[key][0];
  });
  return errors;
};
var getFieldErr = (fieldKey, errorWithMsg, errorMap) => {
  return (errorWithMsg && errorWithMsg[fieldKey] && errorWithMsg[fieldKey]["message"]) ?? (errorMap && errorMap[fieldKey]) ?? null;
};

// src/helpers/extractResponse.ts
function extractResponse(response, key) {
  return response[key] || response;
}
var extractResponseData = (data) => {
  const [, val] = Object.entries(data).find(([k]) => k !== "__typename");
  return val;
};

// src/helpers/fetcher.helper.ts
import axios8 from "axios";
var getFetcher = (url) => axios8.get(url).then((response) => response.data);
var urlWithSearchParams = (url, params) => {
  const queryParams = new URLSearchParams(params).toString();
  return queryParams.length ? `${url}?${queryParams}` : url;
};

// src/helpers/form.helpers.ts
var asset2Select = (assets, defaultKey) => {
  var _a, _b;
  const defaultOption = ((_a = assets == null ? void 0 : assets.filter((asset) => asset.code == defaultKey)) == null ? void 0 : _a.map((asset) => ({
    key: asset.code,
    label: asset.title,
    isDefault: true
  }))) ?? [];
  const others = ((_b = assets == null ? void 0 : assets.filter((asset) => asset.code !== defaultKey)) == null ? void 0 : _b.map((asset) => ({
    key: asset.code,
    label: asset.title,
    isDefault: false
  }))) ?? [];
  return defaultOption.concat(others);
};
var asset2NetworkAddresses = (assets) => {
  const assetMap = {};
  assets == null ? void 0 : assets.forEach((asset) => {
    assetMap[asset.currency] = {
      currency: asset.currency,
      networkSelect: asset.addresses.map((address) => ({
        network: address.network,
        meta: {
          address: address.address,
          memo: address.memo
        }
      }))
    };
  });
  return assetMap;
};
var getNetworks = (cryptoAssets2, selectedCurrency, setSelectedNetwork) => {
  let defaultNetwork;
  let networks;
  let _asset = cryptoAssets2 == null ? void 0 : cryptoAssets2.find((_a) => _a.symbol === selectedCurrency);
  defaultNetwork = { network: _asset == null ? void 0 : _asset.networks[0].network.symbol };
  networks = _asset == null ? void 0 : _asset.networks.map((network) => ({
    network: network.network.symbol
  }));
  setSelectedNetwork(defaultNetwork);
  return { networks, defaultNetwork };
};

// src/helpers/time.helper.ts
import dayjs2 from "dayjs";
var displayTimeout = (_seconds) => {
  const _string = String(_seconds);
  const _float = parseFloat(_string);
  const _h = Math.floor(_float / 3600);
  const _m = Math.floor(_float / 60 - _h * 60);
  const _s = _float % 60;
  const _hs = String(`0${_h}:`).slice(-3);
  const _ms = String(`0${_m}:`).slice(-3);
  const _ss = String(`0${_s}`).slice(-2);
  return _hs + _ms + _ss;
};
var toFormDate = (_date) => {
  if (!_date)
    return "";
  return dayjs2(_date).format("YYYY-MM-DD");
};
var toFormDisplayDate = (_date) => {
  if (!_date)
    return "";
  return dayjs2(Number(_date)).format("YYYY-MM-DD");
};
var toFormDateTime = (_date) => {
  if (!_date)
    return "";
  return dayjs2(_date).format("YYYY-MM-DD HH:mm:ss");
};
var toDisplayDate = (_date) => {
  if (!_date)
    return "";
  return dayjs2(_date).format("MMM DD, YYYY");
};
var formatDateTime = (date) => {
  return dayjs2(date).format("MMMM Do, YYYY | hh:mm a");
};
var dateAndTimeReadable = (date) => {
  if (!date)
    return "";
  return `${new Date(date).toDateString().substring(4)} - ${new Date(date).toLocaleTimeString()}`;
};

// src/redux/interceptors/reduxOnError.ts
var reduxOnError = async (error3, requestAction, store) => {
  var _a, _b, _c, _d;
  if (error3 && (error3 == null ? void 0 : error3.name) === "AxiosError" && (error3 == null ? void 0 : error3.code) === "ERR_NETWORK") {
    throw new ReduxRequestException({
      message: "Network connection error.",
      statusCode: 500,
      code: "ERR_NETWORK",
      data: null
    });
  }
  const transformedError = transformGQLAxiosError(error3);
  if (+((transformedError == null ? void 0 : transformedError.statusCode) ?? 0) == 401) {
    try {
      const response = await refreshUserAuth();
      if (response.authToken && response.expiresAt) {
        AuthCookie_default.setAuthToken(response.authToken, response.expiresAt);
      }
      if (response.refreshAuthToken && response.refreshExpiresAt) {
        AuthCookie_default.setAuthRefreshToken(response.refreshAuthToken, response.refreshExpiresAt);
      }
    } catch (e) {
      const event = new Event(LOGOUT_EVENT);
      window && window.dispatchEvent(event);
      throw new ReduxRequestException({
        message: e.message,
        statusCode: e.statusCode,
        code: (_b = (_a = e == null ? void 0 : e.response) == null ? void 0 : _a.data) == null ? void 0 : _b.code,
        data: (_d = (_c = e == null ? void 0 : e.response) == null ? void 0 : _c.data) == null ? void 0 : _d.data
      });
    }
    const resendResponse = await store.dispatch(requestAction);
    if (resendResponse.error) {
      throw new ReduxRequestException(transformGQLAxiosError(resendResponse.error));
    }
    if (resendResponse.data) {
      return resendResponse;
    }
  }
  throw new ReduxRequestException(transformGQLAxiosError(error3));
};

// src/redux/interceptors/reduxOnRequest.ts
var reduxOnRequest = (request, requestAction, store) => {
  return request;
};

// src/redux/interceptors/reduxOnSuccess.ts
var reduxOnSuccess = (response, requestAction, store) => {
  return response;
};

// src/formValidation/account.schema.ts
import * as yup from "yup";
var addPaymentMethodSchema = (options) => {
  const shape = {};
  for (const o of options) {
    switch (o.type) {
      case "number":
        shape[o.name] = yup.number().typeError(`${o.fieldNameText} must be a number`);
        break;
      case "email":
        shape[o.name] = yup.string().typeError(`${o.fieldNameText} must be a string`).email(`${o.fieldNameText} is not a valid email`);
        break;
      default:
        shape[o.name] = yup.string().typeError(`${o.fieldNameText} must be a string`);
    }
    if (o.required) {
      shape[o.name] = shape[o.name].required(`${o.fieldNameText} is required`);
    }
    if (o.minimum && o.required) {
      shape[o.name] = shape[o.name].min(o.minimum, `Minimum length for ${o.fieldNameText} is ${o.minimum}`);
    }
    if (o.maximum && o.required) {
      shape[o.name] = shape[o.name].max(o.maximum, `Maximnum length for ${o.fieldNameText} is ${o.maximum}`);
    }
    if (o.match) {
      const { flags, source } = JSON.parse(o.match);
      shape[o.name] = shape[o.name].matches(RegExp(source, flags), `Invalid ${o.fieldNameText}`);
    }
  }
  return yup.object().shape(shape);
};
var initKycSchema = yup.object().shape({
  country: yup.string().required("Issuer country is required."),
  flowId: yup.string().required("Document type is required.")
});

// src/formValidation/common.ts
import { validate as btcValidate } from "bitcoin-address-validation";
import * as addrValidator from "multicoin-address-validator";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
var IsMobileNumber = (mobileNumber) => {
  return isMobilePhone(mobileNumber, "any", { strictMode: true });
};
var IsValidEmail = (email) => {
  return isEmail(email);
};
var IsValidUsername = (username) => {
  const re = /^[a-zA-Z]\w{3,7}$/;
  return re.test(username);
};
var isValidOtp = (otp) => {
  return otp.length === 6 && /^\d+$/.test(otp);
};
var IsValidAddress = (address, network) => {
  if (!address || !network)
    return false;
  return network === "BTC" ? btcValidate(address) : addrValidator.validate(address, networkValidatorMap[network]);
};

// src/formValidation/p2p.schema.ts
import * as yup2 from "yup";
var TradeTermsValidation = yup2.string().required("Trade terms is required.").min(TRADE_TERM_CHARS.MIN, `Minimum of ${TRADE_TERM_CHARS.MIN} characters required.`).max(TRADE_TERM_CHARS.MAX, `Trade terms should not exceed ${TRADE_TERM_CHARS.MAX} characters.`);
var tradeFormSchema = ({
  minAmount,
  maxAmount,
  minQuantity,
  maxQuantity,
  tradeType,
  balance
}) => {
  const _minAmt = parseFloat((minAmount ?? 0).toFixed(2));
  const _maxAmt = parseFloat((maxAmount ?? 0).toFixed(2));
  const _minQty = parseFloat((minQuantity ?? 0).toFixed(6));
  const _maxQty = parseFloat((maxQuantity ?? 0).toFixed(6));
  return yup2.object().shape({
    paymentOptionId: yup2.string().required("Select valid payment method."),
    amount: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Numeric value requires").required("Fiat amount is required").min(_minAmt, `Amount should not be less than ${_minAmt}`).max(_maxAmt, `Amount should not be greater than ${_maxAmt}`),
    quantity: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Numeric value required").required("Crypto quantity is required").min(_minQty, `Crypto should not be less than ${_minQty}`).max(_maxQty, `Crypto should not be greater than ${_maxQty}`).test("balance", `You don't have enough balance`, (val) => tradeType === "OFFRAMP" /* OFFRAMP */ && !!val ? +val <= +balance : true)
  });
};
var adsFormSchema = (options) => {
  const {
    balance,
    orderType,
    minimumCryptoAmount,
    minimumFiatAmount,
    maxPaymentType,
    lowerRateLimit,
    upperRateLimit
  } = options;
  return yup2.object().shape({
    rateMargin: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).min(50, "Flexible pricing percenter should not be less than 50%").max(150, "Flexible pricing percentage should not be greater than 150%").required("Flexible pricing is required"),
    fiat: yup2.string().required("Fiat currency is required."),
    offerRate: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Numeric value required").required("Offer rate is required").min(lowerRateLimit, "Offer rate is below allowed rate margin").max(upperRateLimit, "Offer rate is above allowed rate margin"),
    paymentWindow: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Numeric value required").required("Payment time limit is required."),
    quantity: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Numeric value required").required("Trade volume is required.").min(minimumCryptoAmount, `Volume is less than allowed minimum qauntity: ${minimumCryptoAmount}`).test("balance", `You don't have enough balance to be locked.`, (val) => orderType === "SELL" /* SELL */ && val !== void 0 ? +val <= +balance : true),
    minQuantity: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Minimum trade limit must be numeric").test("above-minimum", `Minimum limit cannot be less than ${minimumCryptoAmount}`, (val) => val !== void 0 ? +val >= minimumCryptoAmount : true).test("is-required", "Minimum trade limit is required", (val) => orderType === "SELL" /* SELL */ ? val !== void 0 : true).test("is-positive", "Minimum trade limit must be greater than 0", (val) => val !== void 0 ? +val > 0 : true).test("min-qty-overflow", `Minimum limit is more than trade volume`, (val, _this) => val === void 0 || _this.parent.quantity === void 0 || val <= _this.parent.quantity),
    maxQuantity: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Maximum trade limit must be numeric").test("is-positive", "Maximum trade limit must be greater than 0", (val) => val !== void 0 ? +val > 0 : true).test("max-overflow", `Maximum limit is more than trade volume`, (val, _this) => val === void 0 || _this.parent.quantity === void 0 || val <= _this.parent.quantity).test("max-underflow", `Maximum limit is less than minimum limit`, (val, _this) => val === void 0 || _this.parent.minQuantity === void 0 || val >= _this.parent.minQuantity).optional(),
    minOrderAmount: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Minimum trade limit must be numeric").test("above-minimum", `Minimum limit cannot be less than ${minimumFiatAmount}`, (val) => val !== void 0 ? +val >= minimumFiatAmount : true).test("is-required", "Minimum trade limit is required", (val) => orderType === "BUY" /* BUY */ ? val !== void 0 : true).test("is-positive", "Minimum trade limit must be greater than 0", (val) => val !== void 0 ? +val > 0 : true).test("min-overflow", `Minimum limit is more than trade volume`, (val, _this) => {
      const _max = (_this.parent.offerRate ?? 0) * (_this.parent.quantity ?? 0);
      return _this.parent.offerRate === void 0 || _this.parent.quantity == void 0 || val === void 0 || val <= _max;
    }),
    maxOrderAmount: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Maximum trade limit must be numeric").test("is-positive", "Maximum trade limit must be greater than 0", (val) => val !== void 0 ? +val > 0 : true).test("max-overflow", `Maximum limit is more than trade volume`, (val, _this) => {
      const _max = (_this.parent.offerRate ?? 0) * (_this.parent.quantity ?? 0);
      return _this.parent.offerRate === void 0 || _this.parent.quantity == void 0 || val === void 0 || val <= _max;
    }).test("max-underflow", `Maximum is less than minimum limit`, (val, _this) => {
      return _this.parent.minOrderAmount === void 0 || val === void 0 || val >= _this.parent.minOrderAmount;
    }).optional(),
    paymentChannelSlugs: yup2.array().max(maxPaymentType, `Only ${maxPaymentType} channels allowed.`).test("require-channel", "Select at least on payment channel", (val) => orderType !== "BUY" /* BUY */ || !!(val == null ? void 0 : val.length)),
    paymentOptionIds: yup2.array().max(maxPaymentType, `Only ${maxPaymentType} payment methods allowed.`).test("require-channel", "Select at least on payment method", (val) => orderType !== "SELL" /* SELL */ || !!(val == null ? void 0 : val.length)),
    tradeTerms: TradeTermsValidation
  });
};
var flexibleMaxAdsFormSchema = (options) => {
  const adsSchema = adsFormSchema(options);
  return adsSchema.shape({
    maximumRate: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Numeric value required").required("Maximum offer rate is required").min(options.lowerRateLimit, "Maximum rate is below allowed rate margin").max(options.upperRateLimit, "Maximum rate is above allowed rate margin")
  });
};
var flexibleMinAdsFormSchema = (options) => {
  const adsSchema = adsFormSchema(options);
  return adsSchema.shape({
    minimumRate: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Numeric value required").required("Minimum offer rate is required").min(options.lowerRateLimit, "Minimum rate is below allowed rate margin").max(options.upperRateLimit, "Minimum rate is above allowed rate margin")
  });
};
var editAdFormSchema = (options) => {
  const {
    orderType,
    minimumCryptoAmount,
    minimumFiatAmount,
    maxPaymentType,
    lowerRateLimit,
    upperRateLimit,
    availableQuantity
  } = options;
  const ignoreQty = orderType === "BUY" /* BUY */;
  const ignoreAmt = orderType === "SELL" /* SELL */;
  return yup2.object().shape({
    offerRate: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Numeric value required").required("Offer rate is required").min(lowerRateLimit, "Offer rate is below allowed rate margin").max(upperRateLimit, "Offer rate is above allowed rate margin"),
    rateMargin: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).min(50, "Flexible pricing percenter should not be less than 50%").max(150, "Flexible pricing percentage should not be greater than 150%").required("Flexible pricing is required"),
    minQuantity: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Minimum trade limit must be numeric").test("above-minimum", `Minimum limit cannot be less than ${minimumCryptoAmount}`, (val) => val !== void 0 && !ignoreQty ? +val >= minimumCryptoAmount : true).test("is-required", "Minimum trade limit is required", (val) => !ignoreQty ? val !== void 0 : true).test("is-positive", "Minimum trade limit must be greater than 0", (val) => val !== void 0 && !ignoreQty ? +val > 0 : true).test("min-qty-overflow", `Minimum limit is more than trade volume`, (val) => ignoreQty || val === void 0 || availableQuantity === void 0 || val <= availableQuantity),
    maxQuantity: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Maximum trade limit must be numeric").test("is-positive", "Maximum trade limit must be greater than 0", (val) => val !== void 0 && !ignoreQty ? +val > 0 : true).test("max-overflow", `Maximum limit is more than trade volume`, (val) => ignoreQty || val === void 0 || availableQuantity === void 0 || val <= availableQuantity).test("max-underflow", `Maximum limit is less than minimum limit`, (val, _this) => ignoreQty || val === void 0 || _this.parent.minQuantity === void 0 || val >= _this.parent.minQuantity).optional(),
    minOrderAmount: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Minimum trade limit must be numeric").test("above-minimum", `Minimum limit cannot be less than ${minimumFiatAmount}`, (val) => val !== void 0 && !ignoreAmt ? +val >= minimumFiatAmount : true).test("is-required", "Minimum trade limit is required", (val) => !ignoreAmt ? val !== void 0 : true).test("is-positive", "Minimum trade limit must be greater than 0", (val) => val !== void 0 && !ignoreAmt ? +val > 0 : true).test("min-overflow", `Minimum limit is more than trade volume`, (val, _this) => {
      const _max = (_this.parent.offerRate ?? 0) * (availableQuantity ?? 0);
      return ignoreAmt || _this.parent.offerRate === void 0 || availableQuantity == void 0 || val === void 0 || val <= _max;
    }),
    maxOrderAmount: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Maximum trade limit must be numeric").test("is-positive", "Maximum trade limit must be greater than 0", (val) => val !== void 0 && !ignoreAmt ? +val > 0 : true).test("max-overflow", `Maximum limit is more than trade volume`, (val, _this) => {
      const _max = (_this.parent.offerRate ?? 0) * (availableQuantity ?? 0);
      return ignoreAmt || _this.parent.offerRate === void 0 || availableQuantity === void 0 || val === void 0 || val <= _max;
    }).test("max-underflow", `Maximum is less than minimum limit`, (val, _this) => {
      return ignoreAmt || _this.parent.minOrderAmount === void 0 || val === void 0 || val >= _this.parent.minOrderAmount;
    }).optional(),
    paymentChannelSlugs: yup2.array().max(maxPaymentType, `Only ${maxPaymentType} channels allowed.`).test("require-channel", "Select at least on payment channel", (val) => orderType !== "BUY" /* BUY */ || !!(val == null ? void 0 : val.length)),
    paymentOptionIds: yup2.array().max(maxPaymentType, `Only ${maxPaymentType} payment methods allowed.`).test("require-channel", "Select at least on payment method", (val) => orderType !== "SELL" /* SELL */ || !!(val == null ? void 0 : val.length)),
    tradeTerms: TradeTermsValidation
  });
};
var flexibleMaxEditAdsFormSchema = (options) => {
  const adsSchema = editAdFormSchema(options);
  return adsSchema.shape({
    maximumRate: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Numeric value required").required("Maximum offer rate is required").min(options.lowerRateLimit, "Maximum rate is below allowed rate margin").max(options.upperRateLimit, "Maximum rate is above allowed rate margin")
  });
};
var flexibleMinEditAdsFormSchema = (options) => {
  const adsSchema = editAdFormSchema(options);
  return adsSchema.shape({
    minimumRate: yup2.number().transform((value) => isNaN(value) ? void 0 : parseFloat(value)).typeError("Numeric value required").required("Minimum offer rate is required").min(options.lowerRateLimit, "Minimum rate is below allowed rate margin").max(options.upperRateLimit, "Minimum rate is above allowed rate margin")
  });
};
var startDisputeSchema = (maxFileSize = 3, maxFileNums = 2) => yup2.object().shape({
  title: yup2.string().required("Dispute reason is required.").min(5, "At least 5 characters required.").max(100, "Maximum of 100 characters allowed."),
  description: yup2.string().required("Dispute description is required.").min(20, "At leat 20 characters required").max(500, "Maximum of 500 characters allowed."),
  evidences: yup2.array(yup2.mixed()).min(1, "At least 1 evidence is required.").max(maxFileNums, `Maximum of ${maxFileNums} evidences allowed.`).test("fileSize", `File too large! Allowed maximum is ${maxFileSize}mb`, (value) => !!value && value.every((f) => sizeToMegabyte(f.size) <= maxFileSize)).test("allowedFileType", "Unsupported image file format", (value) => !!value && value.every((f) => SUPPORTED_IMAGE_FORMATS.includes(f.type)))
});

// src/formValidation/schemas.ts
import * as yup3 from "yup";
var loginFormSchema = yup3.object().shape({
  userEmail: yup3.string().required("Email is required.").email("Email is invalid").trim()
});
var updateProfileSchema = yup3.object().shape({
  countryCode: yup3.string(),
  dob: yup3.string(),
  firstName: yup3.string(),
  gender: yup3.string(),
  lastName: yup3.string(),
  otherNames: yup3.string()
});
var idVerification = yup3.object().shape({
  identityNumber: yup3.string().required("ID number is required"),
  type: yup3.string()
});
var referralSchema = (currentBalance) => yup3.object().shape({
  baseAmount: yup3.number().typeError("Please enter a valid number.").required("Please enter an amount to transfer.").moreThan(9e-4, "Minimum amount is 0.001").max(currentBalance, "Amount exceeds available balance.").positive("Invalid amount.")
});
var updateProfileAddressSchema = yup3.object().shape({
  countryCode: yup3.string(),
  apartmentNo: yup3.string(),
  city: yup3.string(),
  state: yup3.string(),
  street: yup3.string(),
  zipCode: yup3.string(),
  lineTwo: yup3.string()
});
var signupFormSchema = yup3.object().shape({
  username: yup3.string().required("Username is required").trim(),
  referralCode: yup3.string().trim().nullable(),
  email: yup3.string().required("Email is required").email("Email is invalid"),
  password: yup3.string().required("Password is required").min(8, "Password must have 8 characters minimum, one uppercase letter, one lowercase letter, and one numeric digit."),
  confirmPassword: yup3.string().required("Confirm password is required").oneOf([yup3.ref("password"), null], "Passwords must match"),
  terms: yup3.bool().oneOf([true], "Acceptance of terms and conditions is required.")
});
var updatePasswordSchema = yup3.object().shape({
  currentPassword: yup3.string().required("Current password is required."),
  password: yup3.string().required("Password is required").min(8, "Password must have 8 characters minimum, one uppercase letter, one lowercase letter, and one numeric digit."),
  confirmPassword: yup3.string().required("Confirm password is required").oneOf([yup3.ref("password"), null], "Passwords must match")
});
var resetPasswordSchema = yup3.object().shape({
  token: yup3.string().required("Token is required"),
  password: yup3.string().required("Password is required").min(8, "Password must have 8 characters minimum, one uppercase letter, one lowercase letter, and one numeric digit."),
  confirmPassword: yup3.string().required("Confirm password is required").oneOf([yup3.ref("password"), null], "Passwords must match")
});
var paymentMethodSchema = yup3.object().shape({
  bankAccSwiftcode: yup3.string().optional(),
  bankBranch: yup3.string().optional(),
  bankName: yup3.string().required("Bank Name is required"),
  accountNumber: yup3.string().required("Account Number is required")
});
var withdrawalOtpSchema = yup3.object().shape({
  withdrawalEmailOtp: yup3.number().required("Otp required"),
  withdrawalAppOrSmsOtp: yup3.number().required("Otp required")
});
var withdrawalSchema = yup3.object().shape({
  withdrawalAddress: yup3.string().required("Withdrawal address is required.").trim(),
  withdrawalAmount: yup3.number().typeError("Numeric value expected.").positive("Value must be positive").required("Withdrawal amount is required"),
  withdrawalEmailOtp: yup3.number().typeError("Numeric value expected.").positive("Value must be positive").required("Otp required"),
  withdrawalAppOrSmsOtp: yup3.number().typeError("Numeric value expected.").positive("Value must be positive").required("Otp required")
});
var lauchUpdateFormSchema = yup3.object().shape({
  email: yup3.string().required("Email is required").email("Email is invalid")
});
var bitcodeInvoiceSchema = yup3.object().shape({
  bbr: yup3.string().required("Bitcode invoice reference is required.").trim()
});
var withdrawalAssetSchema = (network) => yup3.object().shape({
  withdrawalAddress: yup3.string().required("address is required.").test("is-valid-address", `Value is not a valid ${network} address`, (value) => IsValidAddress(value, network)).trim(),
  withdrawalAmount: yup3.number().typeError("Numeric value expected.").moreThan(0, "Amount should be more than zero (0)").nullable(false).required("Amount is required")
});
var requestBitcodeSchema = yup3.object().shape({
  payerName: yup3.string().required("Payer's name is required.").trim(),
  payerEmail: yup3.string().required("Payer's email is required.").email("Email is invalid").trim(),
  assetAmount: yup3.number().typeError("Numeric value expected.").moreThan(0, "Amount should be more than zero (0)").nullable(false).required("Amount is required")
});
var makeBitcodePaymentSchema = yup3.object().shape({
  reference: yup3.string().required("BBR is required").trim()
});
var sendVoucherSchema = yup3.object().shape({
  recipientName: yup3.string().required("Recipient's name is required.").trim(),
  recipientEmail: yup3.string().required("Recipient's email is required.").email("Email is invalid").trim(),
  amount: yup3.number().typeError("Numeric value expected.").positive("Value must be positive").required("Amount is required")
});
export {
  ACCOUNT_LOCAL_UPDATE,
  ACCOUNT_PROPS_QUERY,
  ACTIVATE_TELEGRAM,
  ACTIVATE_TELEGRAM_MUTATION,
  ACTIVATE_WEB_PUSH,
  ACTIVATE_WEB_PUSH_MUTATION,
  ADD_MOBILE_NUMBER,
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_MUTATION,
  AUTHENTICATOR_TOKEN_LENGTH,
  AUTH_TOKEN_HEADER,
  AccMenuType,
  AccountLevelType,
  AccountStatusType,
  AnyOtp,
  AppHostUrlMap,
  AssetNetworkType,
  AssetType,
  AuthCookie_default as AuthCookie,
  AuthVerifyType,
  BitCodeType,
  BitcodeStatus,
  CANCEL_P2P_TRADE,
  CANCEL_TRADE_MUTATION,
  CHANGE_PASSWORD,
  CHANGE_WALLET_CURRENCY,
  CLEAR_SESSION,
  CLOSE_MERCHANT_AD,
  CLOSE_MERCHANT_AD_MUTATION,
  CONFIRM_P2P_TRADE,
  CONFIRM_P2P_TRADE_MUTATION,
  COUNTRIES,
  CREATE_ACCOUNT,
  CUSTOMER_TRADES_QUERY,
  DEFAULT_FIAT,
  DELETE_A_PAYMENT_OPTION,
  DELETE_PAYMENT_OPTION,
  DELETE_PAYMENT_OPTION_MUTATION,
  DISABLE_LOGIN_2FA,
  DISABLE_TELEGRAM,
  DISABLE_TELEGRAM_MUTATION,
  DISABLE_WEB_PUSH,
  DISABLE_WEB_PUSH_MUTATION,
  DataSub,
  DisputeFrom,
  EDIT_PAYMENT_METHOD_MUTATION,
  EMAIL_TOKEN_LENGTH,
  ENABLE_LOGIN_2FA,
  ENABLE_TELEGRAM,
  ENABLE_TELEGRAM_MUTATION,
  ENABLE_WEB_PUSH,
  ENABLE_WEB_PUSH_MUTATION,
  FETCH_CLIENT_DATA,
  GET_ACCOUNT,
  GET_ACCOUNT_QUERY,
  GET_ADVERTISED_RATE,
  GET_AD_ORDERS,
  GET_ASSET_ADDRESSES,
  GET_ASSET_ADDRESSES_QUERY,
  GET_ASSET_RATE,
  GET_ASSET_RATE_QUERY,
  GET_AUTH_USER,
  GET_AUTH_USER_QUERY,
  GET_BITCODES,
  GET_BITCODES_QUERY,
  GET_BITCODE_FEE,
  GET_BITCODE_FEE_QUERY,
  GET_BITCODE_INVOICE,
  GET_BITCODE_INVOICE_QUERY,
  GET_CONFIGS,
  GET_CONFIGS_QUERY,
  GET_COUNTRIES,
  GET_CRYPTO_ASSETS,
  GET_CUSTOMER_TRADES,
  GET_FIAT_ASSETS,
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_QUERY,
  GET_LANGUAGES,
  GET_MARKETPLACE_ADS,
  GET_MERCHANT_ADS,
  GET_MERCHANT_AD_QUERY,
  GET_MERCHANT_PROFILE,
  GET_MERCHANT_PROFILE_QUERY,
  GET_OFFRAMP_ORDER,
  GET_ONRAMP_ORDER,
  GET_P2P_ASSET_TRADE_PAIRS,
  GET_PAYMENT_CHANNELS,
  GET_PROFILE,
  GET_PROFILE_QUERY,
  GET_RECENT_BLOG_POSTS,
  GET_RECENT_BLOG_POSTS_QUERY,
  GET_TIMEZONES,
  GET_TRADE_QUERY,
  GET_TRANSACTION,
  GET_TRANSACTIONS_QUERY,
  GET_VOUCHER_DETAILS,
  GET_VOUCHER_DETAILS_QUERY,
  GET_WALLET,
  GET_WALLET_QUERY,
  GET_WITHDRAWAL_FEE,
  GET_WITHDRAWAL_FEE_QUERY,
  GRANT_CHAT_TOKEN_MUTATION,
  HomeMenuType,
  INIT_2FA_SETUP,
  INIT_KYC_VERIFICATION,
  INIT_LOGIN,
  INIT_PASSWORD_RESET,
  IdentityType,
  IsMobileNumber,
  IsValidAddress,
  IsValidEmail,
  IsValidUsername,
  KycProvider,
  LOAD_FULL_ACCOUNT,
  LOCAL_AUTH_COOKIE_NAME,
  LOGOUT,
  LOGOUT_EVENT,
  LocalCookie_default as LocalCookie,
  MARK_TRADE_AS_PAID,
  MARK_TRADE_PAID_MUTATION,
  MAX_MULTI_FILES_COUNT,
  MAX_UPLOAD_FILE_SIZE,
  MERCHANT_ADS_QUERY,
  MERCHANT_ORDERS_QUERY,
  MKT_UPDATE,
  MXN_INIT_KYC_VERIFICATION,
  MXN_SUBMIT_KYC_VERIFICATION,
  MonthFullNames,
  MonthShortNames,
  OrderStatus,
  OrderType,
  OtpReqType,
  P2P_ADS_QUERY,
  PAUSE_OR_PLAY_AD,
  PAUSE_PLAY_AD_MUTATION,
  PHONE_TOKEN_LENGTH,
  POST_AD_MUTATION,
  POST_MERCHANT_AD,
  POST_OFFRAMP_MMUTAION,
  POST_OFFRAMP_TRADE,
  POST_ONRAMP_MUTATION,
  POST_ONRAMP_TRADE,
  PageOrder,
  PhoneAppOtp,
  PhoneEmailOtp,
  QUERY_SINGLE_AD,
  QUERY_SINGLE_P2P_TRADE,
  RAISE_TRADE_DISPUTE,
  RAISE_TRADE_DISPUTE_MUTATION,
  RATE_TRADE,
  RATE_TRADE_MUTATION,
  RATE_UPDATE,
  RECALL_VOUCHER,
  RECALL_VOUCHER_MUTATION,
  REDEEM_BITCODE_VOUCHER,
  REDEEM_VOUCHER_MUTATION,
  REFRESH_CONFIG_HEADER,
  REFRESH_LOGIN,
  REQUEST_CHAT_TOKEN,
  REQUEST_OTP,
  REQ_ADD_MOBILE_NUMBER,
  REQ_CHANGE_CURRENCY,
  REQ_CHANGE_PASSWORD,
  REQ_CONFIRMATION_OTP,
  REQ_CREATE_ACCOUNT,
  REQ_DISABLE_LOGIN_2FA,
  REQ_ENABLE_LOGIN_2FA,
  REQ_INIT_2FA_SETUP,
  REQ_LOGIN,
  REQ_LOGIN_SMS,
  REQ_LOGOUT,
  REQ_PASSWORD_RESET,
  REQ_REFRESH_AUTH,
  REQ_RESET_PASSWORD,
  REQ_VERIFY_2FA_SETUP,
  REQ_VERIFY_EMAIL,
  REQ_VERIFY_EMAIL_OTP,
  REQ_VERIFY_LOGIN,
  REQ_VERIFY_PHONE,
  REQ_VERIFY_PHONE_OTP,
  RESEND_INVOICE,
  RESEND_INVOICE_MUTATION,
  RESEND_RESET_TOKEN,
  RESEND_VOUCHER,
  RESEND_VOUCHER_MUTATION,
  RESET_MERCHANT_ADS_QUERY,
  RESET_MERCHANT_ORDER_QUERY,
  RESET_ORDER_QUERY,
  RESET_P2P_CONFIG,
  RESET_PASSWORD,
  RESET_TRADE_QUERY,
  RESOLVE_TRADE_DISPUTE,
  RESOLVE_TRADE_DISPUTE_MUTATION,
  ReduxRequestException,
  SEND_BITCODE_INVOICE,
  SEND_BITCODE_INVOICE_MUTATION,
  SEND_BITCODE_VOUCHER,
  SEND_BITCODE_VOUCHER_MUTATION,
  SEND_LOGIN_SMS,
  SEND_VERIFY_EMAIL_OTP,
  SEND_VERIFY_PHONE_OTP,
  SETTLE_BITCODE_INVOICE,
  SETTLE_BITCODE_INVOICE_MUTATION,
  SET_LOGGED_IN,
  SET_P2P_CRYPTO,
  SET_P2P_FIAT,
  SET_TRADE_ORDER_ID,
  SKIP_INTERCEPTOR_ERROR,
  SUBMIT_KYC_VERIFICATION,
  SUPPORTED_IMAGE_FORMATS,
  SecureLevel,
  StoreKeys,
  TEN_MINUTES_TIMEOUT,
  TOGGLE_BALANCE,
  TOGGLE_SIDE_MENU,
  TOGGLE_TRADE_VIEW,
  TOKEN_HEADER_NAME,
  TRADE_TERM_CHARS,
  TWO_MINUTES_TIMEOUT,
  ThemeType,
  TradeFilterStatus,
  TradeStatus,
  TradeType,
  TxCategory,
  TxStatus,
  TxType,
  UPDATE_ADDRESS,
  UPDATE_MERCHANT_AD,
  UPDATE_MERCHANT_ADS_QUERY,
  UPDATE_MERCHANT_AD_MUTATION,
  UPDATE_MERCHANT_ORDER_QUERY,
  UPDATE_ORDER_QUERY,
  UPDATE_PAYMENT_OPTION,
  UPDATE_PAYMENT_OPTION_MUTATION,
  UPDATE_PREFERENCE,
  UPDATE_PROFILE_ADDRESS_MUTATION,
  UPDATE_PROFILE_PREFERENCE_MUTATION,
  UPDATE_TRADE_QUERY,
  UserGender,
  UserRole,
  UserVerificationStatusMap,
  VERIFY_2FA_SETUP,
  VERIFY_EMAIL,
  VERIFY_LOGIN,
  VERIFY_PHONE_NUMBER,
  VOUCHER_PIN_LENGTH,
  VerificationStatus,
  VerificationType,
  WITHDRAW_ASSET,
  WITHDRAW_ASSET_MUTATION,
  WalletCurrency,
  WeekdaysShort,
  accountLevelData,
  activateLogin2fa,
  addPaymentMethod,
  addPaymentMethodSchema,
  addPhoneNumber,
  addressUpdate,
  ads,
  adsFormSchema,
  clients_default as apiClients,
  apiConfig,
  appConfigs,
  asset2NetworkAddresses,
  asset2Select,
  assetAddresses,
  auth,
  auth2fa,
  authLogin,
  authLogout,
  config_default as axiosClient,
  axiosInstance4 as axiosInstance,
  baseRefUrl,
  becomeMerchantLink,
  bitcodeFee,
  bitcodeInvoiceDetailsRequest,
  bitcodeInvoiceSchema,
  bitcodePaymentRequest,
  bitcodeRecallVoucher,
  bitcodeRedeemVoucher,
  bitcodeResendInvoice,
  bitcodeResendVoucher,
  bitcodeSendVoucher,
  bitcodeSettlementRequest,
  bitcodeStatuses,
  bitcodeVoucherDetailsRequest,
  bitcodes,
  blogUrl,
  buildRestServicePath,
  cancelP2pTrade,
  cancelTrade,
  chackraCustomeTheme,
  changePassword,
  chatToken,
  client,
  closeAds,
  closeMerchantAd,
  confirmP2PTradePayment,
  confirmP2pTrade,
  countries,
  countryOptions,
  createAccount,
  createAxiosReducer,
  createGraphqlReducer,
  createMultiKeyGraphqlReducer,
  createOrUpdateWebhookConfigs,
  createReferralCodes,
  cryptoAssets,
  cryptoName,
  cryptoNames,
  currencySymbols,
  customerTradeQuery,
  dateAndTimeReadable,
  deleteAuthenticator,
  deletePaymentOption,
  desktopMenuItems,
  disableLogin2fa,
  disableWebPush,
  displayTimeout,
  editAdFormSchema,
  environment,
  extractResponse,
  extractResponseData,
  extractValidationErrors,
  faqLink,
  fiatAssets,
  flexibleMaxAdsFormSchema,
  flexibleMaxEditAdsFormSchema,
  flexibleMinAdsFormSchema,
  flexibleMinEditAdsFormSchema,
  formatDailyLimit,
  formatDateTime,
  getAccountKyc,
  getAccountLevelData,
  getAccountLevelPercent,
  getAdOrders,
  getAppConfigs,
  getAssetAddresses,
  getAssetRate,
  getAuthStatus,
  getAuthUser,
  getBanks,
  getBitcodeFee,
  getBitcodes,
  getChannelChatToken,
  getClientData,
  getCoinLogoSrc,
  getCountries,
  getCountryByCode,
  getCountryTelCode,
  getCurrencySymbol,
  getCustomerTrades,
  getEarningById,
  getEarningStats,
  getEarningTransferById,
  getEarningTransfers,
  getEarnings,
  getFetcher,
  getFieldErr,
  getFromCache,
  getInvoice,
  getInvoiceAction,
  getInvoiceDetails,
  getKycFlows,
  getMarketplaceAds,
  getMerchantAd,
  getMerchantAds,
  getMerchantProfile,
  getNetworkName,
  getNetworks,
  getOfframpTradeOrder,
  getOnrampTradeOrder,
  getRecentBlogPosts,
  getReferralCodeByCode,
  getReferralCodes,
  getReferrals,
  getReferralsStat,
  getTimezones,
  getTransactions,
  getUser,
  getVoucherDetails,
  getWallet,
  getWebPushKey,
  getWebhookConfigs,
  getWebhookEvents,
  getWithdrawalFee,
  handleBestRate,
  handleDataResetAction,
  handleDataUpdateAction,
  handleNavigate,
  handleRateConvert,
  homeFooterPaths,
  homeHeaderPaths,
  idVerification,
  initKycSchema,
  initTelegramSetup,
  isValidOtp,
  keepInCache,
  language,
  languages,
  lauchUpdateFormSchema,
  loadCountries,
  loadCryptoAssets,
  loadFiatAssets,
  loadLanguages,
  loadP2pTradePairs,
  loadPaymentChannels,
  loadTimezones,
  loadUser,
  login,
  loginFormSchema,
  loginOrRegisterUser,
  logonUser,
  logout,
  makeBitcodePaymentSchema,
  markP2pTradePaid,
  markTradeAsPaid,
  marketOrderQuery,
  marketplace,
  me,
  merchantAd,
  merchantAdsQuery,
  merchantOrderQuery,
  merchantProfile,
  mktRate,
  mktUpdate,
  mungPhoneNumber,
  mungReferralCode,
  mungeEmailAddress,
  networkNames,
  networkValidatorMap,
  onAbort,
  orderStatuses,
  orderTypes,
  orders,
  otpRequest,
  p2pTradePairs,
  passwordResetReq,
  pauseOrPlayAd,
  paymentChannels,
  paymentMethodSchema,
  phoneNumber,
  postAdRequest,
  postMerchantAd,
  postOfframpTrade,
  postOnrampTrade,
  querySingleP2pTrade,
  raiseDispute,
  rateTrade,
  recallVoucher,
  recentBlogPosts,
  redeemVoucher,
  actions_exports as reduxActions,
  drivers_exports as reduxDrivers,
  reduxOnError,
  reduxOnRequest,
  reduxOnSuccess,
  reducers_exports as reduxReducers,
  reduxRequestReset,
  referralSchema,
  refreshAuth,
  refreshUserAuth,
  register,
  reqLoginOtp,
  reqVerifyEmailOtp,
  requestBitcodeSchema,
  requestChatToken,
  requestOtp,
  requestPasswordReset,
  resendInvoice,
  resendLoginToken,
  resendVoucher,
  resetAuthenticator,
  resetCustomerTradeQuery,
  resetMarketOrderQuery,
  resetMerchantAdsQuery,
  resetMerchantOrderQuery,
  resetPassword,
  resetPasswordReq,
  resetPasswordSchema,
  resetUserAccount,
  resetUserP2pConfig,
  resetWallet,
  resetWebhookSecret,
  resolveTradeDispute,
  routes,
  sendInvoice,
  sendLoginSms,
  sendPhoneVerifyOtp,
  sendVoucher,
  sendVoucherSchema,
  setDisplayCurrency,
  setDisplayCurrenyRequest,
  setMktData,
  setMktError,
  setTradeOrderId,
  setUserP2pCrypto,
  setUserP2pFiat,
  setUserPhoneNumber,
  settleInvoice,
  setupAuthenticator,
  setupWebPush,
  signupFormSchema,
  sizeToMegabyte,
  socialUrls,
  sortAssets,
  startDisputeSchema,
  startKycVerification,
  submitKycVerification,
  telegramLink,
  termsLink,
  themeColors,
  themeConfig2 as themeConfig,
  themeFontSizes,
  themeFontWeights,
  themeFonts,
  themeLetterSpacings,
  themeLineHeights,
  timeLimitOptions,
  timezones,
  toCommaValue,
  toDisplayDate,
  toFiatWithSymbol,
  toFormDate,
  toFormDateTime,
  toFormDisplayDate,
  toLowerFixed,
  toggleShowBalance,
  toggleSideMenu,
  toggleTradeView,
  trade,
  tradeFormSchema,
  tradeViewAttr,
  trades,
  transaction,
  transferEarning,
  transformAxiosError,
  transformGQLAxiosError,
  ui,
  unlikeTelegramSetup,
  updateAccountAction,
  updateAddress,
  updateCustomerTradeQuery,
  updateDataKey,
  updateMarketOrderQuery,
  updateMerchantAd,
  updateMerchantAdsQuery,
  updateMerchantOrderQuery,
  updateOneMerchantAd,
  updatePassword,
  updatePasswordSchema,
  updatePaymentMethod,
  updatePreference,
  updateProfileAddressSchema,
  updateProfileSchema,
  updateRate,
  updateTradeData,
  updateUserAccount,
  urlWithSearchParams,
  useChat,
  useCountryCode,
  useFormWithSchema,
  useFormWithSchemaBuilder,
  useNProgress,
  useRouteProp,
  useStickyStorage,
  user,
  userAuth,
  userP2pConfig,
  verifyAuthenticator,
  verifyEmailAddress,
  verifyLogin2fa,
  verifyPhoneNumber,
  verifyPhoneOtpRequest,
  verifyUser2fa,
  verifyUserAuth,
  verifyUserPhoneNumber,
  wallet,
  walletDisplayCurrencies,
  withdrawAsset,
  withdrawal,
  withdrawalAssetSchema,
  withdrawalFee,
  withdrawalOtpSchema,
  withdrawalSchema
};
