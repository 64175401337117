const BitcoinIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="16" fill="#F7931A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2747 15.8148C18.2288 15.5835 18.9505 14.9349 19.1495 13.6002C19.4241 11.7771 18.0817 10.8053 16.2422 10.1626L16.853 7.69194L15.395 7.32613L14.7998 9.73156C14.4173 9.63467 14.0241 9.54371 13.6329 9.45473L14.232 7.03249L12.7749 6.66669L12.164 9.13638C11.8463 9.06421 11.5345 8.99204 11.2325 8.91591L11.2344 8.90899L9.2243 8.40279L8.82718 10.0084C8.82718 10.0084 9.90873 10.2585 9.8852 10.2733C10.4765 10.4226 10.5814 10.824 10.5618 11.1434L9.86559 13.9581C9.9173 13.9711 9.96808 13.9876 10.0176 14.0076L9.86265 13.969L8.88797 17.9118C8.81443 18.0967 8.63008 18.3745 8.21629 18.2727C8.231 18.2934 7.15729 18.0057 7.15729 18.0057L6.42383 19.728L8.32121 20.2055C8.53503 20.2594 8.74597 20.3148 8.95491 20.3696C9.09091 20.4053 9.22606 20.4407 9.3606 20.4754L8.74285 22.9748L10.198 23.3406L10.8099 20.8689C11.1957 20.9744 11.5828 21.0756 11.9708 21.1724L11.3619 23.6342L12.819 24L13.4368 21.5066C15.9235 21.9762 17.7963 21.7706 18.5965 19.4502C19.2407 17.5816 18.5798 16.5099 17.2747 15.8148ZM23.8778 21.6844L24.4122 19.5262L26.5528 20.065L26.9087 18.6255L24.7682 18.0877L25.3016 15.9294L23.8749 15.5705L23.3405 17.7288L21.1999 17.191L20.844 18.6295L22.9845 19.1673L22.4511 21.3265L23.8778 21.6844ZM15.7888 18.4299C16.2216 16.6791 13.7037 16.1107 12.4723 15.8327C12.3338 15.8014 12.2116 15.7738 12.1117 15.7487L11.2949 19.0577C11.3768 19.0782 11.4728 19.1038 11.5802 19.1324L11.5802 19.1324C12.7703 19.4495 15.3681 20.1418 15.7908 18.4289L15.7888 18.4299ZM13.5299 11.2997C14.5565 11.528 16.6595 11.9959 16.2664 13.5894C15.8803 15.1482 13.7129 14.5629 12.723 14.2955C12.6343 14.2715 12.555 14.2501 12.4873 14.2331L13.2296 11.2305C13.3127 11.2513 13.4145 11.274 13.5299 11.2997Z"
      fill="white"
    />
  </svg>
)

export default BitcoinIcon
